


// subjects for subscribtionsss


import React, {useEffect, useRef, useState} from 'react' 
import { Link } from 'react-router-dom';
 import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import StudentCouponsTable from './StudentCouponsTable';
import { useQuery } from 'react-query';
import fetchstudentsCoupons from '../../redux-toolkit/api/fetchstudentsCoupons';

export default function CouponsTable({data , refetchCoupons, isLoading , page ,setStatus ,setPage}) { 

    console.log('cocoocococo',data);
     const [ErrorMessage , setErrorMessage] = useState('');
     const [successMessage , setSuccessMessage] = useState('');
     const [selectedCouponId,setSelectedCouponId] = useState(null);
     const [isloading,setIsLoading] = useState(false);
     const [coupon,setCoupon] = useState('');
     const [couponExpirDate,setcouponExpirDate] = useState('');
     const [CouponDiscount,setCouponDiscount] = useState(0);

     const [studentsPage,studentsSetPage] = useState(0);

    const addModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const editModalRef = useRef(null);

    const {data: studentsCouponData , isLoading:isLoadingStudentsCoupons , error} = useQuery(['students_coupons', selectedCouponId , studentsPage], fetchstudentsCoupons)

    const handleDeleteCoupon = (id) => {

      setIsLoading(true);

      axios.delete(`${process.env.REACT_APP_API_URL}/coupons/${id}` , {headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }} ) 
      .then(() => {
        setSuccessMessage('تم حذف الكوبون بنجاح');
        refetchCoupons();
      }).catch(() => {
        setErrorMessage('حدثت مشكلة ما يرجى المحاولة مرة أخرى')
      })
      .finally(() => {
        setIsLoading(false)
      })

    }

      const handleAddCoupon = () => {
        setIsLoading(true);

        if(couponExpirDate == '' || CouponDiscount == 0){
          setErrorMessage('الرجاء ادخال تاريخ وقيمة خصم صالحين')
        } {
          let expD = couponExpirDate+':00'  ;
          expD.replace('T', " ");
          
          let couponData = {
            discount_percentage: CouponDiscount ,
            expires_at: expD.replace('T', " ")
          }
  
          axios.post(`${process.env.REACT_APP_API_URL}/coupons` , couponData , 
          {headers: {
            Authorization:  `Bearer ${localStorage.getItem('token')}`
          }})
          .then((data) => {
            setSuccessMessage('تمت إضافة الكوبون بنجاح');
            refetchCoupons();
            addModalRef.current.click();
          })
          .catch((error) => {
            setErrorMessage('حدث خطأ ما يرجى المحاولة لاحقا');
          })
          .finally(() => {
            setIsLoading(false);
          });
        }


      }

      const handleEditCoupon = (id) => {
        setIsLoading(true);

        if(couponExpirDate == '' || !CouponDiscount){
          setErrorMessage('الرجاء ادخال تاريخ وقيمة خصم صالحين')
        } {
          // let expD = couponExpirDate+':00'  ;
          // expD.replace('T', " ");
          
          let couponData = {
            discount_percentage: CouponDiscount ,
            expires_at: couponExpirDate.replace('T', " ")
          }
  
          axios.put(`${process.env.REACT_APP_API_URL}/coupons/${id}` , couponData , 
          {headers: {
            Authorization:  `Bearer ${localStorage.getItem('token')}`
          }})
          .then((data) => {
            setSuccessMessage('تم تعديل معلومات الكوبون بنجاح');
            refetchCoupons();
            editModalRef.current.click();
          })
          .catch((error) => {
            setErrorMessage('حدث خطأ ما يرجى المحاولة لاحقا');
          })
          .finally(() => {
            setIsLoading(false);
          });
        }


      }

    return ( 
    <> 

<ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />
<div className='text-center my-5'>
           
            <button 
            data-bs-toggle="modal" 
            data-bs-target="#addCoupon"  
            className='btn btn-primary '>إضافة كوبون</button>
          
        </div>
<div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3' style={{minHeight: '400px'}}> 
            <thead> 
                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'> الكوبون</th> 
                <th className='text-center align-middle'> نسبة الخصم</th> 
                <th className='text-center align-middle'> وقت الإنشاء </th> 
                <th className='text-center align-middle'> وقت الإنتهاء</th> 
                <th className='text-center align-middle'> العمليات المتاحة </th> 
               
                </tr> 
            </thead> 
            <tbody style={{minHeight: '400px'}}> 
 
                {isLoading ? <td colSpan={5} > 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                   data && data?.data.length>0 ? data?.data?.map((coupon,index) => { 
                        return <tr key={index}> 
                        <td  className='text-center align-middle'>{coupon?.coupon} 
                        </td>                       
                        <td  className='text-center align-middle'>{coupon?.discount_percentage} 
                        </td>                       
                        <td  className='text-center align-middle'>{coupon?.created_at} 
                        </td>                       
                        <td  className='text-center align-middle'>{coupon?.expires_at} 
                        </td>                       
                        <td  className='text-center align-middle'>
                        <button 
                        type="button" 
                        class="btn btn-primary" 
                        data-bs-toggle="modal" 
                        data-bs-target="#exampleModal" 
                        data-bs-whatever="@mdo"
                        onClick={() => {setSelectedCouponId(coupon.id)}}>
                          الطلاب
                        </button>

                        <button 
                        data-bs-toggle="modal" 
                        data-bs-target="#editCoupon"  
                        className='btn btn-dark'
                        onClick={() => {
                          setcouponExpirDate(coupon.expires_at);
                          setCouponDiscount(coupon.discount_percentage);
                          setSelectedCouponId(coupon.id);
                        }}
                        >
                          تعديل
                        </button>

                        <button  data-bs-toggle="modal" 
                        data-bs-target="#deleteCoupon" 
                        className='btn btn-danger'
                        onClick={() => setSelectedCouponId(coupon.id)}>
                         حذف
                        </button>
                        </td>                       
                       
                         
                         
                    </tr> 
                    })  : <td colSpan={5}><h3 className='text-center my-5'>لم يتم إضافة كوبونات بعد </h3></td>
                }             
            </tbody> 
        </table> 

</div>



{/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Launch static backdrop modal
</button> */}

<div class="modal modal-fullscreen fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">الطلاب المستفيدين من الكوبون</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">
            
            <StudentCouponsTable 
              data={studentsCouponData}
              isLoading={isLoadingStudentsCoupons}
              page={studentsPage}
              setPage={studentsSetPage}
            />
          {/* <UnitsForStudentTable 
          data={dataUnitsForStudent} 
          isLoading={isLoadingUnits} 
            selectedUnitsForCancel={selectedUnitsForCancel}
            setSelectedUnitsForCancel={setSelectedUnitsForCancel}
          />   */}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إغلاق</button>
      </div>
    </div>
  </div>
</div>



<div className="modal fade" id="deleteCoupon" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div class="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">حذف الكوبون</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div className="modal-body">
        هل انت متاكد انك تريد الحذف
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" ref={deleteModalRef}>لا</button>
        <button 
        type="button" 
        className="btn btn-danger d-flex"  
        data-bs-dismiss="modal"  
        onClick={() => handleDeleteCoupon(selectedCouponId)}
        >
      تأكيد عملية الحذف
        {
          isloading && 
          <div className='mx-2'>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </div>
        }
        </button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="editCoupon" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div class="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">تعديل الكوبون</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div className="modal-body d-flex flex-column">
      <label >
        نسبة الخصم
        <input 
        type={'number'} 
        className="form-control mt-1"
        value={CouponDiscount} 
        onChange={e => setCouponDiscount(e.target.value)} 
        />
      </label>

      <label>
        تاريخ الانتهاء
        <input 
        className='form-control mt-1'
        type={'datetime-local'} 
        value={couponExpirDate} 
        onChange={e => setcouponExpirDate(e.target.value)} 
        />
      </label>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" ref={editModalRef}>لا</button>
        <button 
        type="button d-flex" 
        className="btn btn-success"  
        data-bs-dismiss="modal"  
        onClick={() => handleEditCoupon(selectedCouponId)}
        >
        تعديل
        {
          isloading && 
          <div className='mx-2'>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </div>
        }
        </button>
      </div>
    </div>
  </div>
</div>


<div className="modal fade" id="addCoupon" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div class="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">إضافة كوبون جديد</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div className="modal-body d-flex flex-column">

      <label >
        نسبة الخصم
        <input 
        type={'number'} 
        className="form-control mt-1"
        value={CouponDiscount} 
        onChange={e => setCouponDiscount(e.target.value)} 
        />
      </label>

      <label>
        تاريخ الانتهاء
        <input 
        className='form-control mt-1'
        type={'datetime-local'} 
        value={couponExpirDate} 
        onChange={e => setcouponExpirDate(e.target.value)} 
        />
      </label>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" ref={addModalRef}>إلغاء</button>
        <button 
        type="button" 
        className="btn btn-success d-flex"    
        onClick={handleAddCoupon}
        >
        إضافة
        {
          isloading && 
          <div className='mx-1'>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </div>
        }
        </button>
      </div>
    </div>
  </div>
</div>


     
    </> 
  ) 
}