import axios from 'axios';
import React, {useEffect, useState,useRef} from 'react';
import {createSearchParams, Link, useNavigate} from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function ConfirmRequest({data , isLoading}) { 
    const navigate = useNavigate();
    const [currentBooks,setCurrentBooks] = useState([]);
    const [successMessage , setSuccessMessage] = useState('');
    const [Isloading,setIsLoading] = useState(false);
    const [selectedBookId,setSelectedBookId] = useState();
    const [ErrorMessage , setErrorMessage] = useState('');
    const modalRef = useRef(null);
    const closeModalBtn = useRef(null);

    useEffect(() => { 
        console.log('data from react query',data?.data);
        setCurrentBooks(data?.data); 
    },[data]);   




    const handleDeleteBook = (e,id) => {
        e.stopPropagation();
        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_website_library_section_book_by_id/${id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {

        if(data.status == 200){
            setSuccessMessage("تمت  عملية الحذف بنجاح")
            console.log(data);
            const newCurrentBooks = currentBooks.filter(currentBook => currentBook.id != id);
            setCurrentBooks([...newCurrentBooks]);
            
            setIsLoading(false)
        }else {
            setErrorMessage("حدث خطأ ما");
            setIsLoading(false)
        }
        })
        .catch(error => {
            console.log(error);       
            setErrorMessage("حدث خطأ ما");
            setIsLoading(false)
        })
        .finally(() => {
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }

    const handleEditLibraryBook = (e,id) => {
        e.stopPropagation();

        navigate(`/editlibrarybook/${id}`);
    }

    const selectedBookid = (e,id) => {
        e.stopPropagation();

        setSelectedBookId(id);
    }
    

    return ( 
    <> 
            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

            <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 
            <thead> 
                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'> اسم الكتاب</th> 
                {/* <th className='text-center text-white'> اسم المستخدم</th> 
                <th className='text-center text-white'> الايميل</th> 
                <th className='text-center text-white'> رقم الهاتف</th> */}
               <th className='text-center align-middle'>العمليات المتاحة</th> 
                </tr> 
            </thead> 
            <tbody style={{minHeight: '400px'}}> 
 
                {isLoading ? <td colSpan={2} > 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                 currentBooks && currentBooks.map((book,index) => { 
                        return <tr 
                        key={index} 
                        onClick={() => {
                           
                            window.open(`${process.env.REACT_APP_Video_URL+"/"+book.pdf_link}`,'_blank');
                        }} 
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        title='اضغط لاظهار معلومات الكتاب'
                        > 
                        <td  className='text-center align-middle'>{book.title}</td>             
                        {/* <td  className='text-center'>{teacher.username}</td>                       
                        <td  className='text-center'>{teacher.email}</td>                       
                        <td  className='text-center'>{teacher.phone_number}</td>    */}
                        <td  className='text-center align-middle'>

                            <button 
                            type="button" 
                            className="btn btn-danger" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal"
                            onClick={(e) => selectedBookid(e,book.id)}>
                                حذف
                            </button>
                            
                            <button 
                            className="btn bg-primary" 
                            onClick={(e) => handleEditLibraryBook(e,book.id)}
                            title="تعديل معلومات الكتاب">
                                تعديل
                            </button>
{/* 
                            <Link 
                            to={`/librarySectionStudents/${section.id}`} 
                            className='btn btn-success'>
                                الطلاب  
                            </Link> */}
                            </td>   

                    </tr> 
                 
                    }) 
                } 
            
            </tbody> 
        </table> 
        </div>
        
     
        <div className="modal fade" ref={modalRef} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>
           
           
        </div>
        {Isloading && <div className="modal-body">
        {Isloading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
      
        }
        </div>
        }
       {!Isloading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn-danger"
            onClick={(e) => handleDeleteBook(e,selectedBookId)}
            >حذف</button>

        </div>
        }
      
    </div>
    </div>
    </div>
    </> 
  ) 
}