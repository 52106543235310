import { createSlice} from "@reduxjs/toolkit";



const initialState={
    role_id: localStorage.getItem('role_id') || '',
    token: localStorage.getItem('token') || '',
}

const LoginSLice = createSlice({
    name:'login',
    initialState:initialState,
    reducers:{
         setUser:(state,action)=>{
              console.log("muser ifons to set in reduxtoolkit" , action.payload);
                state.role_id = action.payload.role_id;
                state.token = action.payload.token;
         },

   }
})

export const { setUser }  = LoginSLice.actions;

export default LoginSLice.reducer;




