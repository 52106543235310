import React, { useState } from "react";

import SuperiorsListTable from "../../components/ConfirmRequest/SuperiorsList";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import SuperiorsListLogic from "./SuperiorsListLogic";

const SuperiorsList = () => {
  const {
    listRef,
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
    refetch,
    sectionData,
  } = SuperiorsListLogic();
  const dataSections = data?.data;
  const [status, setStatus] = useState(0);

  return (
    <>
      <Layout>
        <div className="container all">
          <h1
            className="pt-0 mb-4 pb-4 pt-3"
            style={
              {
                // borderBottom: '1px solid green'
              }
            }
          >
            نجوم {sectionData?.name}
          </h1>
          <div className="d-flex justify-content-around mb-5"></div>

          {
            <SuperiorsListTable
              data={dataSections}
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              setStatus={setStatus}
              refetch={refetch}
            />
          }

          {data?.total < 10 ? (
            <> </>
          ) : (
            <Pagination
              data={data?.data}
              handlePageClick={handlePageClick}
              page={page}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default SuperiorsList;
