import React,{ useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import fetchExamStudents from "../../redux-toolkit/api/fetchExamStudents";

const ExamStudentsLogic = () => {

    const [page,setPage] = useState( +localStorage.getItem('exams_students') == null ? 1 : Number(+localStorage.getItem('exams_students')) );
    const {id} = useParams();

    const [initialFilters,setInitialFilters] = React.useState({
        all: 1 , 
        submitted_on_time: 0 ,
        submitted_after_time: 0 ,
        corrected: 0 ,
        uncorrected: 0 ,
        presented: 0 ,
        not_presented: 0
    });
    const [filtersToSend,setFiltersToSend] = React.useState(['all']);
    const [finalFiltersToSend,setFinalFiltersToSend] = React.useState(['all']);
    const [requestFilterApi , setRequestFilterApi] = React.useState(false);
    const [showFilter,setShowFilter] = useState(false);


    const { 
        isLoading, 
        isError, 
        data, 
        error } = useQuery(['exams_students',page, id ,finalFiltersToSend], fetchExamStudents);   
    
    function handlePageClick (data)
    {  
        localStorage.setItem('exams_students' , data.selected)
        setPage(data.selected+1);
    }
    
    useEffect(() => {
        localStorage.setItem('exams_students',1);
        setPage(1);
    },[finalFiltersToSend]);

    const detecteFilterName = (englishName) => {
        switch (englishName) {
            case 'all':
                return 'الكل'
                break;
            case 'submitted_on_time':
                return 'مسلم على الوقت'
                break;
            case 'submitted_after_time':
                    return 'غير مسلم على الوقت'
                    break;
            case 'corrected':
                    return 'مصحح'
                    break;
            case 'uncorrected':
                    return 'غير مصحح'
                    break;
            case 'presented':
                    return 'المتقدمين للمذاكرة'
                    break;   
            case 'not_presented':
                    return 'الغير متقدمين للمذاكرة'
                    break;      
            default:
                break;
        }
    }


    const handleChangeFilter = (e) => {
        
        // console.log('key ', e.target.value);
        // console.log('is selected ', e.target.checked);

        const previousFilterState = initialFilters;

        const isSelected = e.target.checked ? 1 : 0 ;

        previousFilterState[`${e.target.value}`] = isSelected;

        setInitialFilters({...previousFilterState});
        
        const prevFiltersToSend = filtersToSend ;

        if(isSelected){
            prevFiltersToSend.push(e.target.value);
        }else {
            // console.log( 'array ' , prevFiltersToSend )
            const indexToRemove = prevFiltersToSend.indexOf(e.target.value) ;

            prevFiltersToSend.splice(indexToRemove, 1);
        }

        setFiltersToSend([...prevFiltersToSend]);
        
    }

    return {
        isLoading, 
        isError, 
        data, 
        error,
        handlePageClick,
        page,
        setPage,
        initialFilters , 
        handleChangeFilter,
        detecteFilterName,
        showFilter,
        setShowFilter,
        filtersToSend,
        setFinalFiltersToSend
    }
}

export default ExamStudentsLogic