import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
// import fetchsections from "../../../redux-toolkit/api/fetchsectionapi";
import countryCodes from "country-codes-list";
import "./EditStudentDetails.css";
import fetchSectionsApi from "../../redux-toolkit/api/fetchSections";
import fetchProvinces from "../../redux-toolkit/api/fetchProvinces";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import Layout from "../../components/Layout/Layout";
import fetchSubSectionsApi from "../../redux-toolkit/api/fetchSubSections";
import { useParams } from "react-router-dom";

const EditStudentDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSended, setIsSended] = useState(false);
  const [localSubSections, setLocalSubSections] = useState([]);
  const closeButtonRef = useRef(null);
  const [updateLocalForInit, setUpdateLocalForInit] = useState(false);
  const [subSectionsIds, setSubSectionsIds] = useState([]);
  const [years, setYears] = useState([]);

  const params = useParams();

  const Dataform = JSON.parse(localStorage.getItem("student_data"));

  const {
    getValues,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      mother_name: "",
      address: "",
      province_id: "",
      section_id: "",
      birthday: "",
      gender: "",
      phone_number: "",
      gaurdian_number: "",
      username: "",
      sub_sections_ids: [],
      year: 2022,
    },
  });

  // setting default year values
  useEffect(() => {
    const newYears = [];

    for (let i = 2022; i <= 2100; i++) {
      newYears.push(i);
    }

    setYears([...newYears]);
  }, []);

  // console.log('data forjlfkj', Dataform);

  const {
    data: provinces,
    isLoading: isLoadingProvinces,
    isError,
  } = useQuery(["provinces"], fetchProvinces);

  const {
    data: subSections,
    isLoading: isLoadingsubSections,
    isError: isErrorsubSections,
  } = useQuery(["sections", 1, watch("section_id")], fetchSubSectionsApi);

  useEffect(() => {
    if (provinces) {
      setValue("province_id", provinces[0].id);
    }
  }, [provinces]);

  const {
    data: sections,
    isLoading: isLoadingSections,
    isError: isErrorSections,
  } = useQuery(["sections"], fetchSectionsApi);

  useEffect(() => {
    if (sections) {
      setValue("section_id", sections?.data[0]?.section_id);
    }
  }, [sections]);

  const OnSubmit = (data) => {
    setIsLoading(true);

    const { phone_number } = data;

    let selectedSubSections = localSubSections.filter(
      (subsection) => subsection.isSelected
    );

    let subSectionsIds = selectedSubSections.map(
      (subSection) => subSection.sub_section_id
    );

    let student_data = {
      name: data.name,
      username: data.username,
      //   password: data.password,
      father_name: data.father_name,
      mother_name: data.mother_name,
      phone_number: phone_number,
      guardian_phone_number: data.guardian_phone_number,
      gender: data.gender,
      province_id: data.province_id,
      section_id: data.section_id,
      birthday: data.birthday,
      address: data.address,
      sub_sections_ids: subSectionsIds,
      year: data.year,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update_student_by_id/${params.id}`,
        student_data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => {
        setSuccessMessage("تم تعديل معلومات الطالب");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (subSections?.data?.data) {
      const localSubSections = subSections?.data?.data.map((subSection) => {
        return {
          ...subSection,
          isSelected: false,
        };
      });

      setLocalSubSections([...localSubSections]);
    }
  }, [subSections]);

  useEffect(() => {
    if (localSubSections.length) {
      setUpdateLocalForInit(true);
    }
  }, [localSubSections]);

  const handleToggleSelectForSubSection = (subSectionId) => {
    const newLocalSubSections = localSubSections.map((subsection) => {
      if (!subsection.isSelected && subsection.sub_section_id == subSectionId) {
        return {
          ...subsection,
          isSelected: true,
        };
      } else if (
        subsection.isSelected &&
        subsection.sub_section_id == subSectionId
      ) {
        return {
          ...subsection,
          isSelected: false,
        };
      } else {
        return {
          ...subsection,
        };
      }
    });

    setLocalSubSections([...newLocalSubSections]);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_student_by_id/${params.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        console.log("sututuut", data?.data?.data);
        const studentData = data?.data?.data;

        setValue("name", studentData.name);
        setValue("username", studentData.username);
        setValue("gender", studentData.gender);
        setValue("address", studentData.address);
        setValue("province_id", studentData.province_id);
        setValue("father_name", studentData.father_name);
        setValue("mother_name", studentData.mother_name);
        setValue("guardian_phone_number", studentData.gaurdian_number);
        setValue("phone_number", studentData.phone_number);
        setValue("birthday", studentData.birthday);
        setValue("student_id", studentData.student_id);
        if (studentData?.year) {
          setValue("year", studentData?.year);
        }

        setSubSectionsIds([...studentData.student_sub_sections_ids]);
      });
  }, []);

  useEffect(() => {
    if (subSectionsIds.length) {
      // console.log('kckc', subSectionsIds)
      const newLocal = localSubSections.map((subSection) => {
        if (subSectionsIds?.some((id) => id == subSection?.sub_section_id)) {
          return {
            ...subSection,
            isSelected: true,
          };
        } else {
          return { ...subSection };
        }
      });

      setLocalSubSections([...newLocal]);
    }
  }, [subSectionsIds, updateLocalForInit]);

  return (
    <Layout>
      <div className="container all mb-5">
        <form onSubmit={handleSubmit(OnSubmit)}>
          <section className="bg-signup  my-5 d-flex align-items-center px-lg-4">
            <ToastSuccessError
              successMessage={successMessage}
              ErrorMessage={ErrorMessage}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
            />
            <div className="container-fluid h-custom">
              <div className="row d-flex flex-row-reverse justify-content-center align-items-center h-100">
                <div className="row col-md-9 col-lg-8 col-xl-8 offset-xl-1 mx-auto">
                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      الاسم الثلاثي الكامل ( باللغة العربية )
                      {errors.name && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء إدخال الاسم الكامل
                        </small>
                      )}
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      id="form3Example3"
                      className={`shadow-sm border ${
                        errors.name ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      placeholder="الاسم الثلاثي الكامل"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      الاسم باللغة الانكليزية (بدون فراغات )
                      {errors.username && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء إدخال الاسم باللغة الانكليزية{" "}
                        </small>
                      )}
                    </label>
                    <input
                      type="text"
                      {...register("username", { required: true })}
                      id="form3Example3"
                      className={`shadow-sm border ${
                        errors.username ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      placeholder="الاسم باللغة الانكليزية"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example4">
                      الجنس{" "}
                      {errors.gender && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء تحديد الجنس
                        </small>
                      )}
                    </label>
                    <select
                      id="form3Example4"
                      {...register("gender", { required: true })}
                      className={`shadow-sm border ${
                        errors.gender ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                    >
                      <option value={"MALE"}> ذكر</option>
                      <option value={"FEMALE"}>أنثى</option>
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      {" "}
                      العنوان{" "}
                      {errors.address && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء ادخال العنوان
                        </small>
                      )}
                    </label>
                    <input
                      type="العنوان"
                      {...register("address", { required: true })}
                      id="form3Example3"
                      className={`shadow-sm border ${
                        errors.address ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      placeholder="العنوان"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example4">
                      المحافظة{" "}
                      {errors.province_id && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء تحديد المحافظة
                        </small>
                      )}
                    </label>
                    <select
                      id="form3Example4"
                      {...register("province_id", { required: true })}
                      className={`shadow-sm border ${
                        errors.province_id ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                    >
                      {provinces &&
                        provinces?.map((province, idx) => {
                          return (
                            <option value={province.id}>{province.name}</option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      اسم الأب{" "}
                      {errors.father_name && (
                        <small className="text-sm text-danger">
                          الرجاء إدخال اسم الأب{" "}
                        </small>
                      )}
                    </label>
                    <input
                      type="text"
                      {...register("father_name", { required: true })}
                      id="form3Example3"
                      className={`shadow-sm border ${
                        errors.father_name ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      placeholder="اسم الأب"
                    />
                  </div>
                  <div
                    className={`col-sm-12 col-md-6 form-outline mb-2 mx-auto 
              `}
                  >
                    <label className="form-label" for="form3Example3">
                      {" "}
                      رقم هاتف ولي الأمر{" "}
                      {errors.guardian_phone_number && (
                        <small className="text-sm text-danger">
                          الرجاء ادخال رقم هاتف ولي الامر{" "}
                        </small>
                      )}
                    </label>
                    <div
                      className={`border ${
                        errors.guardian_phone_number
                          ? "border-danger"
                          : "border-success"
                      } d-flex flex-column rounded overflow-hidden`}
                    >
                      <input
                        type="number"
                        {...register("guardian_phone_number", {
                          required: true,
                          pattern: /^\d+$/,
                        })}
                        id="form3Example3"
                        className={`shadow-sm form-control form-control-lg border-0 text-start number_input`}
                        placeholder="0999999999"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      اسم الأم{" "}
                      {errors.mother_name && (
                        <small className="text-sm text-danger">
                          {" "}
                          الرجاء ادخال اسم الأم{" "}
                        </small>
                      )}
                    </label>
                    <input
                      type="text"
                      {...register("mother_name", { required: true })}
                      id="form3Example3"
                      className={`shadow-sm border ${
                        errors.mother_name ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      placeholder="اسم الأم"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example3">
                      تاريخ الميلاد{" "}
                      {errors.birthday && (
                        <small className="text-sm text-danger">
                          تاريخ الميلاد مطلوب
                        </small>
                      )}
                    </label>
                    <input
                      type="date"
                      className={`shadow-sm border ${
                        errors.birthday ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                      id="datepicker1"
                      {...register("birthday", { required: true })}
                      placeholder="يوم/شهر/سنة"
                    />
                  </div>

                  {/* <div className="col-sm-12 col-md-6 form-outline mb-2">
                <label className="form-label" for="form3Example4">
                  كلمة السر{" "}
                  {errors.password && (
                    <small className="text-sm text-danger">
                      يجب ألا تقل كلمة السر عن 8 محارف ويجب ان تحتوي أرقام وأحرف
                    </small>
                  )}
                </label>
                <input
                  type="password"
                  {...register("password", {
                    required: true,
                    minLength: 8,
                    pattern: {
                      value: new RegExp(
                        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
                      ),
                    },
                  })}
                  id="form3Example4"
                  className={`shadow-sm border ${
                    errors.password ? "border-danger" : "border-success"
                  } form-control form-control-lg`}
                  placeholder="أدخل كلمة المرور"
                />
              </div> */}

                  {/* <div className="col-sm-12 col-md-6 form-outline mb-2">
                <label className="form-label" for="form3Example4">
                   تأكيد كلمة السر{" "}
                  {watch('password') != watch('confirme_password') && (
                    <small className="text-sm text-danger">
                      كلمة السر غير مطابقة
                    </small>
                  )}
                </label>
                <input
                  type="password"
                  {...register("confirme_password", {
                    required: true,
                    minLength: 8,
                    pattern: {
                      value: new RegExp(
                        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
                      ),
                    },
                  })}
                  id="form3Example4"
                  className={`shadow-sm border ${
                    errors.confirme_password ? "border-danger" : "border-success"
                  } form-control form-control-lg`}
                  placeholder="أدخل كلمة المرور"
                />
              </div> */}

                  <div
                    className={`col-sm-12 col-md-6 form-outline mb-2 mx-auto 
              `}
                  >
                    <label className="form-label" for="form3Example3">
                      <div className="text-start"> رقم الهاتف</div>

                      {errors.phone_number && (
                        <small className="text-sm text-danger">
                          الرجاء ادخال رقم هاتف الطالب{" "}
                        </small>
                      )}
                    </label>
                    <div
                      className={`border ${
                        errors.phone_number ? "border-danger" : "border-success"
                      } d-flex flex-column rounded overflow-hidden`}
                    >
                      <input
                        type="number"
                        {...register("phone_number", {
                          required: true,
                          pattern: /^\d+$/,
                        })}
                        id="form3Example3"
                        className={`shadow-sm form-control form-control-lg border-0 text-start number_input`}
                        placeholder="0999999999"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example4">
                      اختر القسم{" "}
                      {errors.section_id && (
                        <small className="text-sm text-danger">
                          الرجاء اختيار القسم
                        </small>
                      )}
                    </label>
                    <select
                      type="text"
                      {...register("section_id", { required: true })}
                      id="form3Example4"
                      className={`shadow-sm border ${
                        errors.section_id ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                    >
                      {sections &&
                        sections?.data?.map((section, idx) => {
                          return (
                            <option value={section.section_id}>
                              {section.section_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example4">
                      اختر السنة الدراسية{" "}
                      {errors.section_id && (
                        <small className="text-sm text-danger">
                          الرجاء اختيار السنة
                        </small>
                      )}
                    </label>
                    <select
                      type="text"
                      {...register("year", { required: true })}
                      id="form3Example4"
                      className={`shadow-sm border ${
                        errors.year ? "border-danger" : "border-success"
                      } form-control form-control-lg`}
                    >
                      {years?.map((year, idx) => {
                        return (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6 form-outline mb-2">
                    <label className="form-label" for="form3Example4">
                      اختر الاقسام الفرعية{" "}
                      {errors.section_id &&
                        {
                          /* <small className="text-sm text-danger">
                      الرجاء اختيار القسم
                    </small> */
                        }}
                    </label>

                    <div className="rounded hidden">
                      {localSubSections?.map((subsection) => {
                        // if (subsection?.is_for_selling) {
                          return (
                            <div
                              className={`border w-100 subSection ${
                                subsection?.isSelected
                                  ? "active_subSection"
                                  : ""
                              }`}
                              onClick={() =>
                                handleToggleSelectForSubSection(
                                  subsection?.sub_section_id
                                )
                              }
                            >
                              {subsection?.sub_section_name}
                            </div>
                          );
                        // }
                      })}
                    </div>
                  </div>

                  {/* {!watch("is_accept") && (
                <div className="text-sm text-danger">
                  {" "}
                  يرجى الموافقة على سياسة الخصوصية
                </div>
              )} */}
                  {/* <div className="col-12 form-outline d-flex align-items-center gap-2 mb-2 mx-auto">
                <input
                  type="checkbox"
                  {...register("is_accept", { required: true })}
                  id="form3Example3"
                  className="ml-3"
                  placeholder="0999999999"
                />
                <label className="form-label" for="form3Example3">
                  {" "}
                  الموافقة على سياسة الخصوصية{" "}
                </label>
              </div> */}

                  <div className="text-center mt-4 pt-2">
                    <button
                      type="submit"
                      className="btn text-white shadow-sm"
                      style={{ marginLeft: "auto", background: "#11AEA2" }}
                      // data-bs-toggle="modal" data-bs-target="#exampleModal"
                    >
                      تعديل المعلومات
                      {isLoading && (
                        <div
                          class="spinner-border spinner-border-sm mx-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </Layout>
  );
};

export default EditStudentDetails;
