import React, { Component, useState } from 'react'
import Menu from '../../Menu'
import './Header.css'
import {GiHamburgerMenu} from 'react-icons/gi';
import Logo from '../../assets/Home/logo.png'

export default function Header() {
    
    
        return (
           <div className=''>
               <GiHamburgerMenu  className='open__close__icon position-fixed' onClick={()=>{
                 document.getElementById('sidebar').classList.toggle('aa')
               }} />
  <nav className="main-header justify-content-end navbar navbar-expand  position-fixed w-100 d-flex   navbar-light" >
    {/* <form className="form-inline ml-3 ">
      <div className="input-group ">
        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search" />
          </button>
        </div>
      </div>
    </form> */}
    {/* Right navbar links */}
    <ul className="navbar-nav ">
      {/* Messages Dropdown Menu */}
      <li className="nav-item dropdown">

          <img 
          src={Logo} 
          alt="myway-logo"
          style={{
            width: "150px",
            marginBottom: "29px",
            marginLeft: "-30px",
            zIndex:'-1'
          }}
          />
      </li>


      {/* Notifications Dropdown Menu */}
      

    </ul>
  </nav>
 
</div>


        )
    }

