import React, { useRef, useState,useEffect } from 'react'
import Layout from '../../components/Layout/Layout';
import record from'../../assets/Home/record.png';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import './AddBook.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';



export default function AddBook() {

      const [mainVideoFile,setMainVideoFile] = useState('');
      const [aboutUsImage,setAboutUsImage] = useState('');
      const [allSiteInfo,setAllSiteInfo] = useState(null);
      const [progress,setProgress] = useState(0);
      const [IsLoading,setIsLoading] = useState(false);
      const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
      const [usernameShouldBeUniq,setUserNameShouldBeUniq] = useState(false);
      const [ErrorMessage , setErrorMessage] = useState('');
      const modalRef = useRef(null);
      const closeModalBtn = useRef(null);
      const [successMessage , setSuccessMessage] = useState('');
      const navigate = useNavigate();

        const params = useParams();
       
      const [isSuccess,setIsSuccess] = useState(false);
    
    const { reset,getValues,watch ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
    useForm({mode: "onSubmit",defaultValues:{
        title:  '',
        website_library_section_id: params.id ,
        transable: 0,
        pdf_file: ''
    }});

    const handleOnSubmit = (data) => {
        // e.preventDefault();
        console.log('data',data)
        console.log("submitting data to server");
        setIsLoading(true);

        const ourFormData = new FormData();

        ourFormData.append('title',data.title);
        ourFormData.append('website_library_section_id',data.website_library_section_id);
        ourFormData.append('pdf_file',data.pdf_file[0]);
        ourFormData.append('transable',data.transable)


        for(var pair of ourFormData.entries()) {
            console.log(pair[0]+ ', '+  pair[1]); 
            // console.log('pair',pair);
       }

        axios.post(`${process.env.REACT_APP_API_URL}/add_website_library_section_book`, ourFormData, {

        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          'Content-Encoding': 'gzip , deflate, br',
          'content-type': 'text/json',
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
  
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(progress);
          setProgress(progress);
          setIsLoading(true);
          console.log(`${process.env.REACT_APP_API_URL}/add_website_library_section_book`);
        },
        // onDownloadProgress: (progressEvent) => {
        //   const progress = 50 + (progressEvent.loaded / progressEvent.total) * 100;
        //   console.log(progress);
        //   setProgress(progress);
        // },
      }).then(res=>{
        setTimeout(() => {
          navigate(-1);
      }, 1000);
        if(res.status === 200)
        {
          const {data} = res;
          console.log('data from post api' , data);
        setIsLoading(false)
        setIsUploadSuccessfuly(true);
        setIsSuccess(true);
        setSuccessMessage("تمت عملية اضافة الكتاب بنجاح");


        // reset form values to empty
        reset({title: '',pdf_file: ''});

        // setTimeout(() => {

        //   // Hide the success alert
        //   setIsSuccess(false);

        // }, 3000);


          return res;
        }else
        {   
          setIsLoading(false)
          setErrorMessage("حدث خطأ ما");
        }
        
      }).catch(error=>{
         setIsLoading(false);
         setErrorMessage(error);
        console.log(error.response)
        if(error.response.data.errors.username ) {
            setUserNameShouldBeUniq(true);
        }
        console.log('this is an error from backend')
        //  setIsError(error.response.data)
      }
        );
      }

      useEffect(() => {
        if(progress == 100) {
            setIsLoading(false);
        }
      },[progress]);

      
      const changeColor = (progress) => {
        if(progress <= 25){
            return '#ff0707';
        }else if(progress <= 50){
            return '#ff6907';
        }else if(progress <= 75){
            return '#ffdb07';
        }else {
            return '#77ff07';
        }
      }

        useEffect(() => {
          setUserNameShouldBeUniq(false);
        },[watch('username')])

  return (
   
        <>

<ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

            {
           IsLoading && <div className={`uploadPrgressbar`}>
            <h2>جار اضافة الكتاب ... </h2>
            
            <div className='content' style={{dispaly: typeof progress != 'number' ? 'none': 'flex'}}>
                {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
                <p>{progress}%</p>
                <Circle percent={progress} strokeWidth={4} strokeColor={changeColor(progress)} /> 
            </div>
        </div>
    }
        <Layout>
            <div className='container all'>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="py-5 pb-3">
          
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> العنوان</label>
            </div>

           <input 
           onChange={() => setUserNameShouldBeUniq(false)} 
           type='text' 
           {...register("title", { required: true })} 
           className='shadow w-100 main-input border-0'
           />
           
           {errors.title && <p className='text-danger text-end p-2 fw-bold'> هذا الحقل مطلوب *</p>}
            {/* {usernameShouldBeUniq && <p className='text-danger text-end p-2 fw-bold'>يجب أن يكون الاسم فريد</p>} */}
        </div>


        <RegisterImage  
        register={register} 
        isUploadSuccessfuly={isUploadSuccessfuly}  
        registerWhat={"pdf_file]"}
        Accept={'pdf'}
        />



    
            <div className='d-flex justify-content-center mt-5'>
                
        {
          IsLoading ? <div className='mb-3 d-flex justify-content-center' >
          
                    <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  </div>
                  : 
              <button className=' btn infos px-3 py-2 border-0 btn-primary' type='submit'><h6>إضافة الكتاب </h6> </button>
        }
         </div>
        </form>
        </div>
        </Layout>
        </>
    
  )
}
