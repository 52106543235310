import React, {useState,useEffect} from 'react';
import Layout from '../../components/Layout/Layout';
import './GuardianDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const GuardianDetails = () => {
    const params = useParams();
    const [guardianDetails,setGuardianDetails] = useState(null);
    const navigate = useNavigate();

    const [editPassword,setEditPassword] = useState(false);
    const [students,setStudents] = useState([
        {student_id: 1 , name: 'Ahmed'},
        {student_id: 2 , name: 'Maher'},
        {student_id: 3 , name: 'Marwan'},
        {student_id: 4 , name: 'yousef'},
        {student_id: 5 , name: 'Yamen'},
        {student_id: 6 , name: 'Moahmmed'},
        {student_id: 7 , name: 'rami'},
        {student_id: 8 , name: 'khaled'},
        {student_id: 9 , name: 'naser'}
       ]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_guardian_by_id/${params.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('guardian detalis are',data.data.data)
            setGuardianDetails(data.data.data);
        })
        .catch(error => {
            console.log(error);
        })
    },[]);

    const handleSearchStudent = (e) => {
        // handleUnorderStudentsList(e.target.value);
        // setValue('student_id',e.target.value);
    }



    return <>
        <Layout>
        <div className='profileScreen'>
        <div className='profileScreen__content'>
            <div className='topcontent'>
                <div className='userImage'>
                    <img src={`${process.env.REACT_APP_Video_URL}/${guardianDetails?.image}`} style={{borderRadius: "50%"}} alt="" />
                </div>
                <h3 className='userName'>{guardianDetails?.username}</h3>
            </div>

            
            <div className={`bottomContent`}>

                <div className='leftContent'>
                    <ul>
                        <li><div className='descTeacherTitle'>اسم ولي الأمر</div><div className='descTeacherContent'>{guardianDetails?.name}</div></li>
                        <li><div className='descTeacherTitle'>الايميل</div><div className='descTeacherContent'>{guardianDetails?.email}</div></li>
                        <li><div className='descTeacherTitle'>رقم الهاتف</div><div className='descTeacherContent'>{guardianDetails?.phone_number}</div></li>
                        <li><div className='descTeacherTitle'>الجنس</div><div className='descTeacherContent'>{guardianDetails?.gender}</div></li>
                        {/* <li><div>تاريخ الميلاد</div><div>Lorem ipsum</div></li>
                        <li><div>العنوان</div><div>Lorem ipsum</div></li>
                        <li><div>المحافظة</div><div>Lorem ipsum</div></li> */}
                    </ul>
                    <div className='title'>
                        معلومات ولي الأمر
                    </div>
                </div>

                {/* <input type='text' placeholder='اكتب هنا للبحث عن ابنك' onChange={(e) => handleSearchStudent(e)} className='shadow w-100 main-input border-0'/>
     
            <div className='students_select'>   
                <ul className='unorder_students_list' >
                        {
                            students && students?.map((student,index) => {
                                return   <li value={student.student_id} key={index}>{student.name}</li>
                            })
                        }
                    </ul>
            </div> */}

                

        </div>
        <div className='editBtn__containener'>
            <button className="btn editTeacherInforBtn" onClick={() => navigate(`/editguardiandetails/${guardianDetails?.guardian_id}`)}>تعديل معلومات ولي الأمر</button>
        </div>
    </div>
    </div>

        </Layout>
    </>
}

export default GuardianDetails;