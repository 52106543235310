import React, { useEffect, useState } from 'react'
import Footer from '../../Footer'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'

const Layout = (props) => {
//   const [appear,setAppear]=useState(false)
// useEffect(()=>{
// setAppear(true)
// },[])

  return (
    <div>
        <Header />
        {/* {
          appear?<SideBar/>:''
        } */}
        <SideBar/>
        
           <div className=''> {props.children}</div> 
           
        <Footer />
    </div>
  )
}

export default Layout