
import { useEffect, useRef, useState  } from "react";
import './SectionsImage.css'
import SectionsImageLogic from './SectionsImageLogic'
export default function SubjectImage({ valid,subscribtionPage,register,id,AddSubscribtion , errors}){
  const{ cardref,btnref,onImageChange} = SectionsImageLogic(valid);

  const [link,setLink] = useState(null);
 
  const paramsId = id || 0

  const [imgLink,setImgLink]= useState(null);
  
  useEffect(()=>{
      if(paramsId){
        setImgLink(subscribtionPage?.section_image);
      }
  },[subscribtionPage])
  
    let img =null;
    if(paramsId){
      img= `${process.env.REACT_APP_Video_URL}/${imgLink}`
    }
   


   const handleImage  = (e)=>{
    const [file] = e.target.files;
    setLink(URL.createObjectURL(file));
   }

    useEffect(()=>{
          img = null;
          setLink(null);
    },[AddSubscribtion])

    return(
      <>
      
      <div className="bg-white    shadow  mb-4 d-flex finals justify-content-center parent_btn" ref={cardref}>
       <img   src={link || img} style={{'width':'16rem','height':'16rem','margin':'auto'}} /> 
            <div className="button ii m-auto add-div btn_child" ref={btnref}>
              <i class="fa fa-plus "></i>
            <div >
   
        <input type='file' 
         className="ool"
         multiple accept="image/*"
         {...register("section_image", { required: id?false:true })}
         onChange={handleImage}
         />
           {errors.cover && <p className='text-danger'>*required image</p>}
        

        </div></div>
      </div>
      </>
    )
  }