import React, {useState ,  useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import Image from '../Image/Image'
import record from '../../assets/Home/record.png'
import SelectLogic from './SelectLogic';
import { useForm } from 'react-hook-form';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';


export default function Select ({handleAdd , subscribtionPage,id}) {
    const [success , setSuccess ] = useState(false);
    const [faild , setFaild ] = useState(false);
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
       
    const { reset,getValues ,watch ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        subscription_name:'',
        subscription_price:'',
        subscription_description:'',
        subscription_image:null
    }});

const{valid,handleSubmitApi,handleUpdateApi,AddSubscribtion}=SelectLogic(subscribtionPage,id,reset,setSuccessMessage,setErrorMessage,setSuccess , setFaild)

const tempId = id || 0

console.log("Subscribtionnnnnn nameeeeeeeee", getValues().subscription_name);

useEffect(()=>{
   if(tempId)reset(subscribtionPage?.data);
   console.log("subcribtionPageeeeeee" , subscribtionPage);
},[subscribtionPage])
  return (
    <div>
        <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />
        
        <form onSubmit={handleSubmit(handleSubmitApi)}>
              <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2' />
                    <label className='fw-bold'> اسم الاشتراك </label>
                </div>
                <div className="d-flex ">
               <input type='text' className='shadow w-50 py-2 border-0'
                {...register("subscription_name", { required: true })}
               />
               {errors.subscription_name && <p className='text-danger'>هذا الحقل مطلوب</p>}
               </div>
               </div>
               <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2'/>
                    <label className='fw-bold'> سعر الاشتراك </label>
                </div>
                <div className="d-flex ">
               <input type='number' className='shadow w-50 py-2 border-0' 
                {...register("subscription_price", { required: true })}
               />
               {errors.subscription_price && <p className='text-danger'></p>}
               </div>
               </div>
    
    
               <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2'/>
                    <label className='fw-bold'> وصف عن الاشتراك </label>
                </div>
               <input type='text' className='shadow w-100 main-input border-0'
                
                {...register("subscription_description", { required: true })}
               />
               {errors.subscription_description && <p className='text-danger'>هذا الحقل مطلوب</p>}
            </div>
            <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center gap-5'>
                    <img src={record} className='record mx-2'/>
                    <label className='fw-bold'> صورة الاشتراك    </label>
                </div>
              <Image 
              valid={valid} 
              register={register}
              id={id}
              subscribtionPage={subscribtionPage}
              AddSubscribtion={AddSubscribtion}
          />
            </div>
            <div className='d-flex justify-content-center mb-5'>
            {tempId ?
                   <button  className=' btn btn-success' 
                //    onClick={(e)=>handleUpdateApi(e,getValues())}
                   >
                    <h6>تعديل </h6>
                   </button>
                   :
                   <button  className=' info  py-2 px-5 border-0'>
                    <h6>إضافة </h6>
                   </button>
            }
            </div>
         </form>
    </div>
  )
}






{/* <div className='mb-3'>
<div className='mb-3 d-flex align-items-center'>
    <img src={record} className='record mx-2'/>
    <label className='fw-bold'>  القسم المرتبط بالاشتراك  </label>
</div>
<div className="d-flex justify-content-between flex-wrap">
   <select className="px-5 py-2 mb-3" ref={bach}>
       <option>بكالوريا علمي</option>
       <hr/>
       <option>بكالوريا أدبي</option>
       <hr/>
       <option>التاسع</option>
       <hr/>
       <option>اللغات</option>
       
   </select>
   <select  className="px-5 py-2 mb-3" ref={bache}>
       <option> الدورة الشتوية</option>
   </select>
   <select  className="px-5 py-2 mb-3" ref={bachee}>
       <option> الرياضيات </option>
   </select>
   
</div>
</div> */}
