

import axios from "axios";
export default async function  fetchAllUnits ({queryKey})
{
          console.log("axiosssssssssdfsdfsf");
          console.log("QUERY KEYyyyyyyyyyyy",queryKey[2]);
          
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_units_by_subject_id?subject_id=${queryKey[2]}&page=${queryKey[1]}` ,
          {params :{limit:19}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


