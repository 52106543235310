import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import record from "../../assets/Home/record.png";
import { useForm } from "react-hook-form";

import "./index.css";

import axios from "axios";
import { Circle } from "rc-progress";
import AddSupderiorsSectionLogic from "./AddSuperiorsLogic";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import Layout from "../../components/Layout/Layout";

export default function AddSuperiorsSection() {
  const [success, setSuccess] = useState(false);
  const [faild, setFaild] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const params = useParams();
  const id = params?.id;

  const [idTeacher, setIdTeacher] = useState(null);
  const [subSectionId, setSubSectionId] = useState(null);
  const [sections, setSections] = useState([]);

  const {
    reset,
    getValues,
    formState,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  const {
    valid,
    handleSubmitApi,
    handleUpdateApi,
    AddSubscribtion,
    progress,
    IsLoading,
  } = AddSupderiorsSectionLogic(
    id,
    reset,
    idTeacher,
    subSectionId,
    setSuccessMessage,
    setErrorMessage,
    setSuccess,
    setFaild
  );

  useEffect(() => {
    if (id) {
      const sectionsFromAPI = axios
        .get(`${process.env.REACT_APP_API_URL}/superiors-sections/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((data) => {
          setSections(data.data.data);
          reset(data.data.data);
        });
    }
  }, []);

  const tempId = id || 0;

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

  return (
    <Layout>
      {IsLoading && (
        <div className={`uploadPrgressbar`}>
          <h2>جار رفع الملفات الرجاء الانتظار ... </h2>

          <div className="content">
            {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
            <p>{progress}%</p>
            <Circle
              percent={progress}
              strokeWidth={4}
              strokeColor={changeColor(progress)}
            />
          </div>
        </div>
      )}
      <div className="container pt-5">
        <ToastSuccessError
          successMessage={successMessage}
          ErrorMessage={ErrorMessage}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
        {console.log("success message", successMessage)}
        <form onSubmit={handleSubmit(handleSubmitApi)}>
          <div className="mb-3 pt-5">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> اسم القسم</label>
            </div>
            <div className="d-flex ">
              <input
                type="text"
                className="shadow w-50 py-2 border-0"
                {...register("name", { required: true })}
              />
              {errors.name && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="d-flex justify-content-center mb-5">
            {!IsLoading && tempId ? (
              <button
                className=" btn btn-success"
                //  onClick={()=>handleUpdateApi(getValues() , id)}
              >
                <h6>تعديل </h6>
              </button>
            ) : null}

            {!IsLoading && !tempId ? (
              <button className=" info  py-2 px-5 border-0">
                <h6>إضافة </h6>
              </button>
            ) : null}

            {IsLoading && (
              <div className="mb-3 d-flex justify-content-center">
                <div class="spinner-border mx-auto" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
}
