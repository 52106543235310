import React, { useEffect, useRef, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import {
  MdOutlineUpdate,
  MdDone,
  MdDoneAll,
  MdOutlineSmsFailed,
} from "react-icons/md";
import AudioPlayer from "./AudioPlayer";
import { Circle } from "rc-progress";
import pdfImg from "../../../assets/icons/pdf.png";
import axios from "axios";
import FullScreenImageModal from "./FullScreenImageModal";
import ImageWithLoader from "./ImageWithLoader";

function Message({
  message,
  setReplyToMessage,
  progress,
  highlightedMessageId,
  highlightTrigger,
  chatUser,
  handleReplyClick,
}) {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  async function downloadFile(name) {
    setLoading(true);
    setDownloadProgress(0); // Reset progress
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/chat/files/${message.id}/download`,
        method: "GET",
        responseType: "blob", // important
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;

          // Calculate the progress percentage
          const percentCompleted = Math.round((current / total) * 100);
          setDownloadProgress(percentCompleted);
        },
      });
      setLoading(false);

      // Create a new Blob object using the response data of the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during file download:", error);
    } finally {
    }
  }
  useEffect(() => {
    if (message.id === highlightedMessageId) {
      setIsHighlighted(true);
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [highlightedMessageId, message.id, highlightTrigger]);
  const messageRef = useRef(null);

  useEffect(() => {
    if (messageRef.current) {
      setHeight(messageRef.current.clientHeight);
      setWidth(messageRef.current.clientWidth);
    }
  }, []);

  const { open, setOpen, mouse } = useClose();

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

  const createdAt = new Date(message.created_at);
  const renderContentWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedCreatedAt = createdAt.toLocaleTimeString("en-US", options);
  const direction = message.is_sent_by_auth_user ? "rtl" : "ltr";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const openModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImageUrl("");
  };

  return (
    <div
      key={message.id}
      className={`message-div mb-25 row d-flex   ${isHighlighted ? "highlighted" : ""
        }`}
      style={{
        direction: message.is_sent_by_auth_user ? "rtl" : "ltr",
        margin: "10px",
      }}
      ref={mouse}
    >
      <div className="col-lg-10 col-sm-9 ">
        <div className={`position-relative`} ref={messageRef}>
          {
            <>
              {message.reply && (
                <div
                  className=" gap p-2 "
                  style={{
                    border: "1px solid gray",
                    borderRight: "3px solid blue",
                    borderRadius: "10px",
                    display: "flex",
                    width: "90%",
                    alignItems: "center",

                    background: "white",
                  }}
                  onClick={async () => {
                    handleReplyClick(message.reply.id);
                    console.log("scro", message.reply.id);
                  }}
                >
                  {message.reply.type == "image" && (
                    <ImageWithLoader
                      size={50}
                      src={
                        message.reply.type === "file"
                          ? pdfImg
                          : message.reply.file_url ??
                          process.env.REACT_APP_Video_URL +
                          message.reply.file
                      }
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "cover",
                        borderRadius: "10px",
                        border: "1px solid blue",
                      }}
                    />
                  )}
                  <div className="" style={{ width: "160px" }}>
                    <p className="mt-1 text-ppp ">
                      {message.reply.is_sent_by_auth_user
                        ? "أنا"
                        : chatUser?.name}
                    </p>
                    <p className="mt-1 text-ppp ">
                      {message.reply.type == "image"
                        ? "صورة"
                        : message.reply.type === "audio"
                          ? "رسالة صوتية"
                          : message.reply.type === "text"
                            ? message.reply.content
                            : "ملف"}
                    </p>
                  </div>
                </div>
              )}
              <div
                className="div d-flex justify-content-between align-items-center  position-relative "
                style={{ direction: direction }}
              >
                <div
                  className={`${message.type === "image" || message.type === "file"
                      ? "image-width"
                      : message.type === "audio" ? 'audio-width' : ""
                    } `}
                  style={{
                    backgroundColor: message.is_sent_by_auth_user ? "#DCF8C6" : "#F5F5DC",
                    // backgroundColor: "#f2f2f2",
                    padding: message.type == "text" ? "10px" : "0px",
                    borderRadius: "10px",
                    overflowWrap: "break-word",
                    ...(message.type === "text"
                      ? {
                        maxWidth: "90%",
                      }
                      : {}),
                  }}
                >
                  {open && (
                    <div className=" ">
                      <ul
                        className={`${direction == "rtl"
                            ? `ul-right-${message.type == "file" ? "image" : message.type
                            }-message`
                            : `ul-left-${message.type == "file" ? "image" : message.type
                            }-message`
                          }`}
                      >
                        <li
                          className="b_li"
                          onClick={() => {
                            setReplyToMessage(message);
                            setOpen(false);
                          }}
                        >
                          رد
                        </li>
                        {message.type === "text" && (
                          <li
                            className="b_li"
                            onClick={() => {
                              navigator.clipboard.writeText(message.content);
                              setOpen(false);
                            }}
                          >
                            نسخ
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  <div className="">
                    <span
                      className={`${direction == "rtl" ? "spa " : "spaa"
                        } position-absolute`}
                    ></span>
                  </div>

                  <div
                    className={` ${direction == "rtl" ? "text-end" : "text-start"
                      } ${message.type === "text" ||
                        message.type === "image" ||
                        message.type === "audio" ||
                        message.type === "file"
                        ? `image-${direction}-options `
                        : ""
                      }`}
                    style={{
                      ...(message.type === "text" && {
                        display: "flex",
                        justifyContent: "between",
                      }),
                    }}
                  >
                    {(message.status === "sent" ||
                      // this for the messages that comes from server so if the message dose not has status then it sended and we can show the options .
                      !message.status) && (
                        <SlOptionsVertical
                          color="#006967"
                          size={13}
                          className=" curser "
                          onClick={() => setOpen(!open)}
                        />
                      )}
                  </div>

                  <p className="text-pppp ">
                    {message.type === "text" && (
                      <div
                        className={` ${direction == "rtl"
                            ? "text-start message-text-right"
                            : "text-end message-text-left"
                          }`}
                      >
                        {renderContentWithLineBreaks(message.content)}
                        {/* {message.id} */}
                      </div>
                    )}
                    {(message.type === "image" || message.type === "file") && (
                      <div
                        className={`${message.type} div  justify-content-center align-items-center   color-mes-resend position-relative `}
                      >
                        <div className={` img-container`}>
                          {message?.status === "sending" && (
                            <div className="progress-div">
                              <p className="progress-loader">
                                {message.progress}%
                              </p>
                              <Circle
                                percent={message.progress}
                                strokeWidth={4}
                                strokeColor={changeColor(progress)}
                              />
                            </div>
                          )}
                          <ImageWithLoader
                            size={200}
                            src={
                              message.type === "file"
                                ? pdfImg
                                : message.file_url ??
                                process.env.REACT_APP_Video_URL +
                                message.file
                            }
                            alt=""
                            onClick={() =>
                              openModal(
                                message.type === "file"
                                  ? pdfImg
                                  : message.file_url ??
                                  process.env.REACT_APP_Video_URL +
                                  message.file
                              )
                            }
                            className="curser"
                          />
                        </div>

                        {message.type === "file" &&
                          message.status !== "sending" && (
                            <>
                              {loading && (
                                <div>
                                  <h4
                                    style={{
                                      textAlign: "center",
                                      fontSize: "15px",
                                    }}
                                  >
                                    جاري التحميل
                                  </h4>
                                  <div className="progress-bar">
                                    <div
                                      className="progress-bar-fill"
                                      style={{ width: `${downloadProgress}%` }}
                                    ></div>
                                  </div>
                                </div>
                              )}

                              <div className="text-center w-100">
                                <a
                                  className={`btn btn-primary mt-1 w-100 rounded-0 ${loading ? "disabled" : ""
                                    }`}
                                  role="button"
                                  aria-disabled={loading}
                                  style={{
                                    pointerEvents: loading ? "none" : "auto",
                                  }}
                                  onClick={() =>
                                    downloadFile(message.id + ".pdf")
                                  }
                                  download={message.id + ".pdf"}
                                >
                                  {loading ? `${downloadProgress}%` : " تحميل"}
                                </a>
                                <a
                                  className="btn btn-primary mt-1 w-100 rounded-0 "
                                  href={
                                    message.file_url ??
                                    process.env.REACT_APP_Video_URL +
                                    message.file
                                  }
                                  download={message.id + ".pdf"}
                                  target="_blank"
                                >
                                  تصفح
                                </a>
                              </div>
                            </>
                          )}
                        <FullScreenImageModal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          imageUrl={currentImageUrl}
                        />
                      </div>
                    )}

                    {message.type === "audio" && (
                      <div className="audio div d-flex justify-content-center   audio-container  position-relative ">
                        <AudioPlayer
                          url={
                            message.file_url ??
                            process.env.REACT_APP_Video_URL + message.file
                          }
                        />
                        {message?.status === "sending" && (
                          <div className="progress-div-audio">
                            <p className="progress-loader">
                              {message.progress}%
                            </p>
                            <Circle
                              percent={message.progress}
                              strokeWidth={4}
                              strokeColor={changeColor(progress)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </p>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p className="text-ppp  text-start w-100 ">
                      {formattedCreatedAt}{" "}
                    </p>
                    <p>
                      {" "}
                      {message.is_sent_by_auth_user && (
                        <p className="text-ppp  text-center ">
                          {message?.status === "sending" ? (
                            <MdOutlineUpdate
                              size={15}
                              className="rotating-icon"
                            />
                          ) : message.status === "failed" ? (
                            <MdOutlineSmsFailed
                              size={15}
                              style={{ color: "red" }}
                            />
                          ) : message.read_at ? (
                            <MdDoneAll size={15} className=" text-slate-400" />
                          ) : (
                            <MdDone size={15} className=" text-slate-400" />
                          )}
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Message;

export const useClose = () => {
  const [open, setOpen] = useState(false);
  const mouse = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (mouse.current) {
        if (!mouse.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);

  return { open, setOpen, mouse };
};

export const clickZoomInImage = (event) => {
  event.target.requestFullscreen();
};
