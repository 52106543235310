import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './styleTable.css';
import right from '../../assets/students/right.png';
import wrong from '../../assets/students/wrong.jpg';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';


export default function StudentsMessagesRecordTable({data , isLoading}) { 


    return ( 
    <> 

<div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 

            <thead> 

                
                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'>الرسالة</th>
                {/* <th className='text-center align-middle'> المرسل</th>  */}
                <th className='text-center align-middle'> وقت الإرسال</th> 
                <th className='text-center align-middle'> عدد محاولات السيرفر للإرسال</th> 
                <th className='text-center align-middle'>رد سيرفر سيريتيل</th> 
                {/* <th className='text-center align-middle'>المدرسة</th>  */}
                {/* <th className='text-center align-middle'> الصف</th>  */}
                <th className='text-center align-middle'> عدد الرسائل المستهلكة</th> 
                <th className='text-center align-middle'> المستقبل</th> 

                </tr> 

            </thead> 
        
            <tbody style={{minHeight: '400px'}}> 
 
                { isLoading ? <td colSpan={12}> 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                 data && data?.map((student,index) => { 
                        return <tr 
                        key={index} 
                        
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 

                        <td  className='text-center align-middle'>
                        <div style={{ height: '150px' , overflowY: 'auto', margin: '0px !important', whiteSpace: 'pre-wrap'}}>
                        {student.message}
                        </div>
                        </td>                       
                        {/* <td  className='text-center align-middle'>المرسل</td>                        */}
                        <td  className='text-center align-middle'>
                        {student.sended_at}
                        </td>                       
                        {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.school}</td>                        */}
                        {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.class}</td>                        */}
                        <td  className='text-center align-middle'>{student.server_attempts}</td>                       
                        <td  className='text-center align-middle'>{student.api_response}</td>                       
                        <td  className='text-center align-middle'>{student.sms_count}</td>                       
                        <td  className='text-center align-middle'>{student.receiver}</td>                       
                        </tr> 
                    }) 
                } 

            
            </tbody> 
        </table> 
        </div>

        
        
     
     
    </> 
  ) 
}