import React , {useState} from 'react'
import { Link, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import ShowFaqsSubjectsLogic from './ShowFaqsSubjectsLogic';
import TableFaqsSubjects from '../ConfirmRequest/TableFaqsSubjects'
const ShowFaqsSubjects = () => {


     const params= useParams();
     const id = params?.id;

  console.log("YESSSSSSSSSS");
    const  {listRef,isLoading, isError, data, error,handlePageClick,page } = ShowFaqsSubjectsLogic(id);
    console.log("FaqssSubjectssss Dataaaa" ,data);
    const dataSubject = data?.data?.data
    const [status,setStatus] = useState();


    return (
      <>
      <Layout>
          <div className='container all'>
              <div className='d-flex justify-content-around mb-5'>           
              </div>
  
              {
                       <TableFaqsSubjects data={dataSubject} isLoading={isLoading} subjectId={id}/>
              }
  
                   {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
  
                 
              </div>
      </Layout>

      </>
    )
}

export default ShowFaqsSubjects;
              
              
    