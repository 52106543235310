import React , {useState,useRef} from 'react'
import { Link, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import StudentSubscribtionLogic from './StudentSubscribtionLogic';
import TableStudenSubscribtion from '../ConfirmRequest/TableStudenSubscribtion';
import fetchStudentSubscribtion from "../../redux-toolkit/api/fetchStudentSubscribtion";


import {
  QueryClient, useQuery,
} from 'react-query'
import axios from 'axios'
import { debounce } from 'lodash';
import fetchStudents from '../../redux-toolkit/api/fetchStudentSubscribtion';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

const StudentSubscribtion = () => {
const params = useParams()
const id =params?.id; 


    const  {listRef,isLoading, isError, data, error,handlePageClick,page ,setPage ,refetchStudentSubScriptions} = StudentSubscribtionLogic(id);

    const dataSections = data?.data
    const [status,setStatus] = useState(0);
    const [ErrorMessage,setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState("");

    const [isLoadingPost,setIsLoadingPost] = useState(false);
    const [isGettingSutdents,setIsGettingStudents] = useState(false);

    const [searchResult,setSearchResult] = useState('');
    const [selectedStudent,setSelectedStudent] = useState('');
    const inputSearchRef = useRef();

    const unOrderStudentsList = useRef();
    const [stuentId , setStduentId] = useState()


    const handleSelectStudentFromDrobDownList = (student) => {
 
        setStduentId(student.student_id)
       
        unOrderStudentsList.current.style.height = '0';
        inputSearchRef.current.value = student.name;
    }

    const handleSearchStudent = (e) => {
        
        unOrderStudentsList.current.style.height = 'auto';

        handleUnorderStudentsList(e.target.value);
        // setValue('student_id',e.target.value);
        setIsGettingStudents(true);

        axios.get(`${process.env.REACT_APP_API_URL}/get_all_students?q=${e.target.value}`,
        {params :{limit:30, page: 1},headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(response => {
            console.log('search response',response.data.data);
            setSearchResult(response.data.data.data)
            
        })
        .catch(error => {
            console.log('error while fetching search students', error)
        })
        .finally(() => {
          setIsGettingStudents(false);
        })

    }

    const devouncedFn = debounce(handleSearchStudent , 500);

    const handleUnorderStudentsList = (searchQuery) => {
        if(searchQuery == '') {
            unOrderStudentsList.current.style.height = '0px';
        }else {
            unOrderStudentsList.current.style.height = 'auto';
        }
    }


      const handleAddStudent  = (e)=>{
    
    e.preventDefault();

    setIsLoadingPost(true)
      let formData = new FormData();
      // setImage(data.image[0]);
      formData.append("student_id",stuentId)
      formData.append("subscription_id",id)
      //  formData.append("subscription_id" ,id);
       const res = axios.post(`${process.env.REACT_APP_API_URL}/post_subscription_for_student`, formData, {
         headers: {
           'content-type': 'text/json',
           "Content-Type": "multipart/form-data",
           "Accept": "application/json",
           'Content-Encoding': 'gzip , deflate, br',
           "Authorization":`Bearer ${localStorage.getItem('token')}`
         }
       }).then(res=>{
         if(res.status===200){
           const {data} = res;
           console.log('data from add student',data);
          //  window.location.reload();
          //  prefetchTodos()
        //    SetValid(true);
        inputSearchRef.current.value = '';
        setStduentId(null);
        setSuccessMessage('تمت اضافة الطالب بنجاح')
        refetchStudentSubScriptions()
           return res;
         }
         else
         {
           console.log(res);
           throw new Error('there is an error')
         }
       }).catch(error => {
         console.log(error.response.data.message);
         setErrorMessage(error.response.data.message);
       })
       .finally(() => {
        setIsLoadingPost(false)
       })
     
  }




    return (
      <>
      <Layout>
      <ToastSuccessError
          successMessage={successMessage}
          ErrorMessage={ErrorMessage}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
          <div className='container all'>

          <div className='position-relative mt-5'>

          <input type='text' ref={inputSearchRef} placeholder='اكتب هنا للبحث عن طالب' onChange={(e) => devouncedFn(e)} className='shadow w-100 main-input border-0'/>
            
            {
              isGettingSutdents && 
            <div className='position-absolute ' style={{top: 29 , left: 20}}>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            }

          </div>
          {/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}

          {/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}
          <div className='students_select mb-5'>   
            <ul className='unorder_students_list' ref={unOrderStudentsList} >
                    {
                        searchResult && searchResult?.map((student,index) => {
                            return   <li 
                                    onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                    value={student.student_id} 
                                    key={index}>
                                        {student.name}
                                    </li>
                        })
                    }
                </ul>

                
          <div className='text-center my-5'>
          <button className='btn btn-primary' onClick={handleAddStudent}>
          اضف طالب
          {
          isLoadingPost && <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
          </div>
          }
          </button>

          </div>
          </div>

          {
            ErrorMessage && <div  className='alert alert-danger' dangerouslySetInnerHTML={{__html:ErrorMessage}}  />
          }

          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >الطلاب
          </h1>
              {
                       <TableStudenSubscribtion data={dataSections?.data} isLoading={isLoading} page={page} setPage={setPage} setStatus={setStatus}/>
              }

  
                   {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
              </div>

             
           

      </Layout>

      </>
    )
}

export default StudentSubscribtion ;
              
              
    