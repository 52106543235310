import React, {useEffect, useState} from 'react' 
import { Link, useNavigate } from 'react-router-dom';
 import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
export default function TableSubSections({data , isLoading,sectionId}) { 

 
     const [dataSubject , setDataSubject] = useState(data);
     const [flag , setFlag] = useState(0);
     const [successMessage,setSuccessMessage] = useState();
     const [errorMessage,setErrorMessage] = useState();
    //  const params = useParams();
    //  const id= params?.id

     const [idSub,setIdSub] = useState(null);


    //  if(dataSubject.length===0 && flag===1){
    //     window.location.reload();
    // }
    const navigate = useNavigate();

    const AddSubsctions = ()=>{
          navigate('/addSubSections' , {state:{id:sectionId}});
    }

    const AddSubsctionsEdit = (id)=>{
           navigate(`/addSubSections/${id}`,{state:{id:sectionId}});
    }

    const handleDeleteSubSections = (id)=>{
        console.log("idddddd subject" , id);
        const temp = [...dataSubject];
        const DataDelte= temp.filter(item=>item.sub_section_id !== id);
        console.log("Data Deleteeed",DataDelte);
        setDataSubject(DataDelte);
        setFlag(1);
    } 

 
    useEffect(() => { 
        console.log('data from react query heaerrrrrrrrrrrrrrr',data);  
        setIdSub(data?.data[0]?.section_id);
        setDataSubject(data?.data);   
    },[data]);   


   
    async function deletePost(id) {
        console.log("iddddd",id);

        const res = axios.delete(`${process.env.REACT_APP_API_URL}/delete_sub_section_by_sub_section_id/${id}`,{
            headers: {
              'content-type': 'text/json',
              "Content-Type": "multipart/form-data",
              "Accept": "application/json",
              'Content-Encoding': 'gzip , deflate, br',
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          
          }).then(res=>{
            if(res.status===200){
              const {data} = res;
              console.log("data delete Subscribtion" , data);
              handleDeleteSubSections(id);
              setSuccessMessage('تمت عملية الحذف بنجاح')
              return res;
            }
            else
            {
              console.log(res);
              throw new Error('there is an error')
            }
          }).catch(error=>{
            console.log(error.response)
            setErrorMessage('حدث خطأ ما يرجى المحاولة لاحقا')
          });
        
     }
        // setStatus('Delete successful');
        const [idItem,setIdItem] = useState();
     const  handleDelete = (id)=> {
        // DELETE request using fetch with async/await
        console.log("no id",id);
        setIdItem(id);
        // deletePost(id);
        // handleDeleteSubSections(id);
      }

      const handleDeleteItem = ()=>{
        deletePost(idItem);
      }



    return ( 
    <> 
        {/* <ToastSuccessError successMessage={successMessage} ErrorMessage={errorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    /> */}
        
        <div className='overflow-auto w-100'>
        <div className='text-center my-5'>
           {/* <Link to={`/addSubSections/${idSub}`}> */}
             <button className='btn btn-primary'  onClick={AddSubsctions}>اضافة دوره</button>
           {/* </Link> */}
        </div>
        
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3' style={{minHeight: '400px'}}> 
            <thead> 

                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'>اسم الدوره</th> 
                <th className='text-center align-middle'> العمليات</th> 
                </tr> 

            </thead> 
            <tbody style={{minHeight: '400px'}}  > 
 
                {isLoading ? <td colSpan={2} > 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
  
                  dataSubject && dataSubject.length>0 ? dataSubject?.map((item,index) => { 
                        return <tr key={index}> 
                        <td  className='text-center align-middle'>{item?.sub_section_name} 
                        </td>                       
                        <td  className='text-center align-middle'> 
                              {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                              <Link to={`/showSubjects/${item?.sub_section_id}`} state={{sectionId: sectionId,subSectionId: item?.sub_section_id}} className="ms-3 btn btn-primary">عرض</Link>
                              <button  onClick={()=>AddSubsctionsEdit(item?.sub_section_id) } className="ms-3 btn btn-success">تعديل</button>
                             <button onClick={()=>handleDelete(item?.sub_section_id)} className="ms-3 btn btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop">حذف</button> 
                        </td>  
                    </tr> 
                    }) : <td colSpan={2} className='text-center align-middle'><h3>لايوجد برامج</h3> </td>
                }             
            </tbody> 
        </table> 
        </div>



  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div class="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        هل انت متاكد انك تريد الحذف
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">لا</button>
        <button type="button" className="btn btn-danger"  data-bs-dismiss="modal"  onClick={handleDeleteItem}>نعم</button>
      </div>
    </div>
  </div>
</div>


     
    </> 
  ) 
}