import axios from 'axios';
import { Circle } from 'rc-progress';
import React, {useState,useEffect} from 'react';
import { useForm } from 'react-hook-form';
import Image from '../../components/Image/Image';
import Layout from '../../components/Layout/Layout';
import './EditLibrarySection.css';
import record from '../../assets/Home/record.png';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { useNavigate, useParams } from 'react-router-dom';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';

const AddLibrarySection = (props) => {

    const [mainVideoFile,setMainVideoFile] = useState('');
    const [aboutUsImage,setAboutUsImage] = useState('');
    const [allSiteInfo,setAllSiteInfo] = useState(null);
    const [progress,setProgress] = useState(0);
    const [IsLoading,setIsLoading] = useState(false);
    const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
    const [imageChanged,setImageChnaged] = useState(false);
    const navigate = useNavigate();
    const [isSuccess,setIsSuccess] = useState(false);

    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');

    const [defauluseFormVlaues,setDefauluseFormValues] = useState({
         name: '' ,
         is_free:  0,
         transable: 0,
         price: '',
         image: '',
 });

 const params = useParams();

 
 const { reset,getValues,watch,setValue ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
 useForm({mode: "onChange",defaultValues: defauluseFormVlaues});


 useEffect(() => {
     setImageChnaged(true);
 },[watch('image')]);

 useEffect(() => { 
     axios.get(`${process.env.REACT_APP_API_URL}/get_website_library_section_by_id/${params.id}`,
     {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
     .then(data => {

         console.log('library section data',data.data.data)

         const librarySectionDetails = data.data.data;

         // const defaulValuesClone = {...defauluseFormVlaues};
         // defaulValuesClone.name = guardianDetails.name;
         // defaulValuesClone.username = guardianDetails.username;
         // defaulValuesClone.password = guardianDetails.password;
         // defaulValuesClone.gender = guardianDetails.gender;
         // defaulValuesClone.image = guardianDetails.image;
         // defaulValuesClone.description = guardianDetails.description;
         // defaulValuesClone.phone_number = guardianDetails.phone_number;
         // setDefauluseFormValues({...defaulValuesClone});

         setValue('name',librarySectionDetails.name);
         setValue('is_free',librarySectionDetails.is_free);

         
        //  console.log('name after get api', guardianDetails.name)
        //  setValue('transable',librarySectionDetails.transable);
         setValue('price',librarySectionDetails.price);
         setValue('image',librarySectionDetails.image);
        //  console.log('typeof image when setting it to hook form',typeof librarySectionDetails.image)
     })
     .catch(error => console.log(error));
    },[]);

 const handleOnSubmit = (data) => {
     // e.preventDefault();
     console.log('data',data)
     console.log("submitting data to server");

     setIsLoading(true);

     const ourFormData = new FormData();

     ourFormData.append('name',data.name);
    //  ourFormData.append('is_free',data.is_free);

     if(data.is_free) {
      ourFormData.append('is_free',1);
     }else {
      ourFormData.append('is_free',0);
     }

     ourFormData.append('transable',data.transable);
     ourFormData.append('price',data.price);

     if(typeof getValues().image != 'string'){
        console.log('gogogo');
         ourFormData.append('image',data.image[0]);
     }


     for(var pair of ourFormData.entries()) {
         console.log(pair[0]+ ', '+  pair[1]); 
         // console.log('pair',pair);
    }

     axios.post(`${process.env.REACT_APP_API_URL}/update_website_library_section_by_website_library_section_id/${params.id}?_method=PUT`, ourFormData, {

     headers: {
       "Content-Type": "multipart/form-data",
       "Accept": "application/json",
       'Content-Encoding': 'gzip , deflate, br',
       'content-type': 'text/json',
       "Content-Type": "multipart/form-data",
       "Authorization": `Bearer ${localStorage.getItem('token')}`
     },
 
     onUploadProgress: (progressEvent) => {
       const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
       console.log(progress);
       setProgress(progress);
       setIsLoading(true);
     }
   }).then(res=>{
     if(res.status === 200)
     {
       const {data} = res;
     console.log('data from post api' , data);
     setIsLoading(false)
     setIsUploadSuccessfuly(true);
     setIsSuccess(true);
     setProgress(0);

     setSuccessMessage("تمت عملية التعديل بنجاح")

      setTimeout(() => {
        navigate(-1);
      }, 1200);

     setTimeout(() => {
         setIsSuccess(false);    
     }, 4000);

       return res;
     }
     else
     {
      setErrorMessage("غير متصل بالشبكه");
      setIsLoading(false);
      setProgress(0);
       console.log(res);
       throw new Error('there is an error')
     }
   }).catch(error=>{
      setIsLoading(false);
      setProgress(0);
      setErrorMessage(error.response.data.message);
      console.log(error.response)
     //  setIsError(error.response.data)
   }
     );
   }

   useEffect(() => {
     if(progress == 100) {
         setIsLoading(false);
     }
   },[progress]);

   
   const changeColor = (progress) => {
     if(progress <= 25){
         return '#ff0707';
     }else if(progress <= 50){
         return '#ff6907';
     }else if(progress <= 75){
         return '#ffdb07';
     }else {
         return '#77ff07';
     }
   }
    return <div className='librarySectionPage'>

        {
           IsLoading && <div className={`uploadPrgressbar`}>
            <h2>جاري حفظ التعديلات ... </h2>
            
            <div className='content'>
                {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
                <p>{progress}%</p>
                <Circle percent={progress} strokeWidth={4} strokeColor={changeColor(progress)} /> 
            </div>
        </div>
        }
        <Layout>

        <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />


        <form onSubmit={handleSubmit(handleOnSubmit)}>
    <div className='container all'>
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم القسم</label>
            </div>
           <input type='text' {...register("name", { required: false })  } className='shadow w-100 main-input border-0'/>
           {/* {errors.junior_certificate && <p className='text-danger'>*required</p>} */}
        </div>

        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> هل هذا القسم مجاني ؟ </label>
            </div>
           <input type='checkbox' {...register("is_free", { required: false })  } checked={watch('is_free') != 0} className='shadow border-0'/> نعم
           {errors.is_free && <p className='text-danger text-end p-2 fw-bold'> هذا الحقل مطلوب *</p>}
        </div>

        {
          watch('is_free')  ?  
            null
          : 
          <div className='mb-3'>
          <div className='mb-3 d-flex align-items-center'>
              <img src={record} className='record mx-2'/>
              <label className='fw-bold'> سعر القسم</label>
          </div>
         <input type='number' {...register("price", { required: false })  } className='shadow w-100 main-input border-0'/>
         {errors.number && <p className='text-danger text-end p-2 fw-bold'> هذا الحقل مطلوب *</p>}
      </div>
        }

     

        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center gap-5'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> صورة القسم </label>
            </div>
          <RegisterImage  register={register} isUploadSuccessfuly={isUploadSuccessfuly}  registerWhat={"image"} isRequired={false} />
        </div>
        </div>

        {/* {
        isSuccess && <div class="alert alert-success d-flex gap-2 align-items-center" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                </svg>
                  <div>
                    تمت عملية التعديل بنجاح
                  </div>
                </div>
          } */}
          


        {
            IsLoading ? <div className='mb-3 d-flex justify-content-center' >
          
                    <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  </div>

                :
                      
                <button type='submit' className="btn btn-primary infos px-3 py-2">تعديل</button>
        }
        </form>

        
        </Layout>
    </div>
}

export default AddLibrarySection;