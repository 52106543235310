import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
// import { GetData } from '../../../../customhook/getDatawithAuth';
import {useForm} from 'react-hook-form'
// import { useUploadForm } from '../../../../customhook/Uploadfiles';
import axios from 'axios';



export default function ShowExamQALogic() {
  const {id , examId} = useParams();
  const {state} = useLocation();

  const [examDetails,setExamDetails] = React.useState();
  const [isCorrecting,setIsCorrecting] = React.useState(false);
  const [successCorrecting,setSuccessCorrecting] = React.useState(false);
//   console.log('quiz id , student id',QuizId,StudentId)
//   const {getDatafrom, isSuccess, progress,IsLoading,IsError,data}  = GetData(`/get_student_traditional_quiz_answers_by_student_id_and_tq_id?traditional_quiz_id=${QuizId}&student_id=${StudentId}`)
//   const {getDatafrom:Get_Qustions_Quiz, isSuccess:qustions_Quiz_success,IsLoading:qustions_Quiz_IsLoading ,IsError:qustions_Quiz_IsError,data:qustions_Quiz_data}  = GetData(`/get_traditional_quiz_questions_files_by_id/${QuizId}`)
//   const {uploadForm:post_correction_Quiz, isSuccess:post_correction_success,IsLoading:post_correction_IsLoading ,IsError:post_correction_IsError,data:post_correction_data} = useUploadForm('/correcting_traditional_quiz_answer_by_student_id_and_tq_id')
  const { register,handleSubmit, reset, formState: { errors ,isDirty, isValid } } = 
  useForm({mode: "onChange",defaultValues:{
    student_id: id,
    traditional_exam_id: examId,
    deserved_mark: '',
    teacher_notes: ''
}});


// console.log('blaaaaaaaaaaaaaa');

const getTraditionExamStudentDetails = () => {

    const data = {
        traditional_exam_id: examId , 
        student_id: id
    }

    axios.get(`${process.env.REACT_APP_API_URL}/get_student_traditional_exam_by_te_id_and_student_id`,
     {params: data , headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    .then(response => {
        // console.log('resss '.repeat(10) , response);
        setExamDetails(response.data.data);
        reset(response.data.data);
    })
    .catch(error => {

    })
    .finally(() => {

    })
}

const onSubmit = data => {
   const formdata = new FormData();
   setIsCorrecting(true);

   formdata.append('teacher_notes',data.teacher_notes);
   formdata.append('deserved_mark',data.deserved_mark);
   formdata.append('traditional_exam_id',examId);
   formdata.append('student_id', id);

   axios.post(`${process.env.REACT_APP_API_URL}/correcting_traditional_exam_by_student_id_and_te_id`, formdata , 
   {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
   .then(data => {
    setSuccessCorrecting(true);
   })
   .catch(error => {

   })
   .finally(() => {
    setIsCorrecting(false);
   })

//    post_correction_Quiz(formdata);
}

    

    useEffect(()=>{
        getTraditionExamStudentDetails();
    //   getDatafrom(); 
    //   Get_Qustions_Quiz();
    },[])

  return {
    // isSuccess, progress,IsLoading,IsError,data,
    // Get_Qustions_Quiz,qustions_Quiz_IsLoading,qustions_Quiz_IsError,qustions_Quiz_data
    // ,post_correction_success,post_correction_IsLoading,post_correction_IsError
    // ,
    register,
errors,
isDirty,
isValid
    ,onSubmit,handleSubmit,
    examDetails,
    isCorrecting,
    successCorrecting
  }
}