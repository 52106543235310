import axios from 'axios';
import React, {useEffect, useState,useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function UserOpinionsTable({data , isLoading}) {
    const navigate = useNavigate();
    const [currentTeachers,setCurrentTeachers] = useState([]);
    const [selectedteacherId,setSelectedTeacherId] = useState();
    const modalRef = useRef(null);
    const closeModalBtn = useRef();
    const [Isloading,setIsLoading] = useState(false);
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const [isLogining,setIslogining] = useState(false);

    useEffect(() => {
        console.log('data from react query',data?.data);
        setCurrentTeachers(data?.data);
    },[data]);



    const handleDeleteTeacher = (e,id) => {
        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_user_opinion/${id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('modal show',document.getElementsByClassName('modal-backdrop')[0])
            if(data.status === 200){
            setSuccessMessage("تمت  عملية الحذف بنجاح")

            modalRef.current.style.display = 'none';
            // closeModalBtn.current.click();
            console.log('modal show',)
            document.getElementsByClassName('modal-backdrop')[0].remove();

            setIsLoading(false);
            console.log(data);

            const newCurrentTeachers = currentTeachers.filter(currentTeacher => currentTeacher.teacher_id != id);

            setCurrentTeachers([...newCurrentTeachers]);
        }
        })
        .catch(error => {


            console.log(error);
            // setErrorMessage("غير متصل بالشبكه");
            setIsLoading(false);
            // setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')

            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }

    const selectedTeacherId = (id) => {
        console.log('id',id)
        setSelectedTeacherId(id);
    }

    const handleloginAsTeacher = (teacherId) => {

        setIslogining(true);

        const dataToSend = {
            id: teacherId
        }

        axios.post(`${process.env.REACT_APP_API_URL}/login_with_teacher_id`,
        dataToSend,
        {
            headers: {"Authorization":  `Bearer ${localStorage.getItem('token')}`}
        })
        .then(data => {

            console.log('token is data ' , data?.data.data.token)

            //setSuccessMessage('تمت العملية بنجاح .. سيتم توجيهك في غضون ثوان')


                window.open(`${process.env.REACT_APP_FRONT_APP_URL}/loginasteacher/${data?.data.data.token}`);

        })
        .catch((error) => {
            console.log('error login as teacher', error);
            setErrorMessage('حدثت مشكلة ما .. يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
            setIslogining(false);
        })


    }

    return (
    <>

    <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

    <div className='overflow-auto w-100' >
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}>
            <thead>
                <tr className='align-middle table-head fw-bold text-white'>
                {/* <th className='text-center text-white'> اس</th>  */}
                <th className='text-center align-middle'> اسم المستخدم</th>
                <th className='text-center align-middle'> الرأي</th>
                {/* <th className='text-center text-white'> الايميل</th>  */}
                {/* <th className='text-center text-white'> رقم الهاتف</th>  */}
               <th className='text-center align-middle'>العمليات المتاحة</th>
                </tr>
            </thead>
            <tbody style={{minHeight: '400px'}}>

                {isLoading ? <td colSpan={3}>
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status">
                     <span class="visually-hidden">Loading...</span>
                   </div>
                   </div>
                   </td> :

                 currentTeachers && currentTeachers.length > 0 ? currentTeachers.map((teacher,index) => {
                        return <tr
                        key={index}
                        onClick={(() => {navigate(`/useropiniondetails/${teacher.id}`, {state: {...teacher}})})}

                        >
                        {/* <td  className='text-center'>{teacher.teacher_name}</td>              */}
                        <td  className='text-center align-middle'>{teacher.user_name}</td>
                        <td  className='text-center align-middle'>{teacher.opinion.length > 90 ? teacher.opinion.slice(0,90) + '...' : teacher.opinion}</td>
                        {/* <td  className='text-center'>{teacher.email}</td>                        */}
                        {/* <td  className='text-center'>{teacher.phone_number}</td>    */}
                        <td  className='text-center align-middle'>

                        {/* <button
                            type="button"
                            className="btn btn-primary"
                            disabled={isLogining}
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleloginAsTeacher(teacher.teacher_id);
                            }}>
                                تسجيل الدخول بحساب الاستاذ
                            </button> */}

                        <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={(e) => {e.stopPropagation();selectedTeacherId(teacher.id)}}>
                                حذف
                            </button>



                            </td>

                    </tr>

                    }) :<td colSpan={3} className='text-center w-100'><h3>ليس هنالك أية اراء بعد</h3> </td>
                }

            </tbody>
        </table>
        </div>

        <div className="modal fade" ref={modalRef} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">

            <h5
            className="modal-title mx-auto"
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>


        </div>

        {Isloading && <div className="modal-body">

        {Isloading && <div className='mb-3 d-flex justify-content-center' >

          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>

        }
        </div>
        }

       {!Isloading &&<div className="modal-footer justify-content-between">
            <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>

            <button
            type="button"
            className="btn btn-danger"
            data-bs-dismiss={Isloading ? '' : 'exampleModal'}
            onClick={(e) => handleDeleteTeacher(e,selectedteacherId)}
            >حذف</button>

        </div>
        }

    </div>
    </div>
    </div>



    </>
  )
}
