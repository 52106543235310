import React , {useState} from 'react'
import { Link, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import ShowCommentsSubjectsLogic from './ShowCommentsSubjectsLogic';
import TableCommentsSubjects from '../ConfirmRequest/TableCommentsSujects'
const ShowCommentsSubjects = () => {


     const params= useParams();
     const id = params?.id;

  console.log("YESSSSSSSSSS");
    const  {listRef,isLoading, isError, data, error,handlePageClick,page } = ShowCommentsSubjectsLogic(id);
    console.log("CommentsSubjectssss Dataaaa" ,data);
    const dataSubject = data?.data?.data
    const [status,setStatus] = useState();


    return (
      <>
      <Layout>
          <div className='container all'>
          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          > التعليقات 
          </h1>

              <div className='d-flex justify-content-around mb-5'>           
              </div>
  
              {
                       <TableCommentsSubjects data={dataSubject} isLoading={isLoading} subjectId={id}/>
              }
  
                   {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
  
                 
              </div>
      </Layout>

      </>
    )
}

export default ShowCommentsSubjects;
              
              
    