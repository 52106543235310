import { useState } from "react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";


export const useUploadForm = (url) => {

  // console.log(${process.env.REACT_APP_API_SITE}${url})
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState("");
  const navigator = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  const uploadForm = async (formData) => {

    console.log('url for add student is =>', url)

    for (var pair of formData.entries()) {
      console.log("formData", pair[0] + ", " + pair[1]);
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}` + url, formData, {
        headers: { 
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "Content-Encoding": "gzip , deflate, br",
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("progress ", progress);

          setProgress(progress);
        },

      })
      .then((res) => {
        console.log('url '.repeat(50), url);
        // setIsSuccess(true);
        console.log('add student response is => ',res)
        if (res.status == 200) {
          const { data } = res;
          // alert('تمت عملية إضافة الطالب بنجاح')
          setData(res.data.data);

          if(url == '/add_student'){
            alert('تمت عملية اضافة الطالب بنجاح ')
            navigate(-1);
          }

          // navigate(-1)

          if(url=='/third_page_check_up_for_signup'){
            console.log("yes it is enterrr")
            // setIsSuccess(true);
          }
          if(data?.data?.token){
            console.log("dataaa",data?.data?.token);
    
          }
 
          setIsSuccess(true);
          console.log('validate 3 is true right now')
        }
      })
      .catch((error) => {
    
        if(error.response.data.message && location.pathname != 'signup'){
          alert(error.response.data.message);
        }else {
          alert('حدث خطأ ما يرجى المحاولة لاحقا')
        }
      
        console.log("Error Message-->", error);

      })
      .finally(() => {
        setProgress(0);
      });
  };

  return { uploadForm, isSuccess, progress, IsLoading, IsError, data };
};