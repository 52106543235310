import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export default function useQuizBankLogic() {
    const { quizId } = useParams();
    const [isLoading, setIsLoading] = useState(false); // Loading state for submission
    const [errorMessage, setErroreMessage] = useState(""); // Error message state
    const [successMessage, setSuccessMessage] = useState(""); // Success message state

    // Initialize react-hook-form
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: "onChange", // Validate onChange
    });

    const onSubmit = async (data) => {
        let success =true ;
        try {
            setIsLoading(true); // Set loading state
            setErroreMessage(""); // Clear previous errors
            setSuccessMessage(""); // Clear previous success message

            // Construct the payload
            const payload = {
                automated_quiz_id: quizId, // Pass the quiz ID if required
                questions: data.questions.map((q) => ({
                    questionText: q.questionText,
                    note: q.note || "",
                    options: q.options,
                    correctOption: q.correctOption,
                })),
            };

            // Debugging: Log the payload
            console.log("Submitting payload:", payload);

            // Make the API call
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/quizzes/questions`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                success=false;
                const errorResponse = await response.json();
                console.error("API Error Response:", errorResponse);
                throw new Error(
                    errorResponse.message || "Failed to submit questions. Please try again."
                );
            }
            else {
                setSuccessMessage("تم إرسال الأسئلة بنجاح!");
            }

            const result = await response.json();
            console.log("API Response:", result);
            setSuccessMessage("تم إرسال الأسئلة بنجاح!"); // Set success message
        } catch (error) {
            console.error("Submission Error:", error.message);
            setErroreMessage(error.message || "An unexpected error occurred."); // Set error message
        } finally {
            setIsLoading(false); // Reset loading state
        }
        return success;
    };

    return {
        register,
        errors,
        isDirty,
        isValid,
        handleSubmit,
        onSubmit,
        isLoading,
        errorMessage,
        successMessage,
        setErroreMessage,
        setSuccessMessage,
        watch,
    };
}
