// subjects for subscribtionsss

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import ReactPlayer from "react-player";
export default function SuperiorsList({
  data,
  isLoading,
  page,
  setStatus,
  setPage,
  refetch,
}) {
  const { id } = useParams();
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [minusPage, setMinusPage] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedID, setSelectedID] = useState();
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handleDeleteSections = (id) => {
    const temp = [...dataSubject];
    const DataDelte = temp.filter((item) => item.section_id !== id);

    setDataSubject(DataDelte);
    if (DataDelte.length === 0 && page > 1) {
      setPage(page - 1);
      setMinusPage(0);
      setStatus(1);
    }
    setFlag(1);
  };

  useEffect(() => {
    setDataSubject(data?.data);
  }, [data]);

  async function deletePost(superiorId) {
    const res = axios
      .delete(
        `${process.env.REACT_APP_API_URL}/superiors-sections/${id}/superiors/${superiorId}`,
        {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          handleDeleteSections(id);
          setSuccessMessage("تمت عملية الحذف بنجاح");
          refetch();
          return res;
        } else {
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  }
  // setStatus('Delete successful');
  const [idDelete, setIdDelete] = useState();
  const handleDelete = (id) => {
    // DELETE request using fetch with async/await

    setIdDelete(id);
    // deletePost(id);
    // handleDeleteSections(id);
  };

  const handleDeleteItem = () => {
    deletePost(idDelete);
  };

  const handleEditSection = () => {
    setLoadingEdit(true);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/superiors-sections/${id}/superiors/${selectedID}`,
        { description, video },
        {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          setSuccessMessage("تمت عملية التعديل بنجاح");
          refetch();
          return res;
        } else {
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setDescription("");
        setVideo("");
      });
  };

  const handleAddSuperior = () => {
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/superiors-sections/${id}/superiors`,
        { description, video },
        {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          setSuccessMessage("تمت عملية الاضافة بنجاح");
          refetch();
          return res;
        } else {
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setDescription("");
        setVideo("");
      });
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
      <div className="text-center my-5">
        <button
          className="ms-3 mx-auto btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
        >
          إضافة نجم
        </button>
      </div>

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "400px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold">
              <th className="text-center align-middle text-white"> الوصف </th>
              <th className="text-center align-middle text-white"> الفيديو </th>
              <th className="text-center align-middle text-white"> العمليات</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={3}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : dataSubject ? (
              dataSubject.length > 0 &&
              dataSubject?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.description}
                    </td>
                    <td className="text-center align-middle">
                      <ReactPlayer url={item.video} width={150} height={200} />
                    </td>
                    <td className="text-center align-middle">
                      {/* <i className='fa fa-check yes fa-2x'> </i>  */}

                      <button
                        onClick={() => {
                          setSelectedID(item.id);
                          setDescription(item.description);
                          setVideo(item.video);
                        }}
                        className="ms-3 btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#editmodal"
                      >
                        تعديل
                      </button>
                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="ms-3 btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        حذف
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={2} className="text-center align-middle">
                <h3>لايوجد نجوم</h3>{" "}
              </td>
            )}
          </tbody>
        </table>
      </div>

      {/* delete modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">هل انت متاكد انك تريد الحذف</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteItem}
              >
                نعم
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}
      <div
        className="modal fade"
        id="editmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setDescription("");
                  setVideo("");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  الوصف
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="description"
                  aria-label="description"
                  aria-describedby="basic-addon1"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  الفيديو
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="الفيديو"
                  aria-label="الفيديو"
                  aria-describedby="basic-addon1"
                  value={video}
                  onChange={(e) => setVideo(e.target.value)}
                />
              </div>
              <ReactPlayer url={video} width={150} height={150} />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDescription("");
                  setVideo("");
                }}
              >
                إلغاء
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleEditSection}
              >
                تأكيد{" "}
                {loadingEdit && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add modal */}
      <div
        className="modal fade"
        id="addModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                إضافة نجم
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  الوصف
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="description"
                  aria-label="description"
                  aria-describedby="basic-addon1"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  الفيديو
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="رابط الفيديو"
                  aria-label="رابط الفيديو"
                  aria-describedby="basic-addon1"
                  value={video}
                  onChange={(e) => setVideo(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                إلغاء
              </button>
              <button
                disabled={video == "" || description == ""}
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleAddSuperior}
              >
                تأكيد{" "}
                {loadingEdit && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
