import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchSuperiorsList from "../../redux-toolkit/api/fetchSuperiorsList";
import { useParams } from "react-router-dom";
import axios from "axios";

const SuperiorsListLogic = () => {
  const { id } = useParams();
  const [sectionData, setSectionDta] = useState({ name: "", id: 1 });
  const [page, setPage] = useState(0);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["superiorslist_sections", page, id],
    fetchSuperiorsList
  );

  function handlePageClick(data) {
    // localStorage.setItem("superiorslist_sections_pag", data.selected);
    setPage(data.selected + 1);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/superiors-sections/${id}`)
      .then((res) => {
        setSectionDta({ ...res.data.data });
      });
  }, []);

  return {
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
    refetch,
    sectionData,
  };
};

export default SuperiorsListLogic;
