
import axios from "axios";

export default async function  fetchExamStudents ({queryKey})
{           

          console.log("process",queryKey[3])
          const stringOfParams = queryKey[3].map((element , index) => {
            if(index == 0) {
                return '&' + element + '=1';
            }else {
                return '&' + element + '=1'
            }
          })

         

          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_students_of_te_by_id/${queryKey[2]}?page=${queryKey[1]}${stringOfParams.join('')}` ,
          { headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


