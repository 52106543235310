

import React , {useReducer, useState} from 'react'
import { Link } from 'react-router-dom';
import ExamsTable from '../../components/ConfirmRequest/ExamsTable';
import TableSections from '../../components/ConfirmRequest/TableSections';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import ExamStudentsLogic from './ExamStudentsLogic';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png'
import ExamStudentsTable from '../../components/ConfirmRequest/ExamStudentsTable';
import filterReducer from './FilterReducer';
import './index.css';


const ExamStudents = () => {


    //   console.log("YESSSSSSSSSS");
    const  {
        listRef,
        isLoading, 
        isError, 
        data, 
        error,
        handlePageClick,
        page ,
        setPage , 
        initialFilters , 
        handleChangeFilter,
        detecteFilterName,
        showFilter,
        setShowFilter,
        filtersToSend,
        setFinalFiltersToSend } = ExamStudentsLogic();

    // console.log("sections Data" ,data);
    // console.log("current page" , page);
    const dataSections = data?.data;
    const [status,setStatus] = useState(0);
    const {sectionId,subjectId} = useLocation().state;


        // const {examId} = useLocation().state;
        // console.log('exam id'.repeat(22) , examId);
    return (
    <>
      <Layout>
          <div className='container all'>
          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          > الطلاب 
          </h1>

          <button className='btn btn-primary shadow' onClick={() => setShowFilter(true)}>  فلترة النتائج </button>

          <div className='w-100 my-3 filters_container' style={{transition: 'all .18s linear', overflow: 'hidden' ,height: showFilter ? 'auto' : '0px'}}>
            

            {
                Object.entries(initialFilters).map((entry , index) => {
                    // console.log('entry is => ' , index+1 , entry ) 

                    return  <div className='filterState_con ' >
                    
                    <label htmlFor={entry[0]} > { detecteFilterName(entry[0]) } </label>
                    
                    <input 
                    type={'checkbox'} 
                    checked={entry[1] == 1} 
                    id={entry[0]} 
                    value={entry[0]} 
                    onChange={(e) => handleChangeFilter(e)} 
                    />

                </div>
                })
            }
   



            <button 
            className='btn w-50 mx-auto btn-success d-block' 
            onClick={() => { 
                setShowFilter(false);
                setFinalFiltersToSend([...filtersToSend]);
            }}
            >
                بحث
            </button>
          </div>


          {/* <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
              <h5 className='gre'> أضف مذاكرة</h5> 
              
              <NavLink to={`/addTraditionalExam/${sectionId}`}>
                <img src={group} className="me" />
              </NavLink>
        
        </div> */}

              <div className='d-flex justify-content-around'>           
              </div>
  
              {
                    <ExamStudentsTable 
                    data={dataSections} 
                    isLoading={isLoading} 
                    page={page} 
                    setPage={setPage} 
                    setStatus={setStatus}
                    />
              } 
  
                    <div className='my-4'>
                        {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
                    </div>
  
                 
              </div>
      </Layout>

      </>
    )
}

export default ExamStudents;
              
              
    

