import axios from "axios";

  export default async function  fetchProvinces ({queryKey})
 {
     
  

 
 const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_provinces` ,
 {params :{limit:9,local: 'ar'} }
  );

     



   return  data;
 } 