

import axios from "axios";
export default async function  fetchRegisterOrders ({queryKey})
{
    console.log("axiosssssssssdfsdfsf");
    console.log("process",process.env.REACT_APP_API_URL)
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/orders?status=${queryKey[1]}&page=${queryKey[2]}` ,
    {params :{limit:19}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
    );

   
          return  data;
} 


