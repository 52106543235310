import React , {useState} from 'react'
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import TableSections from '../ConfirmRequest/TableSections';
import ShowOffersLogic from './ShowOffersLogic';
import OffersTable from '../ConfirmRequest/OffersTable';

const ShowOffers = () => {


  console.log("YESSSSSSSSSS");
    const  {listRef,isLoading, isError, data, error,handlePageClick,page ,setPage} = ShowOffersLogic();
    console.log("sections Data" ,data);
    console.log("current page" , page);
    const dataSections = data?.data
    const [status,setStatus] = useState(0);


    return (
      <>
      <Layout>
          <div className='container all'>

          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >العروض
          </h1>
              <div className='d-flex justify-content-around mb-5'>           
              </div>
  
              {
                       <OffersTable data={dataSections?.data} isLoading={isLoading} page={page} setPage={setPage} setStatus={setStatus}/>
              }
  
                   {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
  
                 
              </div>
      </Layout>

      </>
    )
}

export default ShowOffers ;
              
              
    