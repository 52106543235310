import React, { useEffect, useReducer, useRef, useState } from "react";

import img from "../../assets/Home/chalks (1).png";
import record from "../../assets/Home/record.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Circle } from "rc-progress";
import { Editor } from "@tinymce/tinymce-react";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import Layout from "../../components/Layout/Layout";
import RegisterImage from "../../components/RegisterImage/RegisterImage";
import { useLocation, useParams } from "react-router-dom";
import AutomatedQuizes from "../../components/ConfirmRequest/automatedQuizes";
import { useQuery } from "react-query";
import fetchAutomatedQuizes from "../../redux-toolkit/api/fetchAutomatedQuizes";
import Pagination from "../../components/Pagination/Pagination";

const WebsiteTexts = () => {
  const [allSiteInfo, setAllSiteInfo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const [teachers , setTeachers] = useState([]);
  const [subSections,setSubSetSections] = useState([]);
  const [SubSelectedSection  , setSelectedSubSection] = useState([]);
  const [sections,setSections] = useState([]);

  const [defaultSelectSection , setDefaultSelectSection] =  useState()
  const [defaultSelectSubSection , setDefaultSelectSubSection] =  useState()

  const [idTeacher,setIdTeacher] = useState(null);
  const [subid,setSubid] = useState(null);
  const [selectedSection,setSelectedSection] = useState(null);
  const [selectedSubSectionId,setSelectedSubSectionId] = useState();
  const [subjects,setSubjects] = useState([]);
  const [defaultSubjectId,setDefaultSubjectId] = useState();
  const [examType,setExampType] = useState('TRADITIONAL');
  const [isAddingAoutomated,setIsAddingAoutmated] = useState(false);
  const [selectedAutomatedQuiz,setSelectedAutomatedQuiz] = useState({});

  const [isUploadSuccessfuly,setIsUploadingSuccessfully] = useState(false);

  const  {id}  = useParams();
    // console.log('state '.repeat(20) , state);
    
  const handleSelectAutomatedQuiz = (event , quiz) => {
    setSelectedAutomatedQuiz({...quiz});
  }

  useEffect(() => {
    // console.log('section_subSection',state?.id);
    // console.log('Subsection_subSection',state?.subid);
    // console.log('state insdie subject',);
    // setSubid(state?.id);
    // setSubid(state?.subid);

    // const sectionsFromAPI =  axios.get(`${process.env.REACT_APP_API_URL}/get_section_by_section_id/${state?.id}`,
    // {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    // .then(data => {
    //   console.log('selected sections',data.data.data)
    //   setDefaultSelectSection(data.data.data.section_name)
    // });

    const subSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${id}?limit=10`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
  .then(data => {
    console.log('selected subsection  ', data.data.data.data);
    setSubSetSections(data.data.data.data);

    setSelectedSubSectionId(data.data.data.data[0].sub_section_id)
      })




  },[])

  useEffect(() => {
    console.log('su sus usu s', selectedSubSectionId)
    if(selectedSubSectionId){
        axios.get(`${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${selectedSubSectionId}&limit=10`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then(data => {
        console.log('selected subjects  ', data.data.data);

        setSubjects(data.data.data);
        setDefaultSubjectId(data.data.data[0].subject_id);
        
          })
    }
    
  },[selectedSubSectionId]);


const handleSectionChange = (e) => {
    console.log("selected sections id",e.target.value);
    setSelectedSection(e.target.value);
    // setSubSetSections([]);
}

const handleTeacherChange = (e) => {
     console.log("selected teacer  id sections",e.target.value);
    setIdTeacher(e.target.value)
    setDefaultSubjectId(e.target.value);
  }




  const handleSubSectionChange = (e) => {
    // console.log(e.target.value);
    console.log("selected  Sub Sections id",e.target.value);
    // e.target.value = 
    setSelectedSubSection(e.target.value);
    setSubid(e.target.value);
    setSelectedSubSectionId(e.target.value)
    // setSubject([]);

  }
 

  const {
    reset,
    getValues,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
        name: '',
        points: '',
        description: '',
        end_time: '',
        start_time: '',
        subject_id: '',
        section_id: '',
        correction_Ladder_file: '',
        image: '',
        questions_file: ''
    },
  });

  const handleOnSubmit = (data) => {

//    setValue('lang_description2' , descrbitionLeft.current.getContent() )

    const ourFormData = new FormData();

    ourFormData.append("name", data.name);
    ourFormData.append("points", data.points);
    ourFormData.append("description", data.description);

    ourFormData.append("start_time", data.start_time);
    ourFormData.append("end_time", data.end_time);
    ourFormData.append("subject_id", defaultSubjectId);
    ourFormData.append("section_id", id);

    // tiny mce fields
    ourFormData.append("correction_Ladder_file", data.correction_Ladder_file[0]);
    ourFormData.append("questions_file", data.questions_file[0]);
    ourFormData.append("image", data.image[0]);
    ourFormData.append("type", examType);

    for (var pair of ourFormData.entries()) {
      console.log('dataaaaaaaaaaaaaaaaa',pair[0] + ", " + pair[1]);
      // console.log('pair',pair);
    }
    
    if(examType == 'AUTOMATED' || examType == 'BOTH'){

      if(!selectedAutomatedQuiz?.id){
        setErrorMessage('الرجاء اختيار الاختبار المؤتمت المراد اضافته للمذاكرة')
      }
      else{

        ourFormData.append('automated_quiz_id', selectedAutomatedQuiz.id);

        if(examType == 'AUTOMATED') {
          ourFormData.delete('questions_file');
          ourFormData.delete('correction_Ladder_file');
        }

        axios
        .post(`${process.env.REACT_APP_API_URL}/create_traditional_exam`, ourFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
  
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
            setIsLoading(true);
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            // console.log("data from post api", data);
            setIsLoading(false);
            setSuccessMessage("تمت عملية إضافة المذاكرة بنجاح");
  
            setIsUploadingSuccessfully(true);
  
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
            return res;
          } else {
            console.log(res);
            //   throw new Error('there is an error')
          }
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
          //  setIsError(error.response.data)
        })
        .finally(() => {
          setIsLoading(false);
        });
      }

    }

    if(examType == 'TRADITIONAL'){
      ourFormData.append('automated_quiz_id',null)
      axios
        .post(`${process.env.REACT_APP_API_URL}/create_traditional_exam`, ourFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
  
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
            setIsLoading(true);
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            // console.log("data from post api", data);
            setIsLoading(false);
            setSuccessMessage("تمت عملية إضافة المذاكرة بنجاح");
  
            setIsUploadingSuccessfully(true);
  
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
            return res;
          } else {
            console.log(res);
            //   throw new Error('there is an error')
          }
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
          //  setIsError(error.response.data)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    

  };


  useEffect(() => {
    if (progress == 100) {
      setIsLoading(false);
    }
  }, [progress]);

  useEffect(() => {
    console.log(getValues());
  }, [watch()]);

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

 const handleChangeExampType = (type) => {
  setExampType(type);
 }

 const [page,setPage] = useState(1);
 const {
     isLoading, 
     isError, 
     data, 
     error
 } = 
     useQuery(['automatedQuizesforExams', page ], fetchAutomatedQuizes);

 function handlePageClick (data)
 {
     localStorage.setItem('automatedQuizesforExams' , data.selected)
     setPage(data.selected);
 }

    useEffect(() => {
        console.log('dataddd', data);
    },[data]);

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      {IsLoading && (
        <div className={`uploadPrgressbar`}>
          <h2>جار رفع الملفات الرجاء الانتظار ... </h2>

          <div className="content">
            {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
            <p>{progress}%</p>
            <Circle
              percent={progress}
              strokeWidth={4}
              strokeColor={changeColor(progress)}
            />
          </div>
        </div>
      )}
      <Layout>

        {

         
      <div className="container all"> 
        <div className="w-100 d-flex justify-content-center flex-column gap-3 align-items-center" >
        <h2>يرجى تحديد نوع المذاكرة</h2>
          <select className="form-select d-block" onChange={(e) => handleChangeExampType(e.target.value)}>
            <option value='TRADITIONAL'>تقليدي</option>
            <option value='AUTOMATED'>مؤتمت</option>
            <option value='BOTH'>تقليدي و مؤتمت معا</option>
          </select>
        </div>
      </div>

        }



        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="container all">
            <h1
              className="pt-0 mb-4 pb-4 pt-3"
              style={
                {
                  // borderBottom: '1px solid green'
                }
              }
            >
               
            </h1>




            <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6>اسم المذاكرة</h6>
                  </div>
              <div className="col-12 mb-5 d-flex justify-content-around">

                  <input
                    min={0}
                    type={"text"}
                    className="w-100 shadow py-2"
                    {...register("name", { required: true })}
                  ></input>
                  {errors.name && (
                    <p className="text-danger text-right">*required</p>
                  )}
                </div>



                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6>عدد النقاط</h6>
                </div>
                <div className="col-12 mb-5 d-flex justify-content-around">

                  <input
                    min={1}
                    type={"number"}
                    className="w-100 shadow py-2"
                    {...register("points", { required: true })}
                  ></input>
                  {errors.points && (
                    <p className="text-danger text-right">*required</p>
                  )}
                </div>

                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6>وصف المذاكرة </h6>
                  </div>
                <div className="col-12 mb-5 d-flex justify-content-around">

                  <input
                    type={"text"}
                    className="w-100 shadow py-2"
                    {...register("description", { required: true })}
                  ></input>
                  {errors.description && (
                    <p className="text-danger text-right">*required</p>
                  )}
                </div>


                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6> صورة المذاكرة </h6>
                  </div>
                <div className="col-12 mb-5 d-flex justify-content-around">

                <RegisterImage
                register={register}
                isRequired={true}
                registerWhat={"image"}

              />
                  {errors.image && (
                    <p className="text-danger text-right">*required</p>
                  )}
                </div>

                
                {
                    examType == "AUTOMATED" ? 
                    null 
                    : 
                    <>


                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6>  ملف أسئلة المذاكرة  </h6>
                </div>
                <div className="col-12 mb-5 d-flex flex-column gap-3 justify-content-around">

                <RegisterImage  
                  register={register} 
                  isUploadSuccessfuly={isUploadSuccessfuly}  
                  registerWhat={"questions_file"}
                  Accept={'pdf'}
                  />

                  </div>
                    </>

                
                }


                
                  {
                    examType == "AUTOMATED" ? 
                    null 
                    : 
                    <>
                    <div className="d-flex gap-3">
                        <img src={img} className="chalk"></img>
                        <h6>سلم التصحيح </h6>
                    </div>
                  <div className="col-12 mb-5 d-flex justify-content-around">

                      <input
                        type={"file"}
                        className="w-100 shadow py-2"
                        accept=".pdf"
                        {...register("correction_Ladder_file", { required: true })}
                      ></input>
                      {errors.correction_Ladder_file && (
                        <div className="text-danger text-right">*required</div>
                      )}
                    </div>
                  </>
                  }


                
                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6> وقت البدء بالمذاكرة </h6>
                </div>
              <div className="col-12 mb-5 d-flex justify-content-around">

                  <input
                    type={"datetime-local"}
                    className="w-100 shadow py-2"
                    {...register("start_time", { required: true })}
                  ></input>
                  {errors.start_time && (
                    <div className="text-danger text-right">*required</div>
                  )}
                </div>


                <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6> وقت انتهاء بالمذاكرة </h6>
                </div>
              <div className="col-12 mb-5 d-flex justify-content-around">

                  <input
                    type={"datetime-local"}
                    className="w-100 shadow py-2"
                    {...register("end_time", { required: true })}
                  ></input>
                  {errors.end_time && (
                    <div className="text-danger text-right">*required</div>
                  )}
                </div>
                    
                    {
                      examType == 'BOTH' ||  examType == 'AUTOMATED' ? 
                      <>
                      <div className="d-flex gap-3">
                          <img src={img} className="chalk"></img>
                          <h6>اضافة الاختبار المؤتمت</h6>
                      </div>

                      <div className="col-12 mb-5 d-flex justify-content-around">
                      
                      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      اختيار الاختبار المؤتمت
                      </button>

                      </div>
                      </>
                      : null
                    }


                <form className='subject'>
              <div className='selects__container'>
                {/* <div className='d-flex flex-column justify-content-center align-items-center'>
              <p>اختر قسم</p>
              <select onChange={(e) => handleSectionChange(e)} className="form-select customSelect" aria-label="Default select example">
            <option selected>{defaultSelectSection}</option>
            {
              sections && sections?.map((section,index) => <option key={index} value={section.section_id}>{section.section_name}</option>)
            }
            
          </select>
          </div> */}

        <div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6> الدورة </h6>
                </div>
          <div className='d-flex mb-5 flex-column justify-content-center align-items-center'>
             
          <select onChange={(e) => handleSubSectionChange(e)}  className="form-select  customSelect" aria-label="Default select example">
          {/* <option selected>اختر الدورة</option> */}
            {
             subSections && subSections?.map((subsection,index) => { 
             return <option key={index} value={subsection.sub_section_id}>{subsection.sub_section_name}</option>})
            }
        </select>
</div>

<div className="d-flex gap-3">
                    <img src={img} className="chalk"></img>
                    <h6>   المادة </h6>
                </div>
<div className='d-flex flex-column justify-content-center align-items-center'>
             

              <select onChange={(e) => handleTeacherChange(e)}  className="form-select  customSelect" aria-label="Default select example">
        {/* <option selected>Choose th</option> */}
        {
            subjects && subjects.length > 0 && subjects?.map((teacher,index) => <option key={index} value={teacher.subject_id}>{teacher.subject_name}</option>)
        }
      </select>

</div>
     

          </div>
                {/* <button type='submit' className='btn infos px-3 py-2' onClick={handleAddSubject}>إضافة</button> */}
              </form>

          </div>
          


          <div className="d-flex justify-content-center">

            {
                IsLoading ? 
                <div className="w-100 my-5 text-center">
                    <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                : 
                <button
                className=" btn infos mt-5 mb-5 px-3 py-2 btn-primary"
                type="submit"
              >
                <h6>إضافة المذاكرة </h6>{" "}
              </button>

            }

          </div>
        </form>

        

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">بنك الاختبارات</h5>
      </div>

      <div class="modal-body">
            
            <AutomatedQuizes data={data?.data} handleSelectAutomatedQuiz={handleSelectAutomatedQuiz} selectedAutomatedQuiz={selectedAutomatedQuiz} isLoading={isLoading}/> 
            
            <div className='my-4'>
                <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />
            </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إغلاق</button>
      </div>
    </div>
  </div>
</div>

      </Layout>


    </>
  );
};

export default WebsiteTexts;
