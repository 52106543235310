import React, { useRef, useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import record from "../../assets/Home/record.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Circle } from "rc-progress";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function () {
  const userData = useSelector((state) => state.Login);
  const [mainVideoFile, setMainVideoFile] = useState("");
  const [aboutUsImage, setAboutUsImage] = useState("");
  const [allSiteInfo, setAllSiteInfo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);
  const [usernameShouldBeUniq, setUserNameShouldBeUniq] = useState(false);
  const [success, setSuccess] = useState();
  const [faild, setFaild] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);

  const {
    reset,
    getValues,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
      role_id: 4,
    },
  });

  const handleOnSubmit = (data) => {
    console.log("data", data);

    setIsLoading(true);

    const ourFormData = new FormData();

    ourFormData.append("username", data.username);
    ourFormData.append("password", data.password);
    ourFormData.append("name", data.name);
    ourFormData.append("role_id", data.role_id);

    for (var pair of ourFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
      // console.log('pair',pair);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/add_admin`, ourFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
          "content-type": "text/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progress);
          setProgress(progress);
          setIsLoading(true);
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          console.log("data from post api", data);
          setIsLoading(false);
          setIsUploadSuccessfuly(true);
          setIsSuccess(true);

          setSuccessMessage("تمت عملية إضافة الأدمن بنجاح");

          setTimeout(() => {
            navigate(-1);
          }, 1200);

          // reset form values to empty
          reset({ password: "", role_id: "", username: "" });

          return res;
        } else {
          setErrorMessage("غير متصل بالشبكه");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.response.data.message);

        console.log(error.response);
        if (error.response.data.errors.username) {
          setUserNameShouldBeUniq(true);
        }
        console.log("this is an error from backend");
        //  setIsError(error.response.data)
      });
  };

  useEffect(() => {
    if (progress == 100) {
      setIsLoading(false);
    }
  }, [progress]);

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

  useEffect(() => {
    setUserNameShouldBeUniq(false);
  }, [watch("username")]);

  return (
    <>
      <Layout>
        <div className="container all">
          <ToastSuccessError
            successMessage={successMessage}
            ErrorMessage={ErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />

          <form onSubmit={handleSubmit(handleOnSubmit)} className="py-5">
            {/* {
        isSuccess && <div class="alert alert-success d-flex gap-2 align-items-center" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                </svg>
                  <div>
                    تمت عملية الاضافة بنجاح
                  </div>
                </div>
          } */}

            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> اسم الادمن</label>
              </div>


              <input
                onChange={() => setUserNameShouldBeUniq(false)}
                type="text"
                {...register("name", { required: true })}
                className="shadow w-100 main-input border-0"
              />

              {errors.name && (
                <p className="text-danger text-end p-2 fw-bold">
                  {" "}
                  هذا الحقل مطلوب *
                </p>
              )}
              {usernameShouldBeUniq && (
                <p className="text-danger text-end p-2 fw-bold">
                  يجب أن يكون الاسم فريد
                </p>
              )}
            </div>

            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold">اسم المستخدم </label>
              </div>


              <input
                onChange={() => setUserNameShouldBeUniq(false)}
                type="text"
                {...register("username", { required: true })}
                className="shadow w-100 main-input border-0"
              />

              {errors.username && (
                <p className="text-danger text-end p-2 fw-bold">
                  {" "}
                  هذا الحقل مطلوب *
                </p>
              )}
              {usernameShouldBeUniq && (
                <p className="text-danger text-end p-2 fw-bold">
                  يجب أن يكون الاسم فريد
                </p>
              )}
            </div>

            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> كلمة السر</label>
              </div>

              <input
                type="password"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  pattern: {
                    value: new RegExp(
                      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
                    ),
                  },
                })}
                className="shadow w-100 main-input border-0"
              />

              {errors.password && (
                <p className="text-danger">
                  {" "}
                  كلمة السر ضعيفة ... يجب أن تحتوي أرقام و رموز{" "}
                </p>
              )}
            </div>

            <div className="m-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> نوع الأدمن</label>
              </div>
              <select
                type="text"
                {...register("role_id", { required: true })}
                className="shadow w-100 border-0 p-2"
              >
                <option value="4">إدارة المحتوى</option>
                <option value="5">إدارة الأمور المالية</option>
                <option value="6">إدارة المستخدمين</option>
                <option value="7">آدمن رئيسي </option>
                <option value="8">إدارة المذاكرات</option>
                <option value="9">مشرف المتابعة </option>
                <option value="10">الدعم الفني</option>
              </select>
              {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
            </div>

            <div className="d-flex justify-content-center mt-5">
              {IsLoading ? (
                <div className="mb-3 d-flex justify-content-center">
                  <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <button
                  disabled={IsLoading}
                  className=" btn infos px-3 py-2 border-0 btn-primary"
                  type="submit"
                >
                  <h6>إنشاء حساب أدمن </h6>{" "}
                </button>
              )}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}
