

import axios from "axios";

export default async function  fetchCoupons ({queryKey})
{

    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/coupons` ,
    {params:{limit:19},headers:{"Authorization": `Bearer ${localStorage.getItem('token')}`} }
    );

    return  data;

} 


