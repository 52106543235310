import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import { NavLink, useParams } from 'react-router-dom';
import group from '../../assets/AboutUs/Group 408.png';
import FaqsSectionsTable from '../../components/ConfirmRequest/FaqsSectionsTable';
import fetchFaqsSections from '../../redux-toolkit/api/fetchFaqsSections';
import { useRef } from 'react';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import fetchFaqSectionQuestions from '../../redux-toolkit/api/fetchFaqSectionQuestions';
import FaqsSectionQuestionsTable from '../../components/ConfirmRequest/FaqsSectionQuestionsTable';
import ReactQuill from 'react-quill';
import katex from "katex";
import "katex/dist/katex.min.css";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from 'react';
window.katex = katex;


const FaqsQuestions = (props)=> {

  const [page,setPage] =useState( +localStorage.getItem('faqs_pag') == null ? 1 : Number(+localStorage.getItem('library_pag')) );
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const params = useParams();
  const [Isloading,setIsLoading] = useState(false);
  const [successMessage , setSuccessMessage] = useState('');
  const [ErrorMessage , setErrorMessage] = useState('');
  const {isLoading, isError, data, error , refetch} = useQuery(['faqsQuestions',page , params.id], fetchFaqSectionQuestions);
    const questionRef = useRef(null);
    const answerRef = useRef(null);



  function handlePageClick (data)
  {
    localStorage.setItem('faqsQuestions_pag' , data.selected)
    setPage(data.selected+1);
  }

  const { reset,getValues , setValue,watch ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
  useForm({mode: "onChange",defaultValues:{
    question: '',
    answer: ''
  }});

      useEffect(() => {
        setValue('question', questionRef.current)
    },[questionRef.current])

    useEffect(() => {
        setValue('answer', answerRef.current)
    },[answerRef.current])


    const onSubmit = (data) => {

        setIsLoading(true);
        const dataToSend = {
            question: data.question ,
            answer: data.answer ,
            faqs_section_id: params.id ,
            transable: 0
        }

        axios.post(`${process.env.REACT_APP_API_URL}/add_faqs`, dataToSend,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            
            if(data.status == 200){
            refetch();
            setSuccessMessage("تمت  عملية الإضافة بنجاح")
            setIsLoading(false);
            reset();
            questionRef.current = '';
            answerRef.current = '';
            }
        })
        .catch(error => {
            setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')
            setIsLoading(false);
            console.log(error);
        })
        .finally(() => {
            modalRef.current.style.display = 'none';
            closeModalBtn?.current?.click();
        })
    }


    return <Layout>
            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

          <div className='container all'> 
          <h1 
          className='pt-0 mb-1 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          > أقسام الأسئلة الشائعة
          </h1>
          <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
               <h5 className='gre'>أضف سؤالا جديدا</h5> 
               <button 
                type="button" 
                className="btn" 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModaladd"
                >
                    <img src={group} className="me" />
                </button>
        </div> 
        <div className='d-flex justify-content-around mb-1'>



        {
          <FaqsSectionQuestionsTable data={data?.data?.data} isLoading={isLoading} refetch={refetch}/>
        }

          </div>
          {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
          
            


        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="modal fade" ref={modalRef} id="exampleModaladd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الإضافة ...' : "إضافة سؤال"}
            </h5>
           

        </div>

         <div className="modal-body">
         <div class="mb-3">
            
            <label for="exampleFormControlInput1" className="form-label text-start">السؤال :</label>
            
            <textarea {...register('question', {required: true})} 
            type="text" 
            class="form-control" 
            id="exampleFormControlInput1" 
            placeholder="أدخل السؤال  هنا ..." 
            hidden
            />

            <ReactQuill
            style={{height: '200px', marginBottom: '120px'}}
            theme={"snow"}
            onChange={(e) =>questionRef.current = e}
            value={questionRef.current}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={'أدخل السؤال ...'}
            /> 
          
            {/* {errors.question && <div className='text-danger'>يرجى إدخال السؤال   </div>} */}

        </div>
         <div class="mb-3">
            
            <label for="exampleFormControlInput1" className="form-label text-start">الجواب:</label>
            
            <textarea {...register('answer', {required: true})} 
            type="text" 
            class="form-control" 
            id="exampleFormControlInput1" 
            placeholder="أدخل الجواب  هنا ..." 
            hidden
            />

            <ReactQuill
            style={{height: '200px', marginBottom: '120px'}}
            theme={"snow"}
            onChange={(e) =>answerRef.current = e}
            value={answerRef.current}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={'أدخل الجواب ...'}
            /> 

            {/* {errors.answer && <div className='text-danger'>يرجى إدخال جواب السؤال   </div>} */}

        </div>
                
        </div>
        

       {<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            disabled={watch('question') == '' || watch('answer') == ''}
            type="submit" 
            className="btn btn-success"
            data-bs-dismiss={Isloading ? '' : 'exampleModal'}
            // onClick={(e) => handleAddSection()}
            >إضافة السؤال</button>

        </div>
        }
      
    </div>
    </div>
    </form>

  
    </Layout>
}


Editor.modules = {
  /*toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ],*/
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};


export default FaqsQuestions;