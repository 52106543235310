import React, {useState} from 'react';
import Select from 'react-select';

function SubjectSelector({subjects, setSelectedSubjectsIds}) {
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const handleSubjectChange = (selectedOptions) => {
        setSelectedSubjects(selectedOptions || []);
        setSelectedSubjectsIds(selectedOptions ? selectedOptions.map(option => option.value) : [])
    };

    // Transforming the subjects into a format react-select accepts
    const options = subjects.map(subject => ({
        value: subject.subject_id,
        label: subject.subject_name,
    }));

    return (
        <div className='selector-container'>
            <p className='selector-title'>اختر المادة</p>

            <Select
                isMulti
                options={options}
                value={selectedSubjects}
                onChange={handleSubjectChange}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="اختر المواد"
            />

            <div className="selected-subjects">
                {selectedSubjects.length > 0 ? (
                    selectedSubjects.map(subject => (
                        <div key={subject.value} className="selected-subject-tag">
                            <span>{subject.label}</span>
                        </div>
                    ))
                ) : (
                    <p className='no-subjects'>لم يتم تحديد أي مادة بعد </p>
                )}
            </div>
        </div>
    );
}

export default SubjectSelector;
