


// subjects for subscribtionsss


import React, {useEffect, useRef, useState} from 'react' 
import { Link } from 'react-router-dom';
 import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function ExamsTable({data , isLoading , page ,setStatus ,setPage}) { 

 
     const [dataSubject , setDataSubject] = useState(data);
     const [flag , setFlag] = useState(0);
     const [minusPage, setMinusPage] = useState(0);


     const [currentAdimis,setCurrentAdmins] = useState([]);
     const modalRef = useRef(null);
     const closeModalBtn = useRef(null);
     const [Isloading,setIsLoading] = useState(false);
     const [selectedDeleteId,setSelectedDeleteId] = useState();
     const [successMessage , setSuccessMessage] = useState('');
     const [ErrorMessage , setErrorMessage] = useState('');


 
    useEffect(() => { 
        console.log('data from react query',data);  
        setDataSubject(data);   
    },[data]);   


   

    const handleDeleteExam = (e,id) => {
        console.log('id id id id id ' , id , e)
        e.stopPropagation();
        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_traditional_exam/${selectedDeleteId}`, 
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(res => {
            if(res.status === 200)
            {
            // console.log(data);
            setIsLoading(false);
            
          setSuccessMessage("تمت  عملية الحذف بنجاح")
          setTimeout(() => {
            window.location.reload();
          }, 400);
            // hide the confirm deletion modal 
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();

            const newCurrentAdmins = currentAdimis.filter(currentAdmin => currentAdmin.id != id);
            console.log('admin deleted successfully ')
            setCurrentAdmins([...newCurrentAdmins]);
            }else {

            }
        })
        .catch(error => {
            // console.log(error);
            setIsLoading(false);
            setErrorMessage("حدث خطأ ما");
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }



    return ( 
    <> 

<ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />
{/* <div className='text-center my-5'>
           <Link to='/addSections'>
             <button className='btn btn-primary '>إضافة قسم</button>
           </Link>
        </div> */}
        
        <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3' style={{minHeight: '400px'}}> 
            <thead> 
                <tr className=' align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'> الصورة </th> 
                <th className='text-center align-middle'> المذاكرة </th> 
                <th className='text-center align-middle'> الوصف </th> 
                <th className='text-center align-middle'> وقت البدء </th> 
                <th className='text-center align-middle'> وقت الانتهاء </th> 
                <th className='text-center align-middle'> العمليات</th> 
                </tr> 
            </thead>  
            <tbody style={{minHeight: '0px'}}> 
 
                {isLoading ? <td colSpan={6}> 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                   dataSubject && dataSubject?.length>0 ? dataSubject?.map((item,index) => { 
                        return <tr key={index}> 
                        <td  className='text-center align-middle'>
                        <img loading='lazy' src={process.env.REACT_APP_Video_URL + item.image} width="45" height="45" className='shadow border' style={{borderRadius: '50%'}} />
                        </td> 

                        <td  className='text-center align-middle'>{item?.name} 
                        </td>                       
                        <td  className='text-center align-middle'>{item?.description?.length < 30 ? item?.description : item?.description?.slice(0,30) + ' ...' }</td> 
                        <td  className='text-center align-middle'>{item?.start_time}</td> 
                        <td  className='text-center align-middle'>{item?.end_time}</td> 
                        <td  className='text-center align-middle'> 
                              {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                        <Link 
                            to={`/examDetails/${item?.id}`} 
                            state={{sectionId: item?.section_id , subjectId: item?.subject_id}} 
                            className="ms-3 btn btn-primary">
                            عرض
                        </Link>
                            
                        <button 
                            className='btn btn-danger'  
                            type="button"  
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal" 
                            onClick={(e) => setSelectedDeleteId(item.id)} >حذف</button>
                            {/* <button className='btn btn-warning' >تعديل</button> */}

                        {/* <Link 
                        to={`/examquestions/${item?.id}`} 
                        state={{sectionId: item?.section_id}} 
                        className="ms-3 btn btn-warning">
                            الأسئلة
                        </Link> */}
                            
                        <Link 
                        to={`/examStudents/${item?.id}`} 
                        state={{sectionId: item?.section_id , subjectId: item?.subject_id , examId: item?.id}} 
                        className="ms-3 btn btn-dark">
                            الطلاب
                        </Link>

                        </td> 
                         
                         
                    </tr> 
                    }) : <td className='text-center align-middle' colSpan={6}><h3>لم يتم إضافة مذاكرات بعد</h3> </td> 
                }             
            </tbody> 
        </table> 
        </div>

        <div className="modal fade" ref={modalRef} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>
           
           
        </div>
        {Isloading && <div className="modal-body">
        {Isloading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
      
        }
        </div>
        }
       {!Isloading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn-danger"
            onClick={(e) => handleDeleteExam(e,selectedDeleteId)}
            >حذف</button>

        </div>
        }
      
    </div>
    </div>
    </div>
     
    </> 
  ) 
}