import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import fetchExamsBySection from "../../redux-toolkit/api/fetchExamsBySection";

const ExamsLogic = () => {
    const [page,setPage] =useState( +localStorage.getItem('showexams_pag') == null ? 1 : Number(+localStorage.getItem('sections_pag')) );
    console.log("nooooooo");
    const {id} = useParams();
    
    const {isLoading, isError, data, error} = useQuery(['sections', id ,page], fetchExamsBySection);   
    
    function handlePageClick (data)
    {  
    localStorage.setItem('sections_pag' , data.selected)
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page,setPage
    }
}

export default ExamsLogic