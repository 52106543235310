import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query';
import fetchQuizeQuestions from '../../redux-toolkit/api/fetchQuizeQuestions';
// import { get_all_automated_quiz_questions_with_options_by_automated_quiz } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';

export default function Show_Automated_Quiz_Options_Logic() {
  const { QuizId } = useParams();
  const dispatch = useDispatch();
  
 
  const {data , isLoading , isError} = useQuery(['quiz_questions' , QuizId] , fetchQuizeQuestions)
  
  console.log('sdjkfljsdf' ,data)
  
  
  useEffect(() => {
    // dispatch(get_all_automated_quiz_questions_with_options_by_automated_quiz({ QuizId }));
  }, [])

  return {
    dispatch,
    QuizId,
    data , 
    isLoading,
    isError
  }
}
