import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./styleTable.css";
import right from "../../assets/students/right.png";
import wrong from "../../assets/students/wrong.jpg";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";

export default function UnActiveStudentsTable({ data, isLoading }) {
  const navigate = useNavigate();
  const [currentTeachers, setCurrentTeachers] = useState([]);
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [newPassword, setNewPassWord] = useState("");
  const newPassWordModalRef = useRef(null);
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [currentUnActiveAccounts, setCurrentUnActiveAccounts] = useState([]);

  useEffect(() => {
    console.log("data from react query", data?.data);
    setCurrentUnActiveAccounts(data?.data);
  }, [data]);

  const handleActiveStudentAccount = (e, id) => {
    e.stopPropagation();

    setSelectedStudentId(id);
  };

  const activateStudentAccount = () => {
    // setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/active_student_account_by_id/${selectedStudentId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage("تم تفعيل الحساب بنجاح");

          // modalRef.current.style.display = 'none';
          // closeModalBtn.current.click();

          const newCurrentUnActiveAccounts = currentUnActiveAccounts.filter(
            (currentStudent) => currentStudent.id != selectedStudentId
          );
          setCurrentUnActiveAccounts([...newCurrentUnActiveAccounts]);
          //   setIsLoading(false);
          console.log("data after activate student account", response.data);
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        // setIsLoading(false);
        console.log("error white activating students", error);
      })
      .finally(() => {
        // modalRef.current.style.display = 'none';
        closeModalBtn.current.click();
        window.location.reload();
      });
  };

  const handleLoginAsStudent = (event, student) => {
    setIsLoading(true);
    event.stopPropagation();

    const dataToSend = {
      id: student.student_id,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login_with_student_id`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log("token is data ", data?.data.data.token);

        setSuccessMessage(
          "تمت العملية بنجاح .. سيتم توجيهك لحساب الطالب في غضون ثوان"
        );

        setTimeout(() => {
          window.open(
            `${process.env.REACT_APP_FRONT_APP_URL}/loginasstudent/${data?.data.data.token}/${student.is_active}`
          );
        }, 15000);
      })
      .catch((error) => {
        console.log("error login as teacher", error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changePassword = (studentId) => {
    // setIsLoading(true);

    if (newPassword == "") {
      setErrorMessage(
        "الرجاء ادخال كلمة السر ومن ثم قم بحقظ التعديلات مرة اخرى"
      );
    } else {
      const data = {
        password: newPassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/${studentId}/password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // console.log('token is data ' , data?.data.data.token)

          setSuccessMessage("تم تعديل كلمة السر بنجاح");
        })
        .catch((error) => {
          // console.log('error login as teacher', error);
          setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
        })
        .finally(() => {
          // modalRef.current.style.display = 'none';
          newPassWordModalRef.current.click();
          // setIsLoading(false);
        });
    }
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <table
        className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
        style={{ minHeight: "400px" }}
      >
        <thead>
          <tr className="align-middle table-head fw-bold text-white">
            <th className="text-center align-middle"> الاسم</th>
            <th className="text-center align-middle"> اسم المستخدم</th>
            <th className="text-center align-middle"> آخر نشاط للطالب</th>
            <th className="text-center align-middle"> النقاط</th>
            {/* <th className='text-center align-middle'>المدرسة</th>  */}
            {/* <th className='text-center align-middle'> الصف</th>  */}
            <th className="text-center align-middle">
              {" "}
              القسم الذي ينتمي إليه الطالب
            </th>
            {/* <th className='text-center align-middle'> الايميل</th>  */}
            <th className="text-center align-middle"> تاريخ الإنشاء</th>
            <th className="text-center align-middle"> العمليات المتاحة</th>
            <th className="text-center align-middle"> رقم هاتف الطالب</th>
            <th className="text-center align-middle"> رقم هاتف ولي الأمر</th>
          </tr>
        </thead>
        <tbody style={{ minHeight: "400px" }}>
          {isLoading ? (
            <td colSpan={11}>
              <div className="w-100 text-center my-5 d-flex justify-content-center">
                <div
                  class="spinner-border"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </td>
          ) : currentUnActiveAccounts && currentUnActiveAccounts.length > 0 ? (
            currentUnActiveAccounts.map((student, index) => {
              return (
                <tr
                  key={index}
                  // onClick={(() => {navigate(`/student/${student.student_id}`)})}
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #1a1a1a1a",
                  }}
                  className="tableRowHover"
                >
                  <td className="text-center align-middle">{student.name}</td>
                  <td className="text-center align-middle">
                    {student.username}
                  </td>
                  <td className="text-center align-middle">
                    {student.last_active_at}
                  </td>
                  <td className="text-center align-middle">{student.points}</td>
                  {/* <td  className='text-center align-middle'>{student.school}</td>                        */}
                  {/* <td  className='text-center align-middle'>{student.class}</td>                        */}
                  <td className="text-center align-middle">
                    {student.section_name}
                  </td>
                  {/* <td  className='text-center align-middle'>{student.email}</td>                                            */}
                  <td className="text-center align-middle">
                    {student.created_at}
                  </td>
                  {/* <td  className='text-center'>{student.phone_number}</td>    */}
                  <td className="text-center activatOrNot">
                    <button
                      type="button"
                      className="btn btn-danger shadow"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={(e) =>
                        handleActiveStudentAccount(e, student.student_id)
                      }
                    >
                      تفعيل الحساب
                    </button>

                    <div
                      className="d-flex flex-column mt-2"
                      style={{ gap: "8px" }}
                    >
                      <button
                        className="btn btn-secondary shadow"
                        onClick={() => {
                          navigate(`/editstudentdetails/${student.student_id}`);
                        }}
                      >
                        تعديل
                      </button>

                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalpassword"
                        onClick={() => setSelectedStudentId(student.student_id)}
                      >
                        تعديل كلمة السر للطالب
                      </button>

                      <button
                        className="btn btn-success shadow"
                        disabled={Isloading}
                        onClick={(e) => handleLoginAsStudent(e, student)}
                      >
                        تسجيل الدخول بحساب الطالب
                      </button>
                    </div>
                  </td>
                  <td className="text-center">{student.phone_number}</td>
                  <td className="text-center">{student.gaurdian_number}</td>
                </tr>
              );
            })
          ) : (
            <td colSpan={11} className="text-center w-100">
              <h3>ليس هنالك اي حسابات غير مفعلة</h3>{" "}
            </td>
          )}
        </tbody>
      </table>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري تفعيل الحساب ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>

            {Isloading && (
              <div className="modal-body">
                {Isloading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => activateStudentAccount(e, selectedStudentId)}
                >
                  تفعيل
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      {/* Edit student password modal */}
      <div
        class="modal fade"
        id="exampleModalpassword"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                تعديل كلمة السر
              </h5>
              <button
                type="button"
                class="btn-close ml-0  mr-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={newPassWordModalRef}
              ></button>
            </div>
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  كلمة السر الجديدة
                </span>
                <input
                  value={newPassword}
                  onChange={(e) => setNewPassWord(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="كلمة السر"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إلغاء
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => changePassword(selectedStudentId)}
              >
                حفظ التعديلات
                {Isloading && (
                  <div
                    class="spinner-border spinner-border-sm d-inline-block mx-2"
                    style="width: 3rem; height: 3rem;"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
