
import axios from "axios";

export default async function  fetchExamsBySection ({queryKey})
{
          console.log("process",process.env.REACT_APP_API_URL)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_traditional_exams_by_section_id/${queryKey[1]}` ,
          { headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


