import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { debounce } from "lodash";
import ImageWithLoader from "../MessagesBox/ImageWithLoader";
import Header from "../../../components/Header/Header";
import Pagination from "../../../components/Pagination/Pagination";
import Layout from "../../../components/Layout/Layout";

const ParticipantsPage = () => {
    const { subjectId, teacherId } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    function handlePageClick(data) {
        setPage(data.selected + 1);
    }

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/chat/subjects/${subjectId}/participants?teacher_id=${teacherId}&page=${page}&${query}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            )
            .then((res) => {
                setData(res?.data);
                setIsLoading(false);
            });
    }, [query]);
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/chat/subjects/${subjectId}/participants?teacher_id=${teacherId}&page=${page}&${query}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            )
            .then((res) => {
                setData(res?.data);
                setIsLoading(false);
            });
    }, [page]);

    const handelSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const debouncedSearch = useCallback(
        debounce((searchQuery) => {
            setPage(1);
            setQuery(`search=${searchQuery}`);
            setIsLoading(true);
        }, 1000),
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm, debouncedSearch]);

    return (
        <>
            <Layout>
                {/* <Header/> */}

                <div>
                    <div className="row header-sec  ">
                        <div className="col-12 d-flex justify-content-between mt-1 mb-1   ">
                            <div className="col-12 col-md-12 d-flex justify-content-start align-items-center ">
                                <img
                                    src={process.env.REACT_APP_Video_URL + data?.subject?.cover}
                                    alt=""
                                    className=""
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                        marginLeft: "12px",
                                        marginRight: "10px",
                                    }}
                                />
                                <p className="text">
                                    تواصل مع{" "}
                                    {localStorage.getItem("role") == 1 ? "أساتذة" : "طلاب "}{" "}
                                    {data?.subject?.name}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 text-center">
                    <input
                        type="text"
                        value={searchTerm}
                        placeholder="بحث بالاسم"
                        onChange={(e) => handelSearch(e)}
                        className="shadow w-100 main-input rounded mt-3 p-2"
                        style={{
                            height: "60px",
                            maxWidth: "500px",
                            border: "2px solid #222 !important",
                            fontSize: "1.2rem",
                            marginBottom: '15px'
                        }}
                    />
                </div>
                {isLoading && (
                    <div className="w-100 my-5 text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <div>
                        {data?.data?.data?.map((e) => {
                            return (
                                <Link
                                    to={`/subjects/${subjectId}/teachers/${teacherId}/chat/participants/${e.id}`}
                                >
                                    <div className="row "
                                        style={{ backgroundColor: e.unread_messages_count > 0 ? '#26979466' : '' }}
                                    >
                                        <div
                                            className="container-fluid d-flex justify-content-between align-items-center my-3 pt-2">
                                            <div className="col-lg-1 col-sm-2 d-flex  ">
                                                <div className="online_div position-relative">
                                                    <ImageWithLoader
                                                        size={75}
                                                        src={`${process.env.REACT_APP_Video_URL}${e.image}`}
                                                        alt=""
                                                        className="rounded-circle border border-white img"
                                                        styley={{
                                                            width: "48px",
                                                            height: "48px",
                                                            marginLeft: "12px",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    {e.is_online && <div className="online"></div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-sm-9">
                                                <div
                                                    className="col-11 d-flex justify-content-between mx-2 align-items-center">
                                                    <div className="col-8 d-flex justify-content-start">
                                                        <p className="text-ppp">{e.name}</p>
                                                    </div>
                                                    <div
                                                        className="col-3 col-sm-4 d-flex justify-content-end align-items-center">
                                                        <p
                                                            style={{ direction: "ltr" }}
                                                            className="text-ppp"
                                                        >
                                                            {e.last_message_time}
                                                        </p>
                                                        {/* Place unread message count at the end */}
                                                        {e.unread_messages_count > 0 && (
                                                            <span className="unread-badge ms-2">
                                                                {e.unread_messages_count}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-11">
                                                    <div className="">
                                                        <p className="text-ppp mt-2 d-flex justify-content-center">
                                                            {e.last_message_content ?? (
                                                                <span className="box-with-p text-ppp">
                                                                    قم بإرسال رسالتك الأولى
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border mx-auto" style={{ width: "100%" }}></div>
                                </Link>
                            );
                        })}


                    </div>
                )}

                <div>
                    {!isLoading &&
                        (data?.total < 1 ? (
                            <> </>
                        ) : (
                            <Pagination
                                data={data?.data}
                                handlePageClick={handlePageClick}
                                page={page}
                            />
                        ))}

                    <div className="mb-5" />
                </div>
            </Layout>

        </>
    );
};

export default ParticipantsPage;
