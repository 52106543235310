import React from 'react';
import './SubjectAchivment.css';

const SubjectAchivement = ({name , hours , achivedPercentage , totalHours}) => {

    const changeColor = (progress) => {
        if (progress <= 25) {
          return "#FF0000";
        } else if (progress <= 50) {
          return " #FFA500";
        } else if (progress <= 75) {
          return " #FFFF00ee";
        } else {
          return "linear-gradient(180deg, rgba(110,255,50,1) 0%, rgba(215,255,0,1) 46%, rgba(231,246,246,0.7987570028011204) 100%)";
        }
    };

    return <div className='studentSubject_achivmentCard'> 
    <h3 className='text-center achivmentCard_title'>{name}</h3>
        <div class="g-container">

    <div className="g-number">{hours}</div>
    <div className="g-from">من أصل</div>
    <div className="second-g-number">{totalHours}</div>
    <div className="g-percentage">{achivedPercentage}%</div>
    
    <div className='percentage_achivmentCard'>
    <div className="percentage-backface-layer"></div>
        <div className='percentage_itSelf' style={{background: changeColor(achivedPercentage ) , height: `${achivedPercentage <= 4 ? 4 : achivedPercentage  }%`}}/>
    </div>

    <div class="g-contrast">
      <div class="g-circle">

      </div>
      
      {/* <ul class="g-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
    </div>
    </div>
    </div>
}

export default React.memo(SubjectAchivement);