import React, { useEffect, useState } from "react";
import Edit_Automated_Quiz_Unit_Logic from "./EditQuizBankLogic";
import katex from "katex";
import { Editor } from "@tinymce/tinymce-react";
import "katex/dist/katex.min.css";
import ReactQuill from "react-quill";
import Layout from "../../components/Layout/Layout";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";

window.katex = katex;

export default function EditQuizBank() {
  const [errorMessage, setErroreMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [pdfPreview, setPdfPreview] = useState(null); // State for PDF preview
  const {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    create_unit_automated_quiz_form_error,
    create_unit_automated_quiz_form_progress,
    create_unit_automated_quiz_form_success,
    edit_unit_automated_quiz_form_success,
    QuizId,
    questionText,
    onSubmit,
  } = Edit_Automated_Quiz_Unit_Logic({setPdfPreview});

  useEffect(() => {
    if (edit_unit_automated_quiz_form_success) {
      setSuccessMessage("تم تعديل الاختبار بنجاح");
    }
  }, [edit_unit_automated_quiz_form_success]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfPreview(URL.createObjectURL(file)); // Create preview URL
    } else {
      setErroreMessage("الرجاء اختيار ملف بصيغة PDF");
      setPdfPreview(null);
    }
  };

  return (
    <Layout>
      <ToastSuccessError
        ErrorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErroreMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <div className="container all">
        <form onSubmit={handleSubmit(onSubmit)} className="row g-3 needs-validation" noValidate>
          <div className="col-md-4">
            <label htmlFor="QuizeName" className="form-label">اسم الكويز</label>
            <input type="text" className="form-control" id="QuizeName" {...register("nameOfQuiz", { required: true })} />
            {errors.QuizeName && <p className="text-danger">Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="quizType" className="form-label text-end">نوع الاختبار</label>
            <select className="form-control" id="quizType" {...register("quizType", { required: true })}>
              <option value="above_level">فوق المستوى</option>
              <option value="final">نهائي</option>
              <option></option>
            </select>
            {errors.quizType && <p className="text-danger">الرجاء اختيار نوع الاختبار</p>}
          </div>

          <label htmlFor="QuizeName" className="form-label">النص العام للاختبار المؤتمت</label>
          <ReactQuill
            style={{ height: "300px", marginBottom: "120px" }}
            theme={"snow"}
            onChange={(e) => (questionText.current = e)}
            value={questionText.current}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={"أدخل النص ..."}
          />

          <div className="col-md-4">
            <label htmlFor="points" className="form-label">الدرجة</label>
            <input type="number" {...register("points", { required: true })} className="form-control degreeinput" id="degree" />
            {errors.degree && <p className="text-danger">Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="descriptionQuize" className="form-label">الوصف</label>
            <input type="text" {...register("description", { required: true })} className="form-control" id="descriptionQuize" />
            {errors.descriptionQuize && <p className="text-danger">Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="duration" className="form-label">المدة</label>
            <input type="number" {...register("duration", { required: true })} className="form-control" id="duration" />
            {errors.duration && <p className="text-danger">Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="file" className="form-label text-end">رفع ملف PDF</label>
            <input
              type="file"
              accept="application/pdf"
              className="form-control"
              id="file"
              {...register("file", { required: false })}
              onChange={handleFileChange} // Trigger preview on file selection
            />
            {errors.file && <p className="text-danger">الرجاء رفع ملف بصيغة PDF</p>}
          </div>

          {/* PDF Preview */}
          {pdfPreview && (
            <div className="col-md-12 mt-3">
              <label className="form-label">معاينة الملف:</label>
              <embed
                src={pdfPreview}
                type="application/pdf"
                width="100%"
                height="400px"
                style={{ border: "1px solid #ddd" }}
              />
            </div>
          )}

          <div className="col-12">
            <button disabled={!isDirty || !isValid} className="btn btn-success" type="submit">
              رفع الاختبار
            </button>
            {create_unit_automated_quiz_form_error && (
              <label className="text-center w-100 text-danger">
                {create_unit_automated_quiz_form_error?.response
                  ? create_unit_automated_quiz_form_error.response.data.message
                  : create_unit_automated_quiz_form_error.message}
              </label>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
}

Editor.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: [1, 2, 3, false] }],
    ["link", "image", "formula"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};
