import React, { useEffect, useRef, useState } from 'react';
// import Button from '../../components/button';
// import chalks from '../../assets/icons/chalks.png';
import './UnitSubscribtions.css';
import Layout from '../../components/Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import fetchStudents from '../../redux-toolkit/api/fetchStudents';
import StudentMessageTable from '../../components/ConfirmRequest/StudentsMessagesTable';
import Pagination from '../../components/Pagination/Pagination';

import CloseIcon from '../../assets/students/close.svg'
import fetchSectionsApi from '../../redux-toolkit/api/fetchSections';
import fetchSubSectionsApi from '../../redux-toolkit/api/fetchSubSections';
import fetchSubjectApi from '../../redux-toolkit/api/fetchSubject';
import fetchAllUnits from '../../redux-toolkit/api/fetchAllUnits';
import AllUnitsTable from '../../components/ConfirmRequest/AllUnitsTable';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import fetchProvinces from '../../redux-toolkit/api/fetchProvinces';


const UnitSubscribtions = () => {

    const [query,setQuery] = useState('');
    const unOrderStudentsList = useRef();
    const inputSearchRef = useRef();
    const [searchResult,setSearchResult] = useState('');
    const [page,setPage] =useState(0);
    const [selectedStudents,setSelectedStudents] = useState([]);
    const [isMsgForAll,setIsMsgForAll] = useState(false);
    const [showForm,setShowForm] = useState(false);
    const [showSendMessageForm,setShowSendMessageForm] = useState(false);


    const [selectedSection,setSelectedSection] = useState(7);
    const [selectedSubSection,setSelectedSubSection] = useState(null);
    const [selectedSubject,setSelectedSubject] = useState(null);

    const [selectedUnits ,setSelectedunits] = useState([]);
    const [ErrorMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [isloading,setIsLoading] = useState(false);
    const closeModalRef = useRef(null);

    
  const [provinceId,setProvinceId] = useState( localStorage.getItem('provindeIdFilterUnits') ? localStorage.getItem('provindeIdFilterUnits') : '');
  const [genderFilter,setGenderFilter] = useState(localStorage.getItem('genderFilerUnits') ? localStorage.getItem('genderFilerUnits') : '');
  const [sectionoFilter,setSectionFilter] = useState(localStorage.getItem('sectionFilterUnits') ? localStorage.getItem('sectionFilterUnits') : '');
  const [subSectionoFilter,setSubSectionFilter] = useState(localStorage.getItem('subSectionFilterUnits') ? localStorage.getItem('subSectionFilterUnits') : '');


    const {
        isLoading: isLoadingSections, 
        isError: isErrorSections, 
        data: dataSections, 
        error: errorSections} = 
        useQuery(['sections', 1], fetchSectionsApi);
  
      const {
        isLoading: isLoadingSubSections, 
        isError: isErrorSubSections, 
        data: dataSubSections, 
        error: errorSubSections} = 
        useQuery(['subSections', 1 , sectionoFilter], fetchSubSectionsApi);

    const {
        data: sectionsData,
        isLoading: isLoadingSectionsData
    } = useQuery(['sections'] , fetchSectionsApi)

    const {
        data: subsectionsData,
        isLoading: isLoadingSubSectionsData
    } = useQuery(['subsections' , 1 , selectedSection ] , fetchSubSectionsApi)

    const {
        data: subjectsData,
        isLoading: isLoadingSubjectsData
    } = useQuery(['subjects' , 1 , selectedSubSection ] , fetchSubjectApi)

    const {
        data: unitsData,
        isLoading: isLoadingunitsData
    } = useQuery(['units' , 1 , selectedSubject ] , fetchAllUnits)

    const {
        data: provinces,
        isLoading: isLoadingProvinces,
        isErrorProvinces,
      } = useQuery(["provinces"], fetchProvinces);


    useEffect(() => {
        if(sectionsData?.data?.length > 0){
            setSelectedSection(sectionsData?.data[0]?.section_id);
        }
    },[sectionsData]);

    
  function resetSubsectionFilter() {
    setSubSectionFilter('');
    localStorage.setItem('subSectionFilterUnits' , '');
  }

    useEffect(() => {
        if(subsectionsData?.data?.data?.length > 0){
            // console.log('jsdkljfklsjdlfkjlsdjfljsdlkf')
            setSelectedSubSection(subsectionsData?.data?.data[0]?.sub_section_id);
        }
        // console.log('selected sub sections isi si ', subsectionsData?.data);
    },[subsectionsData]);

    useEffect(() => {
        if(subjectsData?.data?.length > 0){
            setSelectedSubject(subjectsData?.data[0]?.subject_id);
        }
        // console.log('subject ddddsljdjklk',subjectsData?.data)
    },[subjectsData]);

    let shouldSetQuery = true;

    const {
        isLoading , 
        isError, 
        data, 
        error} = 
        useQuery(['students', page , query , 1 , provinceId , genderFilter , sectionoFilter , subSectionoFilter], fetchStudents);

    function handlePageClick (data)
    {
        localStorage.setItem('UnitSubscribtions' , data.selected)
        setPage(data.selected);
    }

    function handleOnFucusInput(){
        // if(typeOfTable == 1) {
        //   localStorage.setItem('allstudents_pag',0);
        //   setPage(0);
        // }
    
        // if(typeOfTable == 2) {
        //   localStorage.setItem('unactivestudents_pag',0);
        //   setPageUnActive(0);
        // }
    
        // if(typeOfTable == 3){
        //   localStorage.setItem('newstudents_pag',0);
        //   setpageNew(0);
        // }
    
        // if(typeOfTable == 4){
        //   localStorage.setItem('activestudents_pag',0);
        //   setPageActive(0);
        // }
    }

    const setQueryOnTablesType = (value) => {
        setQuery(value);
        console.log('query is => ', value);
    }

    const setQueryNotOnEveryChnage = (value) => {
        
        console.log('value is ', value);
      
        setQueryOnTablesType(value);

    
        setTimeout(() => {
          shouldSetQuery = true;
        }, 1000);
    
    }

    const handleSearchStudent = (e) => {
        const value = e.target.value;
        unOrderStudentsList.current.style.height = 'auto';
        // setQuery(value);
        console.log('vvvv',value);
        setQueryNotOnEveryChnage(value);
        handleOnFucusInput();
        handleUnorderStudentsList(e.target.value);
    
        // setValue('student_id',e.target.value);
    }
    
    const devouncedFn = debounce(handleSearchStudent , 500);
    
    const handleUnorderStudentsList = (searchQuery) => {
      if(searchQuery == '') {
          unOrderStudentsList.current.style.height = '0px';
      }else{
          unOrderStudentsList.current.style.height = 'auto';
      }
    }

    const deleteStudentFromArray = (currentStudent) => {
        const newCurrentStudent = selectedStudents.filter((student) => student.student_id != currentStudent.student_id);
        setSelectedStudents([...newCurrentStudent]);
    }

    const deleteUnitFromArray = (currentUnit) => {
        const newCurrentUnitsArray = selectedUnits.filter((unit) => unit.unit_id != currentUnit.unit_id);
        setSelectedunits([...newCurrentUnitsArray]);
    }
    const handleChangeStatus = (e) => {

        if(e.target.value == 'all') {
            setIsMsgForAll(true);
        }else {
            setIsMsgForAll(false);
        }

    }

    useEffect(() => {
        if(!isMsgForAll){
            setShowSendMessageForm(false);
        }
    },[isMsgForAll]);

    const logValue = debounce((value) => {
        setQuery(value);
    }, 500);

    const onChange = (e) => {
        logValue(e.target.value);
    }

    const handlePostunitsForStudents = () => {
        const unitsArrayToSend = selectedUnits.map((unit,id) => unit.unit_id);
        const studentsArrayToSend = selectedStudents.map((student,id) => student.student_id);
        
        setIsLoading(true);

        const dataToSend = {
            units_ids: unitsArrayToSend ,
        }

        if(isMsgForAll){
            dataToSend.all = 1 ;
        }else {
            dataToSend.students_ids = studentsArrayToSend
        }

        console.log('data to send', dataToSend);

         axios.post(`${process.env.REACT_APP_API_URL}/register_units_subscriptions`,
         dataToSend,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
         .then(response => {
            
             if(response.status == 200){
             setSuccessMessage("تم فتح الوحدات بنجاح")
             setSelectedStudents([]);
             setSelectedunits([]);
         }
         })
         .catch(error => {

        //     setErrorMessage(error.response.data.message);
            
         })
         .finally(() => {
            setIsLoading(false);
            closeModalRef.current.click();
         })

    }

  return (
      <Layout>
    
        <ToastSuccessError 
        successMessage={successMessage} 
        ErrorMessage={ErrorMessage} 
        setSuccessMessage={setSuccessMessage}    
        setErrorMessage={setErrorMessage}    
        />

    <div className='profileScreen container'>
        <div className='profileScreen__content'>


    <div className=''>
    <div className='d-flex flex-row gap-2 justify-content-between flex-wrap '>

   
    <div className='d-flex flex-column justify-content-center align-items-center'>
              <p>اختر قسم</p>
              <select onChange={(e) => setSelectedSection(e.target.value)} className="form-select customSelect" aria-label="Default select example">
            {
                sectionsData && sectionsData?.data?.map((section,index) => {
                    if(index == 0) {
                        return  <option selected key={index} value={section.section_id}>{section.section_name}</option>
                    }else {
                        return <option  key={index} value={section.section_id}>{section.section_name}</option>
                    }
                })
            }
          </select>
          </div>

          <div className='d-flex flex-column justify-content-center align-items-center'>
              <p>اختر دورة</p>
              <select onChange={(e) => setSelectedSubSection(e.target.value)} className="form-select customSelect" aria-label="Default select example">
            {/* <option selected></option> */}
            {
                subsectionsData?.data && subsectionsData?.data?.data?.map((section,index) => {
                    if(index == 0 ){
                    return <option selected key={index} value={section.sub_section_id}>{section.sub_section_name}</option>
                    }else {
                    return <option key={index} value={section.sub_section_id}>{section.sub_section_name}</option>
                    }
                }
                )
            }
          </select>
          </div>

          <div className='d-flex flex-column justify-content-center align-items-center'>
              <p>اختر مادة</p>
              <select onChange={(e) => setSelectedSubject(e.target.value)} className="form-select customSelect" aria-label="Default select example">
            {/* <option selected></option> */}
            {
                subjectsData?.data && subjectsData?.data?.map((section,index) => {
                    if(index == 0 ){
                    return <option selected key={index} value={section.subject_id}> {section.subject_name}</option>
                    }else {
                    return <option key={index} value={section.subject_id}>{section.subject_name}</option>
                    }
                }
                )
            }
          </select>
          </div>

          </div>
        {
            selectedSubject &&
          <AllUnitsTable 
          data={unitsData} 
          isLoading={isLoadingunitsData} 
          setSelectedunits={setSelectedunits} 
          selectedUnits={selectedUnits} 
          />
        }

            <div className='mt-3 mb-2 d-flex flex-wrap gap-3 border shadow p-3 mb-5'>
            <h4 className='w-100 text-center'>الوحدات المختارة</h4>
            {
                selectedUnits.length == 0 ? 
                <div className='selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2'>
                    <h6 className='mb-0'>لم يتم اختيار أية وحدات بعد </h6  >
                </div> 

                :
                  selectedUnits.map((student,idx) => {
                    return <div className='selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2'>
                        <img src={CloseIcon} width={20} height={20} className="closeIcon"  
                            onClick={() => deleteUnitFromArray(student)}
                        />
                        <h6 className='mb-0 mx-auto'> {student.subject_name} / {student.unit_name}</h6  >
                    </div> 
                })
            }
            </div>

            <div className=''>
                {
                    (selectedStudents.length > 0 || isMsgForAll) && selectedUnits.length > 0 ?
                    (
                        isMsgForAll ? 

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalallunits">
                    فتح الوحدات المختارة لجميع الطلاب
                    </button>
                        : 
                    
                    <button class="btn btn-primary" type="button" onClick={handlePostunitsForStudents}>
                    فتح الوحدات المختارة للطلاب 

                    {
                        isloading && <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="visually-hidden">Loading...</span>
                        </>
                    }

                </button>
                    )
                    : 
                    null

                   
                }
            </div>

            <div className='mt-3 mb-2 d-flex flex-wrap gap-3 border shadow p-3'>
            <h4 className='w-100 text-center'>الطلاب الذين تم اختيارهم</h4>
            {
                isMsgForAll ? 
                <div className='selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2'>
                        
                        <h6 className='mb-0'>جميع الطلاب</h6  >
                    </div> 

                    : 

                    selectedStudents.map((student,idx) => {
                    return <div className='selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2'>
                        <img src={CloseIcon} width={20} height={20} className="closeIcon"  
                            onClick={() => deleteStudentFromArray(student)}
                        />
                        <h6 className='mb-0'>{student.name}</h6  >
                    </div> 
                })

                    
            }
        
            </div>



{/* <div className='d-flex flex-column justify-content-center align-items-center'>
              <p>اختر  استاذ</p>

              <select onChange={(e) => handleTeacherChange(e)}  className="form-select  customSelect" aria-label="Default select example">
        <option selected>Choose the teahcer</option>
      
      </select>

</div> */}
    </div>




        <input 
        type='text' 
        // onFocus={() => handleOnFucusInput()} 
        ref={inputSearchRef} 
        placeholder='اكتب هنا للبحث عن طالب' 
        onChange={(e) => onChange(e)} className='shadow w-100 main-input border-0'
        />

        <div className='d-flex flex-column gap-2 mt-4'>

        <label className='mb-0 mx-3 d-flex align-items-center gap-2'>
            <input 
            type={'checkbox'} 
            checked={isMsgForAll}  
            value={'all'} 
            onChange={handleChangeStatus} 
            />
        الجميع
        </label>

        <label className='mb-0 mx-3 d-flex align-items-center gap-2'>
            <input 
            type={'checkbox'} 
            checked={!isMsgForAll}  
            value={'some'} 
            onChange={handleChangeStatus} 
            />
        مخصص
        </label>
        </div>

                   
        <div className="d-flex flex-wrap  p-5">
{/* provinces filter */}
<div className="col-sm-12 col-md-3 form-outline mb-2">
 
  <label className='d-flex align-items-center'>
  
  {/* <input 
  type='checkbox'
  className='cursor-pointer mx-2' 
  checked={activeProvincesFilter} 
  onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
  المحافظات 
  </label>

      <select
        // disabled={!activeProvincesFilter}
        id="form3Example4"
        onChange={(e) => {setProvinceId(e.target.value) ; localStorage.setItem('provindeIdFilterUnits', e.target.value)}}
        className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
      >
       <option selected={provinceId == ""} value={''}>جميع المحافظات</option>
        {provinces &&
          provinces?.map((province, idx) => {
            return (
              <option 
              selected={province.id == localStorage.getItem('provindeIdFilterUnits')} 
              value={province.id}>{province.name}</option>
            );
          })}
      </select>
    </div>

    <div className="col-sm-12 col-md-3 form-outline mb-2">
  <label>الجنس</label>
      <select
        id="form3Example4"
        onChange={(e) => {setGenderFilter(e.target.value); localStorage.setItem('genderFilerUnits', e.target.value) }}
        className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
      >
       <option value={''} selected={localStorage.getItem('genderFilerUnits') == ""}>الجميع</option>
       <option value={'MALE'} selected={localStorage.getItem('genderFilerUnits') == "MALE"}>ذكور</option>
       <option value={'FEMALE'} selected={localStorage.getItem('genderFilerUnits') == "FEMALE"}>إناث</option>

      </select>
    </div>

    

<div className="col-sm-12 col-md-3 form-outline mb-2">
 
 <label className='d-flex align-items-center'>
 
 {/* <input 
 type='checkbox'
 className='cursor-pointer mx-2' 
 checked={activeProvincesFilter} 
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
 الأقسام 
 </label>

     <select
       // disabled={!activeProvincesFilter}
       id="form3Example4"
       onChange={(e) => {setSectionFilter(e.target.value) ; resetSubsectionFilter(); localStorage.setItem('sectionFilterUnits', e.target.value)}}
       className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
     >
      <option selected={sectionoFilter == ""} value={''}>جميع الأقسام</option>
       {dataSections &&
        dataSections?.data?.map((section, idx) => {
           return (
             <option 
             selected={section.section_id == localStorage.getItem('sectionFilterUnits')} 
             value={section.section_id}>{section.section_name}</option>
           );
         })}
     </select>
   </div>

   {
   
    <div className="col-sm-12 col-md-3 form-outline mb-2">
 
 <label className='d-flex align-items-center'>
 
 {/* <input 
 type='checkbox'
 className='cursor-pointer mx-2' 
 checked={activeProvincesFilter} 
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
 الدورات 
 </label>

     <select
       // disabled={!activeProvincesFilter}
       id="form3Example4"
       disabled={ sectionoFilter == ''}
       onChange={(e) => {setSubSectionFilter(e.target.value) ; localStorage.setItem('subSectionFilterUnits', e.target.value)}}
       className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
     >
      <option selected={subSectionoFilter == ""} value={''}>جميع الدورات</option>
       {dataSubSections &&
        dataSubSections?.data?.data?.map((subSection, idx) => {
           return (
             <option 
             selected={subSection.sub_section_id == localStorage.getItem('subSectionFilterUnits')} 
             value={subSection.sub_section_id}>{subSection.sub_section_name}</option>
           );
         })}
     </select>
   </div>
   }

</div>





           <StudentMessageTable data={data?.data} isLoading={isLoading} selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents} />
            
            <div className='my-4'>
                <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />
            </div>
               
        </div>


    </div>

    {/* modal for confirmation proccess for open units for all students */}
    <div class="modal fade" id="exampleModalallunits"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        كن حذرا !!! عند تأكيد عملية فتح الوحدات المختارة لجميع الطلاب لن تكون قادرا على التراجع عن العملية
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-success" ref={closeModalRef} data-bs-dismiss="modal">إغلاق</button>
        <button type="button" class="btn btn-danger" onClick={handlePostunitsForStudents}>
        تأكيد العملية
        {
            isloading && <>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
            </>
        }

        </button>
      </div>
    </div>
  </div>
</div>
    </Layout>
  )


}

export default UnitSubscribtions
