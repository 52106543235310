import React, { useEffect, useReducer, useRef, useState } from "react";
import Video from "../Video/Video";
import Image from "../Image/Image";
import "./Home.css";
import Layout from "../Layout/Layout";
import img from "../../assets/Home/chalks (1).png";
import record from "../../assets/Home/record.png";
import HomeLogic from "./HomeLogic";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Circle } from "rc-progress";
import CustomTable from "../CustomTable/CustomTable";
import RegisterImage from "../RegisterImage/RegisterImage";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";

const Home = () => {


  return (
    <>
      <Layout>
      
      <div className="container pt-5" style={{height: '90vh'}}>

        <div className="w-100" style={{display: 'grid' , placeItems: 'center', height: '100%'}}>

          <div className="topbuttons">


          </div>

          <Link to="/websitetexts" className="btn btn-primary shadow">
            نصوص الموقع
          </Link>

          <Link to="/websitefiles" className="btn btn-info shadow">
            صور وفيديوهات الموقع
          </Link>

          <Link to="/updateapplication" className="btn btn-success shadow">
            معلومات تحديث تطبيق الموبايل
          </Link>

        </div>
      </div>
      </Layout>
    </>
  );
};

export default Home;
