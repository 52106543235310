
import Layout from '../../components/Layout/Layout';
import './Loading.css';

const Loading = () => {
    return <Layout>

    <div className='w-100 text-center py-5' style={{height: '80vh'}}>


        <div className='py-5'/>
        <div className='py-5'/>
        <div className='py-5'/>


    <div class="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
    </div>


    </div>
    </Layout>
}

export default Loading;