import React, {useState, useEffect, useRef } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
// import Image from '../Image/Image'
import SubSectionsImage from './SubSectionsImage';
import record from '../../assets/Home/record.png'
import { useForm } from 'react-hook-form';
import SubSectionsLogic from './SubSectionsLogic';
import Layout from '../Layout/Layout';

import './SubSections.css';

import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { Circle } from 'rc-progress';
import fetchCoupons from '../../redux-toolkit/api/fetchCoupons';
import { useQuery } from 'react-query';


export default function SubSections ({handleAdd , subscribtionPage}) {


  const [success , setSuccess ] = useState(false);
  const [faild , setFaild ] = useState(false);
  const [successMessage , setSuccessMessage] = useState('');
  const [ErrorMessage , setErrorMessage] = useState('');


   const params = useParams();
   console.log("paramsss" ,params)
   const id= params?.id;

   const location = useLocation();
   const AddSubId = location?.state?.id

   console.log("locationnn" ,location?.state);
    const [idTeacher,setIdTeacher] = useState(null);
    const [subSectionId,setSubSectionId] = useState(null);


    const [teachers , setTeachers] = useState([]);
    const [subSubSections,setSubSetSubSections] = useState([]);
    const [SubSelectedSection  , setSelectedSubSection] = useState([]);
    const [SubSections,setSubSections] = useState([]);
    const [selectedSection,setSelectedSection] = useState(null);


    const { reset,getValues ,formState,watch ,register, handleSubmit ,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
      "sub_section_name":"",
      "sub_section_image":null,
      "coupon_id": null ,
      "price": 0 ,
      "addSubSections": 0
    }});

const{valid,handleSubmitApi,handleUpdateApi,AddSubscribtion,progress,IsLoading} =  SubSectionsLogic(subscribtionPage,id,reset , idTeacher,subSectionId,AddSubId,setSuccessMessage,setErrorMessage,setSuccess , setFaild);

const {isLoading, isError, data, error} = useQuery(['coupons'], fetchCoupons);   


// const [selectedTeacher , setSelectedTeacher]= useState(null)



  useEffect( () => {
    // console.log("iddd ٍsubsections",id);
    if(id){
    const SubSectionsFromAPI =  axios.get(`${process.env.REACT_APP_API_URL}/get_sub_section_by_sub_section_id/${id}?limit=19`)
    .then(data => {
      console.log('log SubSectionssssssssss',data.data);
      setSubSections(data.data.data);
      reset(data.data.data);
    });
  }
else console.log("idddElse",id);



  },[])





const tempId = id || 0
console.log("tempIDdd",tempId);
// console.log("Subscribtionnnnnn nameeeeeeeee", getValues().subscription_name);

const changeColor = (progress) => {
  if(progress <= 25){
      return '#ff0707';
  }else if(progress <= 50){
      return '#ff6907';
  }else if(progress <= 75){
      return '#ffdb07';
  }else {
      return '#77ff07';
  }
}

  return (
    <Layout>
    <div className='container'>

    <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

    {
           IsLoading && <div className={`uploadPrgressbar`}>
            <h2>جار إضافة القسم الفرعي ... </h2>
            
            <div className='content' style={{dispaly: typeof progress != 'number' ? 'none': 'flex'}}>
                {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
                <p>{progress}%</p>
                <Circle percent={progress} strokeWidth={4} strokeColor={changeColor(progress)} /> 
            </div>
        </div>
    }

        <form  onSubmit={handleSubmit(handleSubmitApi)}>
        <div className='mb-3 pt-5'>
                <div className='mb-3 d-flex align-items-center gap-5 pt-5'>
                    <img src={record} className='record mx-2'/>
                    <label className='fw-bold'> صورة القسم   </label>
                </div>
              <SubSectionsImage
              valid={valid} 
              register={register}
              id={id}
              subscribtionPage={SubSections}
              AddSubscribtion={AddSubscribtion}
              errors={errors}
              getValues={getValues}
          />
            </div>
              <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2' />
                    <label className='fw-bold'> اسم القسم</label>
                </div>
                <div className="d-flex ">
               <input type='text' className='shadow w-50 py-2 border-0'
                {...register("sub_section_name", { required: true })}
               />
               {errors.name && <p className='text-danger'>*required</p>}
               </div>
               </div>   

               <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2' />
                    <label className='fw-bold'> سعر القسم </label>
                </div>
                <div className="d-flex ">
               <input type='number' className='shadow w-50 py-2 border-0'
                {...register("price", { required: true })}
               />
               {errors.price && <p className='text-danger'>*required</p>}
               </div>
               </div> 


                <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2' />
                    <label className='fw-bold'> هل هذا القسم للبيع ؟  </label>
                </div>
               
               <div className="d-flex ">
                <select class="form-select" {...register("is_for_selling", { required: true })} aria-label="Default select example">
                {/* <option value={null}>كوبون الخصم (اختياري)</option> */}
                <option value={0}>لا</option>
                <option value={1}>نعم</option>

                </select>
               </div>
               </div>

                             <div className='mb-3'>
                <div className='mb-3 d-flex align-items-center'>
                    <img src={record} className='record mx-2' />
                    <label className='fw-bold'> كوبون الخصم </label>
                </div>


                <div className="d-flex ">
                <select class="form-select" {...register("coupon_id", { required: false })} aria-label="Default select example">
                <option value={null}>كوبون الخصم (اختياري)</option>
                {
                    data?.data?.data?.map((coupon) => {
                      return <option value={coupon.id}>
                      
                      نسبة الخصم {coupon.discount_percentage}%
                      
                      
                         
                     الصلاحية  {coupon.expires_at}
                      
                      </option>
                    })
                  }
                </select>
               </div>
               </div>    
 



            <div className='d-flex justify-content-center mb-5'>
            {tempId ?
                   <button  className=' btn btn-success' 
                  //  onClick={()=>handleUpdateApi(getValues() , id)}
                   >
                    <h6>تعديل </h6>
                   </button>
                   :
                   <button   className=' info  py-2 px-5 border-0'>
                    <h6>إضافة </h6>
                   </button>
            }
            </div>

          {/* <h1>{formState.errors?.content }</h1> */}
         </form>
    </div>

    </Layout>
  )
}




