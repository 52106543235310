import React, {  useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Add_Automated_Quiz_Quistions_For_Lesson_Logic() {
  const dispatch = useDispatch();
  const [errorMessage,setErroreMessage] = useState('');
  const [successMessage,setSuccessMessage] = useState('');
  const [isLoading,setIsLoading] = useState(false);

  const question = useRef(null);
  const firstOption = useRef(null);
  const [isFirstTrue,setIsFirstTrue] = useState(true);

  const secondOption = useRef(null);
  const [isSecondTrue,setIsSecondTrue] = useState(false); 
  
  const fourthOption = useRef(null);
  const thirdOption = useRef(null);

  const [isThirdTrue,setIsThirdTrue] = useState(false);



  const [isFourthTrue,setIsFourthTrue] = useState(false);


  const { 
    control,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      question: "",
      correct: 0,
      point: 1 , 
      options: [
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "options",
  });

  const { QuizId } = useParams();

  // console.log("quuuuuiiiiizzzz", QuizId);

  const onSubmit = async (data) => {
    data.options[data.correct].is_true = 1;
    // console.log('quiz options options options', data.options)
    let lastOptionsForSendToDB = [];

    for(let i = 0 ; i < data.options ; i++) {
      // console.log('option index ',data.options[i]);
      // if(option.answear != ''){
      //   lastOptionsForSendToDB.push(option);
      // }else {
      //   continue;
      // }
    }

    // console.log('last options last options', lastOptionsForSendToDB);

    const answers = [

      firstOption.current,
      secondOption.current,
      thirdOption.current,
      fourthOption.current,
    ];

    // console.log('answers is =>', answers)
    
    const lastOptionsToSendToDB = data.options.map((option,index) => {
      return {
        answear: answers[index],
        is_true: option.is_true
      }
    })
    // const options =  answers.map((answer) => {
    //   if(answer != '')
    //   return {
    //     answer,
    //     is_true: 
    //   }
    // })

    // console.log('last to db',lastOptionsToSendToDB);

    const formdata = {
      question: question.current,
      automated_quiz_id: QuizId,
      options: lastOptionsToSendToDB.filter(option => option.answear != ''),
      point: data.point,
      question_note: data.question_note,
    };

    console.log('form data to post is ', formdata);
    setIsLoading(true);
// add_automated_quiz_question_with_its_options4
    axios.post(`${process.env.REACT_APP_API_URL}/add_automated_quiz_question_with_its_options`, 
    formdata, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(() => {
        setSuccessMessage('تمت اضافة السؤال بنجاح')
    })
    .catch(() => {
        setErroreMessage('حدثت مشكلة ما يرجى المحاولة مرة أخرى')
    })
    .finally(() => {
        setIsLoading(false)
    })
  };

  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    onSubmit,
    fields,
    // data,
    watch,
    question,
    firstOption,
    secondOption,
    thirdOption,
    fourthOption,
    isLoading,
    errorMessage,
    successMessage,
    setErroreMessage,
    setSuccessMessage,
  };
}
