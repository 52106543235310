import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import fetchTeachers from '../../redux-toolkit/api/fetchTeachers';
import AdminsTable from '../../components/ConfirmRequest/AdminsTable';
import './Admins.css';
import { NavLink } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png';
import fetchAdmins from '../../redux-toolkit/api/fetchAdmins';

const Teachers = (props)=> {

  const [page,setPage] =useState(+localStorage.getItem('admins_pag') == null ? 1 : Number(+localStorage.getItem('admins_pag')));
  const {isLoading, isError, data, error} = useQuery(['payments',page], fetchAdmins);

    function handlePageClick (data)
    {
      localStorage.setItem('admins_pag' , data.selected)
    setPage(data.selected+1);
    }

    return <Layout>
          <div className='container all'>
          <h1 
          className='pt-0 mb-1 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >الأدمنز
          </h1>

          <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4'>
               <h5 className='gre'> أضف أدمن</h5> 
                <NavLink to='/addadmin'>
                <img src={group} className="me" />
                </NavLink>
            </div>
           
        <div className='d-flex justify-content-around mb-5'>


        {
                <AdminsTable data={data?.data} isLoading={isLoading}/>
        }

          </div>
          {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
                 

          
        </div>

  
    </Layout>
}

export default Teachers;