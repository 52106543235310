import React, {useState, useRef} from 'react'
import Show_Automated_Quiz_Options_Logic from './ShowQuizBankQuestionsLogic'
import wrongAnswer from '../../assets/Menu/wrongcheckbox.png';
import rightAnswer from '../../assets/Menu/rightcheckbox.png';
// import Error from '../alerts/Error/Error';
// import { delete_automated_quiz_question_with_its_options_by_automated_quiz_question } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
// import ToastError from '../ToastSuccessError/ToastError';
import ReactQuill from 'react-quill';
import katex from "katex";
import { Editor } from "@tinymce/tinymce-react";
import "katex/dist/katex.min.css";
import './index.css';
import uuid from 'react-uuid';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import Layout from '../../components/Layout/Layout';
window.katex = katex;

export default function ShowQuizBankQuestions() {
    const { data,isLoading , isError } = 
    Show_Automated_Quiz_Options_Logic();
// console.log(data)

const [question,setQuestion] = useState('');
const [questioData,setQuestionData] = useState();
const [answerData,setAnswerData] = useState();
const [isEditing,setIsEditing] = useState(false);

const [errorMessage,setErrorMessage] = useState('');
const [successMessage,setSuccessMessage] = useState('');
const [whatEdit,setWhatEdit] = useState('');
const [editQuestionNote,setIsEditQuestionNote] = useState(false);
const questionRef = useRef();
const questionNoteRef = useRef();
const answerRef = useRef();

const handleQuestionChange = (e) => {

    const value = e.target.value;

    setQuestion(value);

    // cosole.log(question)
}


    const handleEditQuestion = (question) => {
        // console.log(question);
        setIsEditQuestionNote(false);
        setWhatEdit('question');

        setQuestion(question.question);

        questionRef.current = question.question;

        setQuestionData(question);

        // console.log('qu4estion',question);
    }

    const handleEditQuestionNote = (question) => {
        setIsEditQuestionNote(true);

        setQuestion(question.question);

        questionNoteRef.current = question.question_note;

        setQuestionData(question);

        // console.log('qu4estion',question);
    }

    const handlePostEditsForQuestion = () => {
        setIsEditing(true);
        
        const dataToSend = {
            point: questioData.point,
            automated_quiz_question_id: questioData.id,
            transable: 0 ,
            question: questionRef.current
            // question_note: questionNoteRef.current
        }

        axios.post(`${process.env.REACT_APP_API_URL}/edit_automated_quiz_question_by_id`,
        dataToSend,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            // console.log('success edit question',data)
            setSuccessMessage('تمت عملية تعديل السؤال بنجاح')
            setTimeout(() => {
                window.location.reload();
            }, 100);
        })
        .catch(error => {
            // console.log('error edit question', error);
            setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
            // console.log('finally')
            setIsEditing(false);
        })
    }

    const handlePostEditsForQuestionNote = () => {
        setIsEditing(true);
        const dataToSend = {
            point: questioData.point,
            automated_quiz_question_id: questioData.id,
            transable: 0 ,
            question: questioData.question,
            question_note: questionNoteRef.current
        }

        // console.log('edit note', dataToSend)

        axios.post(`${process.env.REACT_APP_API_URL}/edit_automated_quiz_question_by_id`,
        dataToSend,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            // console.log('success edit question',data)
            setSuccessMessage('تمت عملية تعديل السؤال بنجاح')
            setTimeout(() => {
                window.location.reload();
            }, 100);
        })
        .catch(error => {
            // console.log('error edit question', error);
            setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
            // console.log('finally')
            setIsEditing(false);
        })
    }

    const handlePostEditsForAnswer = () => {
        setIsEditing(true);

        const dataToSend = {
            aqq_option_id: answerData.id,
            answear: answerRef.current 
        }

        axios.post(`${process.env.REACT_APP_API_URL}/edit_automated_quiz_question_option_by_id`,
        dataToSend,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            // console.log('success edit answer',data)
            setSuccessMessage('تمت عملية تعديل الجواب بنجاح')
            setTimeout(() => {
                window.location.reload();
            }, 100);
        })
        .catch(error => {
            // console.log('error edit answer', error);
            setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
            // console.log('finally')
            setIsEditing(false);
        })
    }

    const handleEditAnswer = (answear) => {
        
        setWhatEdit('answer');
        setIsEditQuestionNote(false);

        setAnswerData(answear);

        answerRef.current = answear.answear;
    }

    const handleDeleteQuestion = (question) => {
        const dataToSend = { };
        axios.delete(`${process.env.REACT_APP_API_URL}/delete_automated_quiz_question_with_its_options_by_id/${question.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            // console.log('success edit answer',data)
            setSuccessMessage('تمت عملية حذف السؤال بنجاح')
            setTimeout(() => {
                window.location.reload();
            }, 100);
        })
        .catch(error => {
            // console.log('error edit answer', error);
            setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
            // console.log('finally')
            setIsEditing(false);
        })
    } 

    return (
        <Layout>
        <div className='container all'>
            <ToastSuccessError 
            successMessage={successMessage}
            ErrorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            />
            <div className='lesson__questions'>
                <ol type='1' className='questions__container'>
                    {
                        isLoading && <div class="d-flex justify-content-center">
                            <div class="spinner-grow text-dark" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }

                    {data?.data?.map((quistion =>



                        <li className={`question`} key={uuid()}>
                          
                            <div className='question__container'>
                            
                                <div className='topsection'>
                                    <h6 dangerouslySetInnerHTML={{__html:quistion.question}}>{}</h6>
                                    <span> {quistion.point} نقطة  </span>
                                <i 
                                style={{
                                    cursor: 'pointer',
                                    margin: '0px 5px'    
                                }} 
                                title="حذف"
                                onClick={()=> handleDeleteQuestion(quistion)} 
                                class="fa fa-trash text-danger fs-2 mr-auto" 
                                aria-hidden="true">
                                </i>

                              
                                


                                <p type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <EditOutlined 
                                    style={{fontSize: '30px !important', margin: '0px 15px'}}
                                    onClick={() => handleEditQuestion(quistion)}
                                    className="fs-2 "
                                    />
                                </p>

                                </div>

                                <div className='topsection gap-3 w-100 text-left'>
                                    <p>ملاحظة السؤال</p>
                                <h6 dangerouslySetInnerHTML={{__html:  quistion.question_note }}>{}</h6>

                                
                                <p type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <EditOutlined 
                                    style={{fontSize: '30px !important', margin: '0px 15px'}}
                                    onClick={() => handleEditQuestionNote(quistion)}
                                    className="fs-2 "
                                    />
                                </p>
                                </div>

                                <div className='answers'>

                                    {quistion?.options?.map((answer, id) => {
                                        return <div key={uuid()} className={`answerItself shadow border rounded py-2 my-3 ${answer.is_true == '1' ? 'rightAnswer' : null} }`}>
                                            
                                    
                                            <p type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <EditOutlined 
                                                style={{fontSize: '30px !important', margin: '0px 15px', color: 'balck !important'}}
                                                onClick={() => handleEditAnswer(answer)}
                                                className="fs-2 "
                                                />
                                            </p>

                                            <label dangerouslySetInnerHTML={{__html:answer.answear}}>{}</label>
                                            <input type='checkbox' className={`${answer.checked || answer.status ? 'hidecheckbox' : ''}`} checked={answer.is_true == '1'} value={answer.text} name={id} />
                                            <div className='statusImage'>
                                                {answer.status ? <img loading="lazy"  src={rightAnswer} alt="" /> : null}
                                                {!answer.status && answer.checked ? <img loading="lazy"  src={wrongAnswer} alt="" /> : null}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        
                        </li>
                    ))}


                    {/* {data.isError && <Error errorText={data.errorMessage} />} */}


                </ol>
            </div>



            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> {isEditing ? "جاري التعديل ..." :  ( whatEdit == 'question' ?  "تعديل نص السؤال" : "تعديل الجواب")}</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">
        { 
            !editQuestionNote && 
            (
            whatEdit == 'question' ? 
            <ReactQuill
              style={{height: '300px', marginBottom: '120px'}}
              theme={"snow"}
              onChange={(e) => questionRef.current = e}
              value={questionRef.current}
              modules={Editor.modules}
              formats={Editor.formats}
              bounds={".app"}
              placeholder={'أدخل تعديلاتك هنا ...'}
              /> 
            : 
            <ReactQuill
              style={{height: '300px', marginBottom: '120px'}}
              theme={"snow"}
              onChange={(e) => answerRef.current = e}
              value={answerRef.current}
              modules={Editor.modules}
              formats={Editor.formats}
              bounds={".app"}
              placeholder={'أدخل تعديلاتك هنا ...'}
              /> 
              )

        }

        {
            editQuestionNote ? 
            <ReactQuill
            style={{height: '300px', marginBottom: '120px'}}
            theme={"snow"}
            onChange={(e) => questionNoteRef.current = e}
            value={questionNoteRef.current}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={'أدخل تعديلاتك هنا ...'}
            /> 
            : 
            null
        }

        {/* <textarea 
        type="text" 
        className='form-control' 
        style={{minHeight: '120px'}} 
        value={question} onChange={(e) => handleQuestionChange(e)} 
        /> */}

      </div>
      <div class="modal-footer d-flex w-100" style={{justifyContent: 'space-between !important'}}>
        {
            isEditing ? 
            <div className='w-100 text-center py-3'>
                <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>: 
            <>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">إغلاق</button>
            {
                !editQuestionNote && 
                (
                    whatEdit == 'question' ? 
                    <button type="button" class="btn btn-success" onClick={() => handlePostEditsForQuestion()}>حفظ التعديلات</button>
                    :
                    <button type="button" class="btn btn-success" onClick={() => handlePostEditsForAnswer()}>حفظ التعديلات</button>
                )
            }

            {
                editQuestionNote ? 
                <button type="button" class="btn btn-success" onClick={() => handlePostEditsForQuestionNote()}>حفظ التعديلات</button>
                : 
                null
            }
            </>
        }
      </div>
    </div>
  </div>
            </div>

            
        </div>
        </Layout>
    )

}

Editor.modules = {
    /*toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],*/
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
  
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ header: [1, 2, 3, false] }],
  
      ["link", "image", "formula"],
  
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
  
      ["clean"] // remove formatting button
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };