import React, { useEffect, useState } from "react";
import katex from "katex";
import "katex/dist/katex.min.css";
import Layout from "../../components/Layout/Layout";
import useQuizBankLogic from "./useQuizBankLogic";
import QuestionRow from "./QuestionRow";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";

window.katex = katex;

export default function AddBulkQuizBankQuestion() {
  const [questions, setQuestions] = useState([]);
  const [errorMessage, setErroreMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { handleSubmit, isLoading, onSubmit } = useQuizBankLogic();

  // Initialize 100 questions with default values
  useEffect(() => {
    const defaultQuestions = Array.from({ length: 100 }, (_, index) => ({
      questionText: `السؤال ${index + 1}`,
      note: "",
      options: ["A", "B", "C", "D"],
      correctOption: 0,
    }));
    setQuestions(defaultQuestions);
  }, []);

  const updateQuestion = (index, updatedQuestion) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q, i) => (i === index ? updatedQuestion : q))
    );
  };

  const handleFormSubmit = () => {
    const hasErrors = questions.some((q) => {
      if (!q.questionText.trim()) return true;
      if (q.options.some((opt) => !opt.trim())) return true;
      return false;
    });

    if (hasErrors) {
      setErroreMessage("Please ensure all questions and options are filled in correctly.");
      return;
    }

    console.log("Submitted Questions:", questions);
    onSubmit(questions);
  };

  return (
    <Layout>
      <ToastSuccessError
        ErrorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErroreMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <div className="container all Add_traditional_quiz">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="row g-3 needs-validation"
          noValidate
        >
          {questions.map((question, index) => (
            <QuestionRow
              key={index}
              index={index}
              question={question}
              updateQuestion={updateQuestion}
            />
          ))}
          <div className="col-12 text-center">
            <button
              disabled={isLoading}
              className={`btn btn-success my-5 ${isLoading ? "opacity-50" : ""}`}
              style={{ minWidth: "120px" }}
              type="submit"
            >
              رفع الاختبار
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
