import React, {useState, useRef, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import SubjectsForStudentLogic from './SubjectsForStudentsLogic';
import TableStudenSubscribtion from '../ConfirmRequest/TableStudenSubscribtion';
import fetchStudentSubscribtion from "../../redux-toolkit/api/fetchStudentSubscribtion";


import {
    QueryClient,
} from 'react-query'
import axios from 'axios'
import TableSubjectsForStudent from '../ConfirmRequest/TableSubjectsForStudent';
import SubjectSelector from "./SubjectSelector";

const SubjectsForStudent = () => {


    const params = useParams()
    const id = params?.id;

    const {listRef, isLoading, isError, data, error, handlePageClick, page, setPage} = SubjectsForStudentLogic(id);
    console.log("sections Data", data);
    console.log("current page", page);
    // const dataSections = data?.data
    const [status, setStatus] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState()


    const [sections, setSections] = useState([]);
    const [subSections, setSubSetSections] = useState([]);
    const [subjects, setSubject] = useState([]);


    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedSubSection, setSelectedSubSection] = useState(null);
    const [selectedSubjects, setSelectedSubjects] = useState(null);


    // const handleSearchStudent = (e) => {


    //     // setValue('student_id',e.target.value);

    //     axios.get(`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
    //     {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    //     .then(response => {
    //         console.log('search response',response.data.data);
    //     })
    //     .catch(error => {
    //         console.log('error while fetching search students', error)
    //     });

    // }


    //---------------------------------get the subjects======================//
    useEffect(() => {
        const sectionsFromAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_all_sections?limit=19`,
            {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
            .then(data => {
                console.log('log', data.data.data)
                setSections(data.data.data);
            });

        // console.log('sections from api',sectionsFromAPi);
    }, [])

    useEffect(() => {
        // console.log('section changed')
        // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
        const subSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}?limit=19`,
            {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
            .then(data => {
                console.log('subsection ', data.data.data);
                setSubSetSections(data.data.data.data);
                if(data.data.data.data.length>0)
                setSelectedSubSection(data.data.data.data[0]?.sub_section_id);

            })
    }, [selectedSection])


    useEffect(() => {
        // console.log('section changed')
        // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
        const subSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${selectedSubSection}&limit=19&exclude_student_id=${id}`,
            {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
            .then(data => {
                console.log('subjects ', data.data.data);
                setSubject(data.data.data);
            })
    }, [selectedSubSection])


    const handleSectionChange = (e) => {
        // console.log(e.target.value);
        setSelectedSection(e.target.value);
        setSubSetSections([]);
    }

    const handleSubSectionChange = (e) => {
        // console.log(e.target.value);
        setSelectedSubSection(e.target.value);
        setSubject([]);
    }


    // -----------------------end get the subjects ------------ //


    const handleAddSubject = (e) => {
        setSubmitLoading(true)
        e.preventDefault()
        // let formData = new FormData();
        // setImage(data.image[0]);
        // formData.append("student_id",stuentId)
        // formData.append("subscription_id",id)
        //  formData.append("subscription_id" ,id);
        const Data = {
            "student_id": id,
            "subjects_ids": selectedSubjects
        }
        console.log("object send ", Data);
        const res = axios.post(`${process.env.REACT_APP_API_URL}/add_student_subject_by_subject_id_and_student_id`, Data, {
            headers: {
                'content-type': 'text/json',
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                'Content-Encoding': 'gzip , deflate, br',
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            if (res.status === 200) {
                const {data} = res;
                console.log('data from add student', data);
                //  alert('added subject is success')
                setSubmitLoading(false)

                window.location.reload();

                //  prefetchTodos()
                //    SetValid(true);
                return res;
            } else {
                console.log(res);
                throw new Error('there is an error')
            }
        }).catch(error => {
            console.log(error.response.data.message);
            setErrorMessage(error.response.data.message);
        });

    }


    return (
        <>
            <Layout>
                <div className='container all'>

                    {/* {ErrorMessage && <div  className='alert alert-danger' dangerouslySetInnerHTML={{__html:ErrorMessage}}  />
          } */}

                    <h1
                        className='pt-0 mb-4 pb-4 pt-3'
                        style={{
                            // borderBottom: '1px solid green'
                        }}
                    >المواد
                    </h1>
                    <div className='d-flex justify-content-around mb-5'>
                    </div>

                    {
                        <TableSubjectsForStudent data={data?.data} isLoading={isLoading} page={page} setPage={setPage}
                                                 setStatus={setStatus}/>
                    }


                    {/* {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }   */}
                </div>


                <form>
                    <div className='selects__container'>
                        <div className='d-flex flex-column align-items-center justify-centent-center'>
                            <p>اختر القسم</p>
                            <select onChange={(e) => handleSectionChange(e)} className="form-select customSelect"
                                    aria-label="Default select example">
                                <option selected>اختر القسم</option>
                                {
                                    sections && sections?.map((section, index) => <option key={index}
                                                                                          value={section.section_id}>{section.section_name}</option>)
                                }

                            </select>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-centent-center'>
                            <p>اختر الدورة</p>
                            <select onChange={(e) => handleSubSectionChange(e)} className="form-select  customSelect"
                                    aria-label="Default select example">
                                {
                                    subSections && subSections?.map((subsection, index) => <option key={index}
                                                                                                   value={subsection.sub_section_id}>{subsection.sub_section_name}</option>)
                                }
                            </select>
                        </div>

                        <SubjectSelector
                            subjects={subjects}
                            setSelectedSubjectsIds={setSelectedSubjects}/>
                    </div>

                    <button
                        type='submit'
                        className='submit-btn'
                        onClick={handleAddSubject}
                        disabled={submitLoading}
                    >
                        {submitLoading ? 'جاري الإضافة .. ' : 'إضافة'}
                    </button>
                </form>


            </Layout>

        </>
    )
}

export default SubjectsForStudent;


