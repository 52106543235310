import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './WelcomAdmin.css';
import logo from '../../assets/Home/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const WelcomAdmin = (props) => {

    const userInfos = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const [Isloading,setIsLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      console.log('user',userInfos);


      setTimeout(() => {
        const sidebar = document.getElementById('sidebar');
        sidebar.style.transition = 'all 800ms linear';
            console.log(sidebar);
        sidebar.classList.remove('aa');
        sidebar.style.transition = 'all 500ms ease-in';
      }, 3000);
    },[])


    function backendLoggingOut() {
      axios.post(`${process.env.REACT_APP_API_URL}/logout`)
      .then()
      .catch()
      .finally();
    }


    
  const handleLogout = (e)=>{
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('role_id');
    alert('تم تسجيل الخروج بنجاح')
    navigate('/loginasadmin')
    // window.location.reload();
  }

  // check if the token is valid or not

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/get_admin_by_token`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    .then((response) => {

      console.log('response get admin by token',response);
    })
    .then(data => {
      console.log('data ', data)
    })
    .catch(error => {
      // handleLogout();
      if(error.response.data.code == 410){
        localStorage.removeItem('token');
        localStorage.removeItem('role_id');
        backendLoggingOut();
        // alert('تم تسجيل الخروج بنجاح')
        navigate('/loginasadmin')
      }
      console.log('error get admin by token ', error.response.data.code);
    })
    .finally(() => {
      console.log('finally i exit this');
      setIsLoading(false);
    })
  },[])



    return <Layout>

      {
        
      <div className='container welcomAdmin__container'>
           <div className='top__content'>
            <h2>أهلا بك في لوحة تحكم طريقي</h2>
           <img src={logo} alt="" />
           </div>

           <div className='bottom__content'>
                <div className='content'>
                <p>عزيزي الأدمن , لقد تم منحك مجموعة صلاحيات للتحكم في المنصة وتتضمن هذه الصلاحيات:  </p>
                <ul>
                    <li>صلاحية 1</li>
                    <li>صلاحية 2</li>
                    <li>صلاحية 3</li>
                </ul>
                </div>
           </div>
        </div>

      }

        
    </Layout>
}

export default WelcomAdmin;