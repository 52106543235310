import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";

const SendMessageForm = ({
  isMsgForAll,
  selectedStudents,
  provinceId,
  genderFilter,
  sectionoFilter,
  subSectionoFilter,
  year
}) => {
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const {
    register: registerSendMessage,
    handleSubmit: handleSubmitSendMessage,
    reset: resetSendMessage,
    formState: { errors: errorsSendMessage },
  } = useForm({
    defaultValues: {
      message: "",
      notification_type: "NOTIFICATION",
      is_for_guardian: 0,
      is_for_student: 0,
      students_ids: [],
      all: isMsgForAll ? 1 : 0,
    },
  });

  const addMessage = (data) => {
    setIsLoading(true);

    console.log("message data data ", data);
    const formCallsRecordData = new FormData();

    if (provinceId != "") {
      formCallsRecordData.append("province_id", provinceId);
    }
    if (year != "") {
      formCallsRecordData.append("year", year);
    }

    if (genderFilter != "") {
      formCallsRecordData.append("gender", genderFilter);
    }
    if (sectionoFilter != "") {
      formCallsRecordData.append("section_id", sectionoFilter);
    }
    if (subSectionoFilter != "") {
      formCallsRecordData.append("sub_section_id", subSectionoFilter);
    }

    formCallsRecordData.append("message", data.message);
    formCallsRecordData.append("notification_type", data.notification_type);
    formCallsRecordData.append("is_for_guardian", data.is_for_guardian ? 1 : 0);

    formCallsRecordData.append("is_for_student", data.is_for_student ? 1 : 0);

    if (isMsgForAll) {
      formCallsRecordData.append("all", 1);
    } else {
      // get the student ids array from the original students array

      const student_ids_array = selectedStudents?.map(
        (student) => student.student_id
      );
      formCallsRecordData.append(
        "students_ids",
        JSON.stringify(student_ids_array)
      );
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/push_notifications`,
        formCallsRecordData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        setSuccessMessage("تمت إرسال الرسالة بنجاح");
        // refetch();
        // setRefetchMessage(prev => !prev);
        //   resetSendMessage();
      })
      .catch((error) => {
        setErrorMessage("حدث خطأ ما يرجى المحاولة مرة أخرى");
      })
      .finally(() => {
        setIsLoading(false);

        //   setShowSendMessage(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmitSendMessage(addMessage)}
      className="sendMessageForm border shadow rounded mx-auto"
    >
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="my-2">
        <h5>الرسالة</h5>
        <textarea
          className="border rounded w-100 "
          placeholder="أدخل نص الرسالة المراد إرسالها هنا ..."
          {...registerSendMessage("message", { required: true })}
        />
      </div>

      <div className="my-2">
        <label>إرسال للطالب</label>
        <input
          className="form-check-input mx-2"
          type="checkbox"
          id="noReal"
          {...registerSendMessage("is_for_student")}
        />
      </div>

      <div className="my-2">
        <label>إرسال للوالد</label>
        <input
          className="form-check-input mx-2"
          type="checkbox"
          id="noReal"
          {...registerSendMessage("is_for_guardian")}
        />
      </div>

      <select
        {...registerSendMessage("notification_type")}
        className="shadow p-2 border-0 my-2"
      >
        <option>اختر نوع الرسالة</option>
        <option value="NOTIFICATION">إشعار داخلي</option>
        <option value="SMS">رسالة نصية SMS </option>
        <option value="BOTH">رسالة نصية SMS + إشعار داخلي </option>
      </select>

      {Isloading ? (
        <div className=" w-100 text-center w-100">
          <div class="spinner-border " role="status">
            <div class="visually-hidden">Loading...</div>
          </div>
        </div>
      ) : (
        <button className="btn btn-success d-block mx-auto" type="submit">
          إرسال الرسالة
        </button>
      )}
    </form>
  );
};

export default SendMessageForm;
