import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Create_unit_automated_quiz_Logic from './CreateQuizBankLogic';
import katex from "katex";
import { Editor } from "@tinymce/tinymce-react";
import "katex/dist/katex.min.css";
import ReactQuill from 'react-quill';
import Layout from '../../components/Layout/Layout';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
window.katex = katex;

export default function CreateQuizBank({ isFinal, isAboveLevel }) {

  const [errorMessage, setErroreMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    create_unit_automated_quiz_form_error,
    create_unit_automated_quiz_form_progress,
    create_unit_automated_quiz_form_success,
    create_unit_automated_quiz_form_Loading,
    QuizId,
    questionText,
    onSubmit } = Create_unit_automated_quiz_Logic({ isFinal, isAboveLevel });

  useEffect(() => {
    if (create_unit_automated_quiz_form_success) {
      setSuccessMessage('تمت إضافة الاختبار لبنك الاختبارات بنجاح')
    }
  }, [create_unit_automated_quiz_form_success])

  return (
    <Layout>
      <ToastSuccessError
        ErrorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErroreMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <h1
        className='pt-0 mb-4 pb-4 pt-3'
        style={{
          // borderBottom: '1px solid green'
        }}
      >  إضافة اختبار لبنك الاختبارات
      </h1>
      <div className='container all'>
        <form onSubmit={handleSubmit(onSubmit)} className="row g-3 needs-validation" noValidate>
          <div className="col-md-4">
            <label htmlFor="َQuizeName" className="form-label">اسم الكويز</label>
            <input type="text" className="form-control" id="َQuizeName" {...register("QuizeName", { required: true })} />
            {errors.QuizeName && <p className='text-danger'>Invalid input</p>}
          </div>
          <div className="col-md-4">
            <label htmlFor="َQuizeName" className="form-label text-end">نوع الاختبار </label>
            <select type="text" className="form-control" id="َQuizeName" {...register("quizType", { required: true })}   >
              <option value={'above_level'}>فوق المستوى</option>
              <option value={'final'}>نهائي</option>
              <option></option>
            </select>
            {errors.quizType && <p className='text-danger'>الرجاء اختبار نوع الاختبار</p>}
          </div>

          <label htmlFor="َQuizeName" className="form-label text-end">النص العام للاختبار المؤتمت</label>
          <ReactQuill
            style={{ height: '300px', marginBottom: '120px' }}
            theme={"snow"}
            onChange={(e) => questionText.current = e}
            value={questionText.current}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={'أدخل النص ...'}
          />

          <div className="col-md-4" style={{ display: 'none' }}>
            <label htmlFor="points" className="form-label text-end">النقاط</label>
            <input type="number"  {...register("points", { required: false })} className="form-control degreeinput" id="degree" />
            {errors.degree && <p className='text-danger'>Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="descriptionQuize" className="form-label text-end">الوصف</label>
            <input type="text" {...register("descriptionQuize", { required: true })} className="form-control" id="descriptionQuize" />
            {errors.descriptionQuize && <p className='text-danger'>Invalid input</p>}
          </div>

          <div className="col-md-4">
            <label htmlFor="duration" className="form-label text-end" >المدة</label>
            <input type="number"  {...register("duration", { required: true })} className="form-control" id="duration" />
            {errors.duration && <p className='text-danger'>Invalid input</p>}
          </div>
          <div className="col-md-4">
            <label htmlFor="file" className="form-label text-end">رفع ملف PDF</label>
            <input
              type="file"
              accept="application/pdf"
              className="form-control"
              id="file"
              {...register("file", { required: true })}
            />
            {errors.file && <p className="text-danger">الرجاء رفع ملف بصيغة PDF</p>}
          </div>
          


          {create_unit_automated_quiz_form_progress ? <div class="progress">
            <div class="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${create_unit_automated_quiz_form_progress}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            <label className='text-center'>{create_unit_automated_quiz_form_progress}% </label>
          </div> : <></>
          }

          <div className="col-12">
            {!create_unit_automated_quiz_form_Loading && <button disabled={!isDirty || !isValid} className="btn btn-success" type="submit">رفع الاختبار</button>}

            {create_unit_automated_quiz_form_error && <label className='text-center w-100 text-danger'>{create_unit_automated_quiz_form_error?.response?.data ? create_unit_automated_quiz_form_error.response.data.message : create_unit_automated_quiz_form_error.message}</label>}
          </div>
          {/* {create_unit_automated_quiz_form_success&&<div class="alert alert-success text-center" role="alert">
    تمت عملية إضافة  الاختبار لبنك الاختبارات بنجاح
    </div>}  */}
        </form>
      </div>
    </Layout>
  )
}

Editor.modules = {
  /*toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ],*/
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};