import axios from "axios";

export default async function fetchStudents({queryKey}) {
    // check if the type of the tables is selected then search
    if (queryKey[3] == 1) {
        // console.log("process",process.env.REACT_APP_API_URL)
        // console.log('queryKey[2]' , queryKey[2]);
        const {data} = await axios.get(
            `${process.env.REACT_APP_API_URL}/get_all_students?page=${
                Number(queryKey[1]) + 1
            }&q=${queryKey[2]}&province_id=${queryKey[4]}&gender=${
                queryKey[5]
            }&section_id=${queryKey[6]}&sub_section_id=${queryKey[7]}${
                queryKey[8] ? `&sort_by=achievement` : ""
            }&year=${queryKey[9]??''}`,
            {
                params: {limit: 30},
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            }
        );

        return data;
    }
}
