import React, {useEffect, useRef, useState} from "react";
// import Button from '../../components/button';
// import chalks from '../../assets/icons/chalks.png';
import "./StudentMessages.css";
import Layout from "../../components/Layout/Layout";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {debounce} from "lodash";
import {useQuery} from "react-query";
import fetchStudents from "../../redux-toolkit/api/fetchStudents";
import StudentMessageTable from "../../components/ConfirmRequest/StudentsMessagesTable";
import Pagination from "../../components/Pagination/Pagination";

import CloseIcon from "../../assets/students/close.svg";
import SendMessageForm from "./SendMessageForm";
import fetchProvinces from "../../redux-toolkit/api/fetchProvinces";
import fetchSubSectionsApi from "../../redux-toolkit/api/fetchSubSections";
import fetchSectionsApi from "../../redux-toolkit/api/fetchSections";

const StudentMessages = () => {
    const [query, setQuery] = useState("");
    const unOrderStudentsList = useRef();
    const inputSearchRef = useRef();
    const [searchResult, setSearchResult] = useState("");
    const [page, setPage] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isMsgForAll, setIsMsgForAll] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showSendMessageForm, setShowSendMessageForm] = useState(false);

    const [provinceId, setProvinceId] = useState(
        localStorage.getItem("provindeIdFilterMessages")
            ? localStorage.getItem("provindeIdFilterMessages")
            : ""
    );
    const [genderFilter, setGenderFilter] = useState(
        localStorage.getItem("genderFilerMessages")
            ? localStorage.getItem("genderFilerMessages")
            : ""
    );
    const [sectionoFilter, setSectionFilter] = useState(
        localStorage.getItem("sectionFilterMessages")
            ? localStorage.getItem("sectionFilterMessages")
            : ""
    );
    const [subSectionoFilter, setSubSectionFilter] = useState(
        localStorage.getItem("subSectionFilterMessages")
            ? localStorage.getItem("subSectionFilterMessages")
            : ""
    );

    let allStudentMsg = "إرسال رسالة لجميع الطلاب";
    let someStudentsMsg = "إرسال رسالة مخصصة للطلاب ... ";

    let shouldSetQuery = true;

    function resetSubsectionFilter() {
        setSubSectionFilter("");
        localStorage.setItem("subSectionFilterMessages", "");
    }

    const [yearFilter, setYearFilter] = useState("");

    const {isLoading, isError, data, error} = useQuery(
        [
            "students",
            page,
            query,
            1,
            provinceId,
            genderFilter,
            sectionoFilter,
            subSectionoFilter,
            0,
            yearFilter
        ],
        fetchStudents
    );

    const {
        data: provinces,
        isLoading: isLoadingProvinces,
        isErrorProvinces,
    } = useQuery(["provinces"], fetchProvinces);

    const {
        isLoading: isLoadingSections,
        isError: isErrorSections,
        data: dataSections,
        error: errorSections,
    } = useQuery(["sections", 1], fetchSectionsApi);

    const {
        isLoading: isLoadingSubSections,
        isError: isErrorSubSections,
        data: dataSubSections,
        error: errorSubSections,
    } = useQuery(["subSections", 1, sectionoFilter], fetchSubSectionsApi);

    function handlePageClick(data) {
        localStorage.setItem("studentMessages", data.selected);
        setPage(data.selected);
    }

    function handleOnFucusInput() {
        // if(typeOfTable == 1) {
        //   localStorage.setItem('allstudents_pag',0);
        //   setPage(0);
        // }
        // if(typeOfTable == 2) {
        //   localStorage.setItem('unactivestudents_pag',0);
        //   setPageUnActive(0);
        // }
        // if(typeOfTable == 3){
        //   localStorage.setItem('newstudents_pag',0);
        //   setpageNew(0);
        // }
        // if(typeOfTable == 4){
        //   localStorage.setItem('activestudents_pag',0);
        //   setPageActive(0);
        // }
    }

    const setQueryOnTablesType = (value) => {
        setQuery(value);
        console.log("query is => ", value);
    };

    const setQueryNotOnEveryChnage = (value) => {
        console.log("value is ", value);

        setQueryOnTablesType(value);

        setTimeout(() => {
            shouldSetQuery = true;
        }, 1000);
    };

    const handleSearchStudent = (e) => {
        const value = e.target.value;
        unOrderStudentsList.current.style.height = "auto";
        // setQuery(value);
        // console.log('vvvv',value);
        setQueryNotOnEveryChnage(value);
        handleOnFucusInput();
        handleUnorderStudentsList(e.target.value);

        // setValue('student_id',e.target.value);
    };

    const devouncedFn = debounce(handleSearchStudent, 500);

    const handleUnorderStudentsList = (searchQuery) => {
        if (searchQuery == "") {
            unOrderStudentsList.current.style.height = "0px";
        } else {
            unOrderStudentsList.current.style.height = "auto";
        }
    };

    const deleteStudentFromArray = (currentStudent) => {
        const newCurrentStudent = selectedStudents.filter(
            (student) => student.student_id != currentStudent.student_id
        );
        setSelectedStudents([...newCurrentStudent]);
    };

    const handleChangeStatus = (e) => {
        if (e.target.value == "all") {
            setIsMsgForAll(true);
        } else {
            setIsMsgForAll(false);
        }
    };

    useEffect(() => {
        if (!isMsgForAll) {
            setShowSendMessageForm(false);
        }
    }, [isMsgForAll]);

    const logValue = debounce((value) => {
        setQuery(value);
    }, 500);

    const onChange = (e) => {
        logValue(e.target.value);
    };

    const [years, setYears] = useState([]);

    useEffect(() => {
        const newYears = [];

        for (let i = 2022; i <= 2100; i++) {
            newYears.push(i);
        }

        setYears([...newYears]);
    }, []);

    return (
        <Layout>
            <div className="profileScreen container">
                <div className="profileScreen__content">
                    <input
                        type="text"
                        // onFocus={() => handleOnFucusInput()}
                        ref={inputSearchRef}
                        placeholder="اكتب هنا للبحث عن طالب"
                        onChange={(e) => onChange(e)}
                        className="shadow w-100 main-input border-0"
                    />

                    <div className="d-flex flex-wrap  p-5 my-3 border shadow-sm rounded-2 p-5">
                        <div className="col-sm-12 col-md-3 form-outline mb-2">
                            <label className="d-flex align-items-center">
                                {/* <input
  type='checkbox'
  className='cursor-pointer mx-2' 
  checked={activeProvincesFilter} 
  onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                                المحافظات
                            </label>

                            <select
                                // disabled={!activeProvincesFilter}
                                id="form3Example4"
                                onChange={(e) => {
                                    setProvinceId(e.target.value);
                                    localStorage.setItem(
                                        "provindeIdFilterMessages",
                                        e.target.value
                                    );
                                }}
                                className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                            >
                                <option selected={provinceId == ""} value={""}>
                                    جميع المحافظات
                                </option>
                                {provinces &&
                                    provinces?.map((province, idx) => {
                                        return (
                                            <option
                                                selected={
                                                    province.id ==
                                                    localStorage.getItem("provindeIdFilterMessages")
                                                }
                                                value={province.id}
                                            >
                                                {province.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>

                        <div className="col-sm-12 col-md-3 form-outline mb-2">
                            <label>الجنس</label>
                            <select
                                id="form3Example4"
                                onChange={(e) => {
                                    setGenderFilter(e.target.value);
                                    localStorage.setItem("genderFilerMessages", e.target.value);
                                }}
                                className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                            >
                                <option
                                    value={""}
                                    selected={localStorage.getItem("genderFilerMessages") == ""}
                                >
                                    الجميع
                                </option>
                                <option
                                    value={"MALE"}
                                    selected={
                                        localStorage.getItem("genderFilerMessages") == "MALE"
                                    }
                                >
                                    ذكور
                                </option>
                                <option
                                    value={"FEMALE"}
                                    selected={
                                        localStorage.getItem("genderFilerMessages") == "FEMALE"
                                    }
                                >
                                    إناث
                                </option>
                            </select>
                        </div>

                        <div className="col-sm-12 col-md-3 form-outline mb-2">
                            <label className="d-flex align-items-center">
                                {/* <input
 type='checkbox'
 className='cursor-pointer mx-2' 
 checked={activeProvincesFilter} 
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                                الأقسام
                            </label>

                            <select
                                // disabled={!activeProvincesFilter}
                                id="form3Example4"
                                onChange={(e) => {
                                    setSectionFilter(e.target.value);
                                    resetSubsectionFilter();
                                    localStorage.setItem("sectionFilterMessages", e.target.value);
                                }}
                                className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                            >
                                <option selected={sectionoFilter == ""} value={""}>
                                    جميع الأقسام
                                </option>
                                {dataSections &&
                                    dataSections?.data?.map((section, idx) => {
                                        return (
                                            <option
                                                selected={
                                                    section.section_id ==
                                                    localStorage.getItem("sectionFilterMessages")
                                                }
                                                value={section.section_id}
                                            >
                                                {section.section_name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>

                        {
                            <div className="col-sm-12 col-md-3 form-outline mb-2">
                                <label className="d-flex align-items-center">
                                    {/* <input
 type='checkbox'
 className='cursor-pointer mx-2' 
 checked={activeProvincesFilter} 
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                                    الدورات
                                </label>

                                <select
                                    // disabled={!activeProvincesFilter}
                                    id="form3Example4"
                                    disabled={sectionoFilter == ""}
                                    onChange={(e) => {
                                        setSubSectionFilter(e.target.value);
                                        localStorage.setItem(
                                            "subSectionFilterMessages",
                                            e.target.value
                                        );
                                    }}
                                    className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                                >
                                    <option selected={subSectionoFilter == ""} value={""}>
                                        جميع الدورات
                                    </option>
                                    {dataSubSections &&
                                        dataSubSections?.data?.data?.map((subSection, idx) => {
                                            return (
                                                <option
                                                    selected={
                                                        subSection.sub_section_id ==
                                                        localStorage.getItem("subSectionFilterMessages")
                                                    }
                                                    value={subSection.sub_section_id}
                                                >
                                                    {subSection.sub_section_name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        }
                        <div className="col-sm-12 col-md-3 form-outline mb-2">
                            <label className="d-flex align-items-center">
                                السنة الدراسية
                            </label>
                            <select
                                // disabled={!activeProvincesFilter}
                                id="form3Example4"
                                onChange={(e) => {
                                    setYearFilter(e.target.value);
                                }}
                                className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                            >
                                <option selected={yearFilter == ""} value={""}>
                                    جميع السنوات
                                </option>
                                {
                                    years.map((year, idx) => {
                                        return (
                                            <option value={year} key={year}>
                                                {year}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>


                    <div className="d-flex flex-column gap-2 mt-4">
                        <label className="mb-0 mx-3 d-flex align-items-center gap-2">
                            <input
                                type={"checkbox"}
                                checked={isMsgForAll}
                                value={"all"}
                                onChange={handleChangeStatus}
                            />
                            الجميع
                        </label>

                        <label className="mb-0 mx-3 d-flex align-items-center gap-2">
                            <input
                                type={"checkbox"}
                                checked={!isMsgForAll}
                                value={"some"}
                                onChange={handleChangeStatus}
                            />
                            مخصص
                        </label>
                    </div>

                    {!isMsgForAll && (
                        <div className="mt-3 mb-2 d-flex flex-wrap gap-3">
                            {!isMsgForAll && selectedStudents.length > 0 && (
                                <button
                                    className="btn btn-success"
                                    onClick={() => setShowSendMessageForm(true)}
                                >
                                    {someStudentsMsg}
                                </button>
                            )}

                            {selectedStudents.map((student, idx) => {
                                return (
                                    <div
                                        className="selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2">
                                        <img
                                            src={CloseIcon}
                                            width={20}
                                            height={20}
                                            className="closeIcon"
                                            onClick={() => deleteStudentFromArray(student)}
                                        />
                                        <h6 className="mb-0">{student.name}</h6>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {isMsgForAll && (
                        <button
                            className="btn btn-success my-3"
                            onClick={() => setShowSendMessageForm(true)}
                        >
                            {allStudentMsg}
                        </button>
                    )}

                    {showSendMessageForm && (
                        <SendMessageForm
                            isMsgForAll={isMsgForAll}
                            selectedStudents={selectedStudents}
                            provinceId={provinceId}
                            genderFilter={genderFilter}
                            sectionoFilter={sectionoFilter}
                            subSectionoFilter={subSectionoFilter}
                            year={yearFilter}
                        />
                    )}

                    {showForm && <button onClick={() => setShowForm(true)}></button>}

                    <StudentMessageTable
                        data={data?.data}
                        isLoading={isLoading}
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                    />

                    <div className="my-4">
                        <Pagination
                            data={data?.data}
                            handlePageClick={handlePageClick}
                            page={page}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default StudentMessages;
