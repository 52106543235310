import React, { useRef, useState,useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import record from'../../assets/Home/record.png'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import './AddGuardian.css';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import { useNavigate } from 'react-router-dom';


export default function AddGuardian() {

       const [progress,setProgress] = useState(0);
       const [IsLoading,setIsLoading] = useState(false);
       const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
       const [searchResult,setSearchResult] = useState('');
       const [selectedStudent,setSelectedStudent] = useState('');
       const inputSearchRef = useRef(); 
       const [successMessage , setSuccessMessage] = useState('');
       const [ErrorMessage , setErrorMessage] = useState('');
        const navigate = useNavigate();

    const unOrderStudentsList = useRef();

    const { reset,setValue,getValues,watch ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        username:  '',
        email: '',
        name: '' ,
        password: '',
        gender: 'MALE',
        phone_number: '',
        image: '',
        student_id: ''
    }});

    const handleSelectStudentFromDrobDownList = (student) => {
        setValue('student_id',student.student_id);
        console.log('student which was selected by user => ' , student)
        unOrderStudentsList.current.style.height = '0';
        inputSearchRef.current.value = student.name;
    }

    const handleSearchStudent = (e) => {
        
        unOrderStudentsList.current.style.height = 'auto';

        handleUnorderStudentsList(e.target.value);
        setValue('student_id',e.target.value);
        
        axios.get(`${process.env.REACT_APP_API_URL}/get_all_students?page=1&q=${e.target.value}&limit=30`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(response => {
            console.log('search response',response.data.data.data);
            setSearchResult(response.data.data.data)
        })
        .catch(error => {
            console.log('error while fetching search students', error)
        });

    }

    useEffect(() => {
        console.log(searchResult)
    },[searchResult])

    const handleUnorderStudentsList = (searchQuery) => {
        if(searchQuery == '') {
            unOrderStudentsList.current.style.height = '0px';
        }else {
            unOrderStudentsList.current.style.height = 'auto';
        }
    }

    const handleOnSubmit = (data) => {
        // e.preventDefault();
        console.log('data',data)
        console.log("submitting data to server");

        setIsLoading(true);

        const ourFormData = new FormData();

        ourFormData.append('username',data.username);
        ourFormData.append('email',data.email);
        ourFormData.append('password',data.password);
        ourFormData.append('phone_number',data.phone_number);
        ourFormData.append('name',data.name);
        ourFormData.append('image',data.image[0]);
        ourFormData.append('gender',data.gender);
        ourFormData.append('student_id',data.student_id);


        for(var pair of ourFormData.entries()) {
            console.log(pair[0]+ ', '+  pair[1]); 
            // console.log('pair',pair);
       }

        axios.post(`${process.env.REACT_APP_API_URL}/add_guardian`, ourFormData, {

        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          'Content-Encoding': 'gzip , deflate, br',
          'content-type': 'text/json',
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
    
  
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(progress);
          setProgress(progress);
          setIsLoading(true);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 100;
          console.log(progress);
          setProgress(progress);
        },
      }).then(res=>{
        if(res.status === 200)
        {
          const {data} = res;
          console.log('data from post api' , data);
        setIsLoading(false)
        setIsUploadSuccessfuly(true);
        
        setSuccessMessage("تمت  إضافة ولي الامر بنجاح");

        setTimeout(() => {
            navigate(-1);
        }, 1200);
        
          return res;
        }
        else
        {
            setIsLoading(false);
          console.log(res);
          throw new Error('there is an error')
        }
      }).catch(error=>{
         setIsLoading(false);
        console.log(error.response)

        setErrorMessage(error.response.data.message);
        //  setIsError(error.response.data)
      }
        );
      }

      useEffect(() => {
        if(progress == 100) {
            setIsLoading(false);
        }
      },[progress]);

      
      const changeColor = (progress) => {
        if(progress <= 25){
            return '#ff0707';
        }else if(progress <= 50){
            return '#ff6907';
        }else if(progress <= 75){
            return '#ffdb07';
        }else {
            return '#77ff07';
        }
      }
  
  return (
   
        <>
            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

        <Layout>
            <div className='container all'>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
        
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم المستخدم</label>
            </div>
           <input type='text' {...register("username", { required: true })} className='shadow w-100 main-input border-0'/>
           {errors.the_vision && <p className='text-danger text-right'>*required</p>}
        </div>

                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم ولي الأمر</label>
            </div>
           <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/>
           {errors.the_vision && <p className='text-danger text-right'>*required</p>}
        </div>


                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> الايميل</label>
            </div>
           <input type='text' {...register("email", { required: true })} className='shadow w-100 main-input border-0'/>
           {errors.the_vision && <p className='text-danger text-right'>*required</p>}
        </div>

                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> كلمة السر</label>
            </div>
            
           <input 
           type='password' 
           {...register("password", { 
            required: true , 
            minLength: 8,
            pattern: {
              value: new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})")
            }
            })} 
           className='shadow w-100 main-input border-0'
           />

          {errors.password && <p className="text-danger"> كلمة السر ضعيفة ... يجب أن تحتوي أرقام و رموز </p>}
        </div>

                
        <div className='m-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> الجنس</label>
            </div>
           <select type='text' {...register("gender", { required: true })} className='shadow w-100 border-0'>
            <option value="MALE">ذكر</option>
            <option value="FEMALE">أنثى</option>
           </select>
           {errors.the_vision && <p className='text-danger text-right'>*required</p>}
        </div>

                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> صورة الاستاذ</label>
            </div>
            <RegisterImage  register={register} isUploadSuccessfuly={isUploadSuccessfuly}  registerWhat={"image"}/>

           {/* <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/> */}
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>
        

                <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'>رقم الهاتف</label>
            </div>
           <input type='text' {...register("phone_number", { required: true })} className='shadow w-100 main-input border-0'/>
           {errors.the_vision && <p className='text-danger mt-5  text-right'>*required</p>}
        </div>

        <div className='mb-3'>
                  
          
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'>الطالب</label>
            </div>

            <input type='text' ref={inputSearchRef} placeholder='اكتب هنا للبحث عن ابنك' onChange={(e) => handleSearchStudent(e)} className='shadow w-100 main-input border-0'/>
           {/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}
     
           {/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}
            <div className='students_select'>   
                <ul className='unorder_students_list' ref={unOrderStudentsList} >
                        {
                            searchResult && searchResult?.map((student,index) => {
                                return   <li 
                                        onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                        value={student.student_id} 
                                        key={index}>
                                            {student.name}
                                        </li>
                            })
                        }
                    </ul>
            </div>


          
        </div>

            <div className='d-flex justify-content-center mt-5'>
                    


            {
            IsLoading ? <div className='mb-3 d-flex justify-content-center' >
          
                    <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  </div>

                :
            <button className=' btn infos px-3 py-2 mt-5 mb-5 border-0 btn-primary' type='submit'><h6>إنشاء حساب ولي أمر </h6> </button>
        }

            </div>
        </form>
        </div>
        </Layout>
        </>
    
  )
}
