import React, { useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";

import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
`;

const ShimmerDiv = styled.div`
  height: 75px;
  width: 75px;
  border-radius: 100px;
  background: #25918f70;
  background: linear-gradient(
    to right,
    #25918f70 8%,
    #f0f0f0 18%,
    #238a8780 33%
  );
  background-size: 800px 104px;
  animation: ${shimmer} 2s infinite linear forwards;
`;
const ImageWithLoader = ({
  src,
  style,
  onClick,
  size,
  isCircular = false,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading &&
        (isCircular ? (
          <ShimmerDiv />
        ) : (
          <ShimmerDiv />
        ))}

      <img
        className={className}
        src={src}
        onClick={onClick}
        alt=""
        onLoad={handleImageLoad}
        style={{
          ...style,
          display: isLoading ? "none" : "block",
        }}
      />
    </>
  );
};

export default ImageWithLoader;
