

import axios from "axios";
export default async function  fetchAllSmsMessages ({queryKey})
{
   
          
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_sms_messages?page=${queryKey[1]}` ,
          {params :{limit:19}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


