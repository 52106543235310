import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
           <div className='d-flex justify-content-center'>
  {/* <footer className="main-footer" style={{borderTop:'0'}}>
    <strong className='text-center'>Copyright © 2024 <a href="">ICR</a>.</strong>
    All rights reserved.
   
  </footer> */}
</div>

        )
    }
}
