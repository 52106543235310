import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";

export default function ExamQuestions() {
//   const { data, dispatch, QuizId } =
//     Show_Traditionalquiz_Questions_Files_Logic();

    const {id} = useParams();
    const [isLoading,setIsLoading] = useState(true);
    const [examDetails,setExamDetails] = useState();
    const [isUploading,setIsUploading] = useState(false);

    const modalRef = useRef(null);
    const closeModalBtn = useRef(null);
    const [selectedDeleteId,setSelectedDeleteId] = useState();
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const [Quistions,setQuestions] = useState([]);
    let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/bmp'];


    const handleQuistions= (e)=>{
        let newQuistions = []
      for (var i = 0; i <= e.target.files.length - 1; i++) {
       
      if( e.target.files.item(i).size/(1024*1024)<4 && ((Quistions.length+newQuistions.length) < 8 ) && allowedExtension.indexOf(e.target.files.item(i).type)>-1)
      newQuistions.push(e.target.files.item(i));
      }    
      setQuestions(prev=>[...prev,...newQuistions]);
      }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_traditional_exam_by_id/${id}`, {
            headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
        })
        .then(data => {
            console.log('quiz exam dddd ', data.data.data);
            setExamDetails(data.data.data)
            setIsLoading(false);
        });
    },[]);


    const handleDeleteExam = (e,id) => {
        console.log('id id id id id ' , id , e)
        e.stopPropagation();
        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_traditional_exam_question_file_by_id/${selectedDeleteId}`, 
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(res => {
            if(res.status === 200)
            {
            // console.log(data);
            setIsLoading(false);
            
          setSuccessMessage("تمت  عملية الحذف بنجاح")
          setTimeout(() => {
            window.location.reload();
          }, 400);
            // hide the confirm deletion modal 
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();

            // const newCurrentAdmins = currentAdimis.filter(currentAdmin => currentAdmin.id != id);
            console.log('admin deleted successfully ')
            // setCurrentAdmins([...newCurrentAdmins]);
            }else {

            }
        })
        .catch(error => {
            // console.log(error);
            setIsLoading(false);
            setErrorMessage("حدث خطأ ما");
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }

    const handleUploadQuestions = () => {
        const filesFormData = new FormData();

    filesFormData.append('traditional_exam_id', id);

        Quistions.forEach((element,i) => {
            filesFormData.append(`questions_files[${i}]`,element);
          });

          setIsUploading(true);

        axios.post(`${process.env.REACT_APP_API_URL}/add_traditional_exam_questions_files`, filesFormData ,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            window.location.reload();
            setQuestions([]);
        })
        .catch(error => {

        })
        .finally(() => {
            setIsUploading(false);
        })
    }

  return (
    <Layout>

<ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

    <div className="container all">


    <div className='mb-5'>
					<i
						className='fa fa-book'
						style={{ marginLeft: '1rem', fontSize: '1.5rem' }}
					/>
					<label className='fw-bold'> قم بإضافة صور الأسئلة</label>
				</div>
				<div className='bg-white shadow fine mb-3 d-flex flex-wrap  justify-content-center row'>
					{Quistions?.map((certificate) => (
						<div className='col-3' key={certificate.name}>
							{' '}
							<img
                            width="200"
                            height="200"
								alt='certafecates'
								src={URL.createObjectURL(certificate)}
								className='imagesquistions'
							/>
						</div>
					))}
					{Quistions?.length >= 8 ? (
						<> </>
					) : (
						<label className='col-12 text-center' htmlFor='certificate'>
							<i className='fa fa-plus'></i>
							<div>
								<input
									multiple
									accept='image/png, image/jpeg'
									id='certificate'
									type='file'
									onChange={(e) => {
										handleQuistions(e);
									}}
									style={{ display: 'none' }}
									className='hideinput'
								/>
							</div>
						</label>
					)}
				</div>

                {Quistions?.length > 0 && ( 
                    !isUploading ?
                    <div className="w-100 text-center mb-5">
                        <button onClick={() => handleUploadQuestions()} className="w-50 my-3 mx-auto btn-primary btn">رفع الأسئلة</button> 

                    </div>
                : 
                <div class="d-flex justify-content-center">
                <div class="spinner-grow text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                </div>
                )
                }
        
      {isLoading && (
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {examDetails?.questions?.length > 0 && (
        <div class="">
          <table
            className=" d-block table table-bordered table-striped "
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th> رقم الصورة </th>
                <th> الصورة </th>
              </tr>
            </thead>
            <tbody>
              {examDetails.questions?.map((File, index) => (
                <tr key={File.image_id}>
                  <td> {index} </td>
                  <td>
                    {" "}
                    <a
                      target="blank"
                      href={` ${process.env.REACT_APP_Video_URL}${File.file_link}`}
                    >
                      {" "}
                      <img
                        src={`${process.env.REACT_APP_Video_URL}${File.file_link}`}
                        alt="imag"
                        style={{ width: "60vw", height: "60vh" }}
                      />{" "}
                    </a>{" "}
                  </td>

                  <td>
                    {" "}
                    <div className="d-flex flex-column gap-5 h-100 w-100 justify-content-between align-items-between">
                    <button 
                    className='btn btn-danger'  
                    type="button"  
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal" 
                     onClick={() => setSelectedDeleteId(File.id)}>حذف</button>


                    {/* {" "}
                    <button
                      onClick={() => {
                      
                      }}
                      className="btn btn-success"
                    >
                      {" "}
                      تعديل الصورة{" "}
                    </button>{" "} */}

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

    </div>



    <div className="modal fade" ref={modalRef} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {isLoading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>
           
           
        </div>
        {isLoading && <div className="modal-body">
        {isLoading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
      
        }
        </div>
        }

        {
            examDetails?.questions?.length == 0 && (
                <div className="my-3 text-center">
                <h2>لم يتم إضافة أسئلة بعد</h2>
                </div>
            )
        }

       {!isLoading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn-danger"
            onClick={(e) => handleDeleteExam(e,selectedDeleteId)}
            >حذف</button>

        </div>
        }
      
    </div>
    </div>
    </div>
 
    </Layout>
  );
}
