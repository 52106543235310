import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { useDispatch } from "react-redux";
import { getImage } from "../../redux-toolkit/reducers/GetImage/GetImage";
import { useNavigate } from "react-router-dom";

export default function SubjectLogic(
  subscribtionPage,
  id,
  reset,
  idTeacher,
  subSectionId,
  setSuccessMessage,
  setErrorMessage,
  setSuccess,
  setFaild
) {
  console.log("teacher idddd", idTeacher);
  console.log("subSection iddd", subSectionId);
  //remove the image from input file
  const [AddSubscribtion, setAddSubscribtion] = useState(false);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  console.log("iddd", id);
  const tempId = id || 0;
  console.log("tempIDDsdfdsfjhj", tempId);
  const [valid, SetValid] = useState(false);
  const bach = useRef(null);
  const bache = useRef(null);
  const bachee = useRef(null);

  useEffect(() => {
    setAddSubscribtion(false);
  }, []);

  const handleSubmitApi = (data) => {
    if (id) {
      console.log("Update");
      let formData = new FormData();
      setIsLoading(true);
      // setImage(data.image[0]);
      // console.log("image Select Logic",data.cover[0]);
      // console.log("video",data.introductory_video[0])

      // dispatch(getImage(data.subscription_image[0]));

      if (typeof data.subject_image !== "string") {
        console.log("hereer image push");
        formData.append("cover", data.subject_image[0]);
      }

      console.log("x".repeat(30), data.introductory_video[0]);
      if (
        data.introductory_video[0] &&
        data.introductory_video[0] != "string" &&
        data.introductory_video[0] != "undefined" &&
        data.introductory_video[0] != "null"
      ) {
        formData.append("introductory_video", data.introductory_video[0]);
      }

      formData.append("name", data.subject_name);
      formData.append("notify_name", data.notify_name);
      formData.append("points", data.points);
      formData.append("requirements", data.subject_req);
      formData.append("sub_section_id", subSectionId);
      formData.append("transable", 0);
      formData.append("teacher_id", idTeacher);
      formData.append("price", data.price);
      formData.append("description", data.subject_description);
      formData.append("is_for_selling", data.is_for_selling);

      if (data.coupon_id != "nothing") {
        formData.append("coupon_id", data.coupon_id);
      }

      for (var pair of formData.entries()) {
        console.log("form Data", pair[0] + ", " + pair[1]);
      }
      //  formData.append("subscription_id" ,id);
      const res = axios
        .post(
          `${process.env.REACT_APP_API_URL}/update_subject_by_subject_id/${id}`,
          formData,
          {
            headers: {
              "content-type": "text/json",
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              "Content-Encoding": "gzip , deflate, br",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },

            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              console.log(progress);
              setProgress(progress);
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            SetValid(true);
            setIsLoading(false);
            console.log("data EDIT Subscribtion", data);
            setSuccessMessage("تم تعديل معلومات المادة بنجاح");
            navigate(-1);

            //  window.location.reload();
            return res;
          } else {
            console.log(res);
            setIsLoading(false);
            setErrorMessage("غير متصل بالشبكه");
            throw new Error("there is an error");
          }
        })
        .catch((error) => {
          // setErrorMessage(error.message);
          setIsLoading(false);
          setErrorMessage(error.response.data.message);
          console.log(error.response);
        });
    } else {
      let formData = new FormData();
      setIsLoading(true);
      // setImage(data.image[0]);

      if (data.coupon_id) {
        formData.append("coupon_id", data.coupon_id);
      }

      // dispatch(getImage(data.subscription_image[0]));

      formData.append("cover", data.subject_image[0]);
      formData.append("name", data.subject_name);
      formData.append("notify_name", data.notify_name);
      formData.append("points", data.points);
      if (
        (data.introductory_video[0] &&
          typeof data.introductory_video != "string") ||
        typeof data.introductory_video != undefined ||
        typeof data.introductory_video != null
      ) {
        formData.append("introductory_video", data.introductory_video[0]);
      }
      // formData.append("introductory_video" , data.introductory_video[0]);

      formData.append("requirements", data.subject_req);
      formData.append("sub_section_id", subSectionId);
      formData.append("transable", 0);
      formData.append("teacher_id", idTeacher);
      formData.append("price", data.price);
      formData.append("description", data.subject_description);

      // console.log('hello',formData)
      for (var pair of formData.entries()) {
        console.log("form Data", pair[0] + ", " + pair[1]);
      }

      const res = axios
        .post(`${process.env.REACT_APP_API_URL}/add_subject`, formData, {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
            setIsLoading(true);
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            SetValid(true);
            reset({
              subscription_name: "",
              subscription_price: "",
              subscription_description: "",
              subscription_image: null,
            });

            setIsLoading(false);

            setAddSubscribtion(true);
            setSuccessMessage("تم إضافة المادة بنجاح");
            console.log("data Post Subscribtion", data);
            navigate(-1);

            return res;
          } else {
            setIsLoading(false);
            console.log(res);
            setErrorMessage("غير متصل بالشبكه");
            throw new Error("there is an error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // setErrorMessage(error.message);
          setErrorMessage(error.response.data.message);
          //  setIsError(error.response.data)
        });
    }
  };

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

  return {
    valid,
    handleSubmitApi,
    bach,
    bache,
    bachee,
    AddSubscribtion,
    progress,
    IsLoading,
    changeColor,
  };
}
