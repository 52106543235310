import React , {useEffect, useState} from 'react'
import { Link, useParams,useLocation } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import TableSubSections from '../ConfirmRequest/TableSubSections';
import ShowSubSectionsLogic from './ShowSubSectionsLogic';

const ShowSubSections = () => {

  const params = useParams();
  const id = params?.id;


  console.log("YESSSSSSSSSS");
    const  {listRef,isLoading, isError, data, error,handlePageClick,page } = ShowSubSectionsLogic(id);
    console.log("SubSections Dataaaaaaaa" ,data);
    const dataSubSections = data?.data
    const [status,setStatus] = useState();
    const {state} = useLocation();

    useEffect(() => {
      console.log('state is => ',state.sectionId)
    },[])

    return (
      <>
      <Layout>
          <div className='container all'>
          
          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >الدورات
          </h1>

              <div className='d-flex justify-content-around mb-5'>           
              </div>
  
              {
                <TableSubSections sectionId={state.sectionId} data={dataSubSections} isLoading={isLoading}/>
              }
  
                   {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
  
                 
              </div>
      </Layout>

      </>
    )
}

export default ShowSubSections;
              
              
    