import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchSuperiorsSections from "../../redux-toolkit/api/fetchSuperiorsSections";

const SuperiorsLogic = () => {
  const [page, setPage] = useState(0);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["superiors_sections", page],
    fetchSuperiorsSections
  );

  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  return {
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
    refetch,
  };
};

export default SuperiorsLogic;
