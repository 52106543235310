import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { useDispatch } from "react-redux";
import { getImage } from "../../redux-toolkit/reducers/GetImage/GetImage";
import { useNavigate } from "react-router-dom";

export default function AddSupderiorsSectionLogic(
  id,
  reset,
  idTeacher,
  subSectionId,
  setSuccessMessage,
  setErrorMessage,
  setSuccess,
  setFaild
) {
  //remove the image from input file
  const [AddSubscribtion, setAddSubscribtion] = useState(false);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [valid, SetValid] = useState(false);
  const bach = useRef(null);
  const bache = useRef(null);
  const bachee = useRef(null);

  useEffect(() => {
    setAddSubscribtion(false);
  }, []);

  const handleSubmitApi = (data) => {
    setIsLoading(true);

    let formData = new FormData();

    if (id) {
      formData.append("transable", 0);
      formData.append("name", data.name);

      // formData.append("subscription_id" ,id);

      const res = axios
        .post(`${process.env.REACT_APP_API_URL}/superiors-sections`, formData, {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
            setIsLoading(true);
            console.log(
              `${process.env.REACT_APP_API_URL}/add_website_library_section_book`
            );
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            SetValid(true);
            setIsLoading(false);
            console.log("data EDIT Subscribtion", data);
            setSuccessMessage("تم التعديل بنجاح");
            // window.location.reload();
            setSuccess(true);
            navigate(-1);
            return res;
          } else {
            console.log(res);
            setIsLoading(false);
            setErrorMessage("غير متصل بلشبكه");
            setFaild(true);
            throw new Error("there is an error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.response);
          setFaild(true);
          setErrorMessage(error.response.data.message);
        });
    } else {
      formData.append("name", data.name);

      const res = axios
        .post(`${process.env.REACT_APP_API_URL}/superiors-sections`, formData, {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            SetValid(true);

            setAddSubscribtion(true);
            setSuccessMessage("تمت  عملية الإضافة بنجاح");
            //  setSuccess(true);

            navigate(-1);
            // window.location.reload();
            return res;
          } else {
            setErrorMessage("غير متصل بالشبكه");
            throw new Error("there is an error");
          }
        })
        .catch((error) => {
          console.log(error.response);
          // setErrorMessage(error.message);
          setErrorMessage(error.response.data.message);
          //  setIsError(error.response.data)
        });
    }
  };

  return {
    valid,
    handleSubmitApi,
    // handleUpdateApi,
    bach,
    bache,
    bachee,
    AddSubscribtion,
    progress,
    IsLoading,
  };
}
