import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './styleTable.css';
import right from '../../assets/students/right.png';
import wrong from '../../assets/students/wrong.jpg';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';


export default function AutomatedQuizes({data , isLoading , selectedAutomatedQuiz , handleSelectAutomatedQuiz}) { 


    return ( 
    <> 

<div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 

            <thead> 

                
                <tr className='align-middle table-head fw-bold text-white'> 
                <th></th>
                <th className='text-center align-middle'>اسم الاختبار</th>
                <th className='text-center align-middle'> عدد النقاط  </th>
                <th className='text-center align-middle'> وصف الاختبار  </th>
                </tr> 

            </thead> 
        
            <tbody style={{minHeight: '400px'}}> 
 
                { isLoading ? <td colSpan={12}> 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                 data && data?.map((student,index) => { 
                        return <tr 
                        key={index} 
                        
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 
                        
                        <td>
                        <input 
                        type={'checkbox'} 
                        checked={selectedAutomatedQuiz?.id == student.id}
                        onChange={(event) => handleSelectAutomatedQuiz(event,student)} className="w-2"/>
                        </td>
                    

                        <td  className='text-center align-middle'>
                        {student.quiz_name}
                        </td>                       
                 
                        <td  className='text-center align-middle'>{student.quiz_points}</td>                       
                        <td  className='text-center align-middle'>{student.description}</td>                                           
                        </tr> 
                    }) 
                } 

            
            </tbody> 
        </table> 
        </div>

        
        
     
     
    </> 
  ) 
}