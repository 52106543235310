

import axios from 'axios';
import React, { useRef, useState } from 'react';
import Pagination from '../Pagination/Pagination';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';


export default function StudentCouponsTable({data , isLoading , page , setPage}) { 
    
    const [isloading,setIsLoading] = useState(false);
    const [selectedStudentId,setSelectedStudentId] = useState();

    const [ErrorMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');

    const closeBtn = useRef(null);

    const handlePageClick = () => {
        setPage(data?.data?.selected+1);
    }

    const handleDeleteItem = (id) => {
        
        setIsLoading(true);
        axios.delete(`${process.env.REACT_APP_API_URL}/coupon/students/${id}`, {headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }} )
        .then(() => {
            setSuccessMessage('تم حذف الطالب بنجاح');
        })
        .catch(() => {
            setErrorMessage('حدثت مشكلة ما يرجى المحاولة مرة أخرى')
        })
        .finally(() => {
            setIsLoading(false);
            closeBtn.current.click();
        })

    }

    return ( 
    <> 
    <ToastSuccessError 
    setErrorMessage={setErrorMessage}
    setSuccessMessage={setSuccessMessage}
    ErrorMessage={ErrorMessage}
    successMessage={successMessage}
    />
        <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 

            <thead> 
                
                <tr className='align-middle table-head fw-bold text-white'> 
               
                    <th className='text-center align-middle'>اسم الطالب</th>
                    <th className='text-center align-middle'>رقم الهاتف </th>
                    <th className='text-center align-middle'> العمليات </th>

                </tr> 

            </thead> 
        
            <tbody style={{minHeight: '400px'}}> 
                
                { 

                isLoading ? <td colSpan={12}> 

                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> 

                   :

                 data && data?.data?.data?.map((unit,index) => { 
                        return <tr 
                        key={index} 
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 


                        <td  className='text-center align-middle'>
                            {unit.name}
                        </td> 

                        <td  className='text-center align-middle'>
                            {unit.phone_number}
                        </td>  

                        <td  className='text-center align-middle'>
                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            حذف
                            </button>
                        </td>                             

                        </tr> 
                    }) 
                } 

                {
                    data && data?.data?.data?.length == 0 && 
                    <tr>
                        <td colSpan={3} className="text-center fs-5">ليس هنالك بيانات</td>
                    </tr>
                }

            
            </tbody> 
        </table> 
        </div>

        <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />

        
        
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">هل أنت متأكد من  أنك تريد الحذف</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">
        تأكيد عملية الحذف
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal" closeBtnRef={closeBtn}>إلغاء</button>
        <button type="button" class="btn btn-danger" onClick={() => handleDeleteItem(selectedStudentId)}>
        تأكيد
        
        {
            isloading && <div className='mx-2'>
            <button class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span>
            </button>
            </div>
        }

        </button>
      </div>
    </div>
  </div>
</div>
     
    </> 
  ) 
}