import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchFaqsSubjectApi from "../../redux-toolkit/api/fetchFaqs";

const ShowFaqsSubjectsLogic = (id) => {
    const [page,setPage] =useState( +localStorage.getItem('subjectfaqs_pag') == null ? 1 : Number(+localStorage.getItem('subjectfaqs_pag')) );
    console.log("nooooooo");
    const {isLoading, isError, data, error} = useQuery(['FaqsSubjects',page ,id], fetchFaqsSubjectApi);   
    
    function handlePageClick (data)
    {  
        localStorage.setItem('subjectfaqs_pag' , data.selected)
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page
    }
}

export default  ShowFaqsSubjectsLogic