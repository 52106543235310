

import React from 'react';
import './styleTable.css';


export default function UnitsForStudentTable({data , isLoading , selectedUnitsForCancel, setSelectedUnitsForCancel}) { 

    const handleAddDeleteFromUnitsToCancel = (event , clickecUnit) => {


        // get the previous array values and store it 
        const CurrentUnits = selectedUnitsForCancel;
        // console.log('current unitsssss ' , CurrentUnits);
        // check if the student is exist in the selected students array 
        const isExist = selectedUnitsForCancel.some((unit) => unit.id == clickecUnit.id)
        // console.log('is exist', isExist);
        // check if the student is selected or not
        const isSelected = event.target.checked ;
        // console.log('is selected' , isSelected);

        // if it is not exist and is checked push it to the array
        if(!isExist && isSelected) {
            // console.log('adding the student');
            // console.log('array added the studdent',[...CurrentUnits , {...clickecUnit}] )
            setSelectedUnitsForCancel([...CurrentUnits , {...clickecUnit}]);
        }

        // if the student exists and is not selected then remove it from the array
        if(isExist && !isSelected) {
            // console.log('removing the student');
            const newCurrentUnits = CurrentUnits.filter((unit) => unit.unit_id != clickecUnit.unit_id);
            setSelectedUnitsForCancel([...newCurrentUnits]);
        }
    }

    return ( 
    <> 

        <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 

            <thead> 
                
                <tr className='align-middle table-head fw-bold text-white'> 
                <th>
                    {/* <input type="checkbox" /> */}
                </th>
                    <th className='text-center align-middle'> المادة</th> 
                    <th className='text-center align-middle'>الوحدة</th>
                </tr> 

            </thead> 
        
            <tbody style={{minHeight: '400px'}}> 
                
                { 

                isLoading ? <td colSpan={12}> 

                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> 

                   :

                 data && data?.data?.map((unit,index) => { 
                        return <tr 
                        key={index} 
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 
                        <td className='text-center align-middle'>
                            <input
                            type='checkbox'
                            checked={selectedUnitsForCancel.some(un => un.id == unit.id)}
                            onChange={(event) => handleAddDeleteFromUnitsToCancel(event,unit)} className="w-2"
                            />
                        </td>

                        <td  className='text-center align-middle'>
                            {unit.subject_name}
                        </td>                             
                        <td  className='text-center align-middle'>
                            {unit.name}
                        </td>                             

                        </tr> 
                    }) 
                } 

                {
                    data && data?.data?.length == 0 && 
                    <tr>
                        <td colSpan={3} className="text-center fs-5">لا يوجد بعد اشتراكات وحدات لهذا الطالب</td>
                    </tr>
                }

            
            </tbody> 
        </table> 
        </div>

        
        
     
     
    </> 
  ) 
}