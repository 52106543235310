import React, { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./SideBar.css";

import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import SideBarLogic from "./SideBarLogic";
import { useSelector } from "react-redux";
import RequireSideLink from "../RequireSideLink/RequireSideLink";
import axios from "axios";
import examicon from "../../assets/Home/examicon.svg";
import smsIcon from "../../assets/students/sms.svg";
import { ProfileContext } from "../../contexts/ProfileContext";

export default function SideBar() {
  const profileData = useContext(ProfileContext);
  console.log(profileData)

  const { setOpen, open } = SideBarLogic();
  const userData = useSelector((state) => state.Login);

  const navigate = useNavigate();

  function backendLoggingOut(token) {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        console.log("logout data", data);
      })
      .catch((error) => {
        console.log("logout error", error);
      })
      .finally();
  }

  const handleLogout = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("role_id");

    backendLoggingOut(token);
    alert("تم تسجيل الخروج بنجاح");
    navigate("/loginasadmin");
    // window.location.reload();
  };

  return (
    <>
      {!open && (
        <GiHamburgerMenu
          onClick={() => setOpen(!open)}
          className="open__close__icon "
        />
      )}
      <div className={open ? "SideBar aa" : "SideBar_TY"} id="sidebar">
        {open && (
          <>
            <Link to="/" className="sideBar__user">
              <span
                className="brand-text  fw-bold my-2"
                style={{ color: "#1bd3c5" }}
              >
                <h4>طريقي</h4>{" "}
              </span>
            </Link>

            <nav className="mynav-x ">
              <div
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item has-treeview menu-open">
                  <div className="nav nav-treeview">
                    <li className="nav-item"></li>

                    <RequireSideLink AllowedRoles={[4, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/homepage" className=" d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-home text-white  nav-icon" />
                          </div>
                          <p className="text-white paragraph m-0">
                            معلومات الموقع
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[10,6]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/support" className=" d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-envelope text-white nav-icon" />
                          </div>
                          <p className="text-white paragraph m-0">
                            الدعم الفني
                          </p>
                          {profileData?.data?.unread_messages_count > 0 && (
                            <span className="badge" style={{ backgroundColor: "red" }}>{profileData?.data?.unread_messages_count}</span>
                          )}
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[4, 7]}>
                      <li
                        className="nav-item"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/showSections" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-qrcode  nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0"> الأقسام</p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7, 9]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/students" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-user-circle text-white nav-icon" />
                          </div>
                          <p className="text-white paragraph m-0"> الطلاب</p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/superiors" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            <i class="fa fa-graduation-cap nav-icon text-white fa-inverse"></i>
                          </div>
                          <p className="text-white paragraph m-0">
                            نجوم المنصة
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/registerOrders" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            <i class="fa fa-tag nav-icon text-white fa-inverse"></i>
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            طلبات التسجيل{" "}
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/coupons" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            <i class="fa fa-tag nav-icon text-white fa-inverse"></i>
                          </div>
                          <p className="text-white paragraph m-0">
                            كوبونات الخصم{" "}
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink
                          to="/units_subscribtions"
                          className="  d-flex py-2"
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <i
                              class="fa fa-window-maximize nav-icon text-white"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            تسجيل الوحدات
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink
                          to="/students_messages"
                          className="  d-flex py-2"
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <img
                              src={smsIcon}
                              className="fa nav-icon text-white"
                              width="20"
                              height={20}
                            />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            رسائل الطلاب
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink
                          to="/students_messagesRecord"
                          className="  d-flex py-2"
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <img
                              src={smsIcon}
                              className="fa nav-icon text-white"
                              width="20"
                              height={20}
                            />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            سجل الرسائل
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[4, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink
                          to="/userOpinions"
                          className="d-flex align-items-center py-2"
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-comments-o nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            أراء الطلاب
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/teachers" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-male nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0"> الأساتذة</p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7, 8]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/exams" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <img
                              src={examicon}
                              className="fa nav-icon text-white"
                              width="20"
                              height={20}
                            />
                          </div>
                          <p className="text-white paragraph m-0"> المذاكرات</p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/faqs" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i
                              className="fa fa-question-circle nav-icon text-white"
                              width="20"
                              height={20}
                            />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            الأسئلة الشائعة
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[4, 6, 7, 8]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/quizes-bank" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i
                              className="fa fa-bank nav-icon text-white"
                              width="20"
                              height={20}
                            />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            بنك الاختبارات
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/admins" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-male nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0"> الأدمنز</p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[6, 7]}>
                      <li
                        className="nav-item text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/guirdians" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-male nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            أولياء الأمور
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[4, 7]}>
                      <li
                        className="nav-item  text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/library" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-book nav-icon text-white" />
                          </div>

                          <p className="text-white paragraph m-0">
                            {" "}
                            مكتبة الموقع
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    {/* <RequireSideLink AllowedRoles={[4,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >

                    <NavLink to='/magazine' className="  d-flex">
                    <div className='d-flex align-items-center'> <i className="fa fa-clipboard nav-icon text-white" /></div>
                      <p className='text-white paragraph'>   مجلة الموقع</p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

                    {/* <RequireSideLink AllowedRoles={[6,7]}>
                <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >


                  <NavLink to='/addstar' className="  d-flex">
                  <div className='d-flex align-items-center'> <i className="fa fa-star nav-icon text-white" /></div>
                    <p className='text-white paragraph'>   نجوم الموقع</p>
                 </NavLink>

                </li>
                </RequireSideLink> */}
                    {/*

                <RequireSideLink AllowedRoles={[4,7]}>
                <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >

                  <NavLink to='/aboutus' className="  d-flex">
                  <div className='d-flex align-items-center'>  <i className="fa fa-exclamation-circle nav-icon text-white" /></div>
                    <p className='text-white paragraph'>   لمحة عنا </p>
                 </NavLink>

                </li>
                </RequireSideLink> */}

                    {/*
                <RequireSideLink AllowedRoles={[4,7]}>
                  <li className=" my-2 text-white" style={{borderBottom:'1px solid grey'}} >


                    <NavLink to='/offers' className="  d-flex">
                    <div className='d-flex align-items-center'>  <i className="fa fa-calendar-o nav-icon text-white" /></div>
                      <p className='text-white paragraph'>    العروض   </p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

                    {/* <RequireSideLink AllowedRoles={[4,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}}  >

                    <NavLink to='/program' className="  d-flex">
                    <div className='d-flex align-items-center'>  <i className="fa fa-table nav-icon text-white" /></div>
                      <p className='text-white paragraph'>   البرنامج  </p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

                    {/* <RequireSideLink AllowedRoles={[5,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >

                    <NavLink to='/pay' className="  d-flex">
                    <div className='d-flex align-items-center'> <i className="fa fa-money nav-icon text-white" /></div>
                      <p className='text-white'> طلبات الدفع </p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

                    <RequireSideLink AllowedRoles={[5, 7]}>
                      <li
                        className=" text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink
                          to="/mainSubscribtions"
                          className="  d-flex py-2"
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-calendar-o nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            الاشتراكات{" "}
                          </p>
                        </NavLink>
                      </li>

                      <li
                        className=" text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/showOffers" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-calendar-o nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0"> العروض </p>
                        </NavLink>
                      </li>

                      <li
                        className=" text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/addOffers" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-calendar-o nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0"> اضف عرض </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[5, 7]}>
                      <li
                        className="text-white"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <NavLink to="/subscribe" className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-calendar-o nav-icon text-white" />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            اضف اشتراك{" "}
                          </p>
                        </NavLink>
                      </li>
                    </RequireSideLink>

                    <RequireSideLink AllowedRoles={[4, 5, 6, 7, 8, 9, 10,6]}>
                      <li
                        className=" text-white"
                        style={{
                          borderBottom: "1px solid grey",
                          cursor: "pointer",
                        }}
                        onClick={handleLogout}
                      >
                        <div className="  d-flex py-2">
                          <div className="d-flex align-items-center">
                            {" "}
                            <i className="fa fa-user-circle text-white nav-icon" />
                          </div>
                          <p className="text-white paragraph m-0">
                            {" "}
                            تسجيل الخروج{" "}
                          </p>
                        </div>
                      </li>
                    </RequireSideLink>

                    {/* <RequireSideLink AllowedRoles={[5,7]}>

            <li className=" my-2 text-white" style={{borderBottom:'1px solid grey'}}>
              <NavLink to='/showSections' className="  d-flex">
              <div className='d-flex align-items-center'> <i className="fa fa-calendar-o nav-icon text-white" /></div>
                <p className='text-white paragraph'> الاقسام  </p>
            </NavLink>

            </li>
            </RequireSideLink> */}

                    {/* <RequireSideLink AllowedRoles={[4,7]}>
                <li className=" my-2 text-white" style={{borderBottom:'1px solid grey'}} >

                  <NavLink to='/privacy' className="  d-flex">
                  <div className='d-flex align-items-center'>  <i className="fa fa-male nav-icon text-white" /></div>
                    <p className='text-white paragraph'>   سياسة الخصوصية  </p>
                  </NavLink>

                </li>
                </RequireSideLink> */}
                  </div>
                </li>
              </div>
            </nav>
          </>
        )}
      </div>
    </>
  );
}
