import React, {useEffect, useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import Layout from "../Layout/Layout";
import Pagination from "../Pagination/Pagination";
import StudentsTable from "../ConfirmRequest/StudentsTable";
import fetchStudents from "../../redux-toolkit/api/fetchStudents";
import fetchUnActiveStudents from "../../redux-toolkit/api/fetchUnActiveStudents";
import fetchNewStudents from "../../redux-toolkit/api/fetchNewStudents";
import fetchActiveStudents from "../../redux-toolkit/api/fetchActiveStudents";
import group from "../../assets/AboutUs/Group 408.png";
import {useQuery} from "react-query";
import UnActiveStudentsTable from "../ConfirmRequest/UnActiveStudentsTable";
import NewStudentsTable from "../ConfirmRequest/NewStudentsTable";
import ActiveStudentsTable from "../ConfirmRequest/ActiveStudentsTable";
import {debounce} from "lodash";
import "./Student.css";
import fetchProvinces from "../../redux-toolkit/api/fetchProvinces";
import fetchSectionsApi from "../../redux-toolkit/api/fetchSections";
import fetchSubSectionsApi from "../../redux-toolkit/api/fetchSubSections";

export default function Student() {
    const [page, setPage] = useState(
        +localStorage.getItem("allstudents_pag") == null
            ? 1
            : Number(+localStorage.getItem("allstudents_pag"))
    );

    const [pageUnActive, setPageUnActive] = useState(
        +localStorage.getItem("unactivestudents_pag") == null
            ? 1
            : Number(+localStorage.getItem("unactivestudents_pag"))
    );

    const [pageNew, setpageNew] = useState(
        +localStorage.getItem("newstudents_pag") == null
            ? 1
            : Number(+localStorage.getItem("newstudents_pag"))
    );

    const [pageActive, setPageActive] = useState(
        +localStorage.getItem("activestudents_pag") == null
            ? 1
            : Number(+localStorage.getItem("activestudents_pag"))
    );

    const [typeOfTable, setTypeOfTable] = useState(
        localStorage.getItem("typeOfTable") == null
            ? 1
            : localStorage.getItem("typeOfTable")
    );

    const [searchResult, setSearchResult] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");
    const inputSearchRef = useRef();

    const [activeProvincesFilter, setActiveProvincesFilter] = useState(false);
    const [sortByAchivment, setSortByAchivment] = useState(false);

    const [provinceId, setProvinceId] = useState(
        localStorage.getItem("provindeIdFilter")
            ? localStorage.getItem("provindeIdFilter")
            : ""
    );
    const [genderFilter, setGenderFilter] = useState(
        localStorage.getItem("genderFiler")
            ? localStorage.getItem("genderFiler")
            : ""
    );
    const [sectionoFilter, setSectionFilter] = useState(
        localStorage.getItem("sectionFilter")
            ? localStorage.getItem("sectionFilter")
            : ""
    );
    const [subSectionoFilter, setSubSectionFilter] = useState(
        localStorage.getItem("subSectionFilter")
            ? localStorage.getItem("subSectionFilter")
            : ""
    );
    const [yearFilter, setYearFilter] = useState("");

    const [query, setQuery] = useState("");
    const [query2, setQuery2] = useState("");
    const [query3, setQuery3] = useState("");
    const [query4, setQuery4] = useState("");

    React.useEffect(() => {
    }, [query, query2, query3, query4]);

    let shouldSetQuery = true;

    const unOrderStudentsList = useRef();
    const [stuentId, setStduentId] = useState();

    // localStorage.setItem('typeOfTable', 1);

    const {isLoading, isError, data, error} = useQuery(
        [
            "students",
            page,
            query,
            typeOfTable,
            provinceId,
            genderFilter,
            sectionoFilter,
            subSectionoFilter,
            sortByAchivment,
            yearFilter,
        ],
        fetchStudents
    );

    const {
        isLoading: isLoadingSections,
        isError: isErrorSections,
        data: dataSections,
        error: errorSections,
    } = useQuery(["sections", 1], fetchSectionsApi);

    const {
        isLoading: isLoadingSubSections,
        isError: isErrorSubSections,
        data: dataSubSections,
        error: errorSubSections,
    } = useQuery(["subSections", 1, sectionoFilter], fetchSubSectionsApi);

    const {
        isLoading: IsLoadingNew,
        isError: isErrorNew,
        data: dataNew,
        error: errorNew,
    } = useQuery(
        [
            "newstudents",
            pageNew,
            query3,
            typeOfTable,
            provinceId,
            genderFilter,
            sectionoFilter,
            subSectionoFilter,
            sortByAchivment,
            yearFilter,
        ],
        fetchNewStudents
    );

    const {
        data: provinces,
        isLoading: isLoadingProvinces,
        isErrorProvinces,
    } = useQuery(["provinces"], fetchProvinces);

    const {
        isLoading: isLoadingActive,
        isError: isErrorActive,
        data: dataActive,
        error: errorActive,
    } = useQuery(
        [
            "activeStudents",
            pageActive,
            query4,
            typeOfTable,
            provinceId,
            genderFilter,
            sectionoFilter,
            subSectionoFilter,
            sortByAchivment,
            yearFilter,
        ],
        fetchActiveStudents
    );

    const {
        isLoading: loadingUn,
        isError: isErrorUn,
        data: dataUn,
        error: errorUn,
    } = useQuery(
        [
            "unactivestudents",
            pageUnActive,
            query2,
            typeOfTable,
            provinceId,
            genderFilter,
            sectionoFilter,
            subSectionoFilter,
            sortByAchivment,
            yearFilter,

        ],
        fetchUnActiveStudents
    );

    function resetSubsectionFilter() {
        setSubSectionFilter("");
        localStorage.setItem("subSectionFilter", "");
    }

    useEffect(() => {
        // if(typeOfTable != 1 && typeOfTable != null){
        //   localStorage.removeItem('allstudents_pag')
        // }

        if (typeOfTable) {
            resetAllStudentsPageToZero();
        }

        // reset the query param when ever the user change the type of table wich is displayed
        setQuery("");
        setQuery2("");
        setQuery3("");
        setQuery2("");

        inputSearchRef.current.value = "";
    }, [typeOfTable]);

    const setQueryOnTablesType = (value) => {
        if (typeOfTable == 1) {
            setQuery(value);
        } else if (typeOfTable == 2) {
            setQuery2(value);
        } else if (typeOfTable == 3) {
            setQuery3(value);
        } else if (typeOfTable == 4) {
            setQuery4(value);
        }
    };

    const setQueryNotOnEveryChnage = (value) => {
        if (shouldSetQuery) {
            setQueryOnTablesType(value);
            shouldSetQuery = false;
        }

        setTimeout(() => {
            shouldSetQuery = true;
        }, 1000);
    };

    const handleSearchStudent = (e) => {
        const value = e.target.value;
        unOrderStudentsList.current.style.height = "auto";

        setQueryNotOnEveryChnage(value);
        handleOnFucusInput();
        handleUnorderStudentsList(e.target.value);

        // setValue('student_id',e.target.value);
    };

    const devouncedFn = debounce(handleSearchStudent, 500);

    const handleUnorderStudentsList = (searchQuery) => {
        if (searchQuery == "") {
            unOrderStudentsList.current.style.height = "0px";
        } else {
            unOrderStudentsList.current.style.height = "auto";
        }
    };

    function handleOnFucusInput() {
        if (typeOfTable == 1) {
            localStorage.setItem("allstudents_pag", 0);
            setPage(0);
        }

        if (typeOfTable == 2) {
            localStorage.setItem("unactivestudents_pag", 0);
            setPageUnActive(0);
        }

        if (typeOfTable == 3) {
            localStorage.setItem("newstudents_pag", 0);
            setpageNew(0);
        }

        if (typeOfTable == 4) {
            localStorage.setItem("activestudents_pag", 0);
            setPageActive(0);
        }
    }

    function handlePageClick(data) {
        localStorage.setItem("allstudents_pag", data.selected);
        setPage(data.selected);
    }

    function handlePageUnActiveClick(dataUn) {
        localStorage.setItem("unactivestudents_pag", dataUn.selected);
        setPageUnActive(dataUn.selected);
    }

    function handlePageNewClick(dataNew) {
        localStorage.setItem("newstudents_pag", dataNew.selected);
        setpageNew(dataNew.selected);
    }

    function handlePageActiveClick(dataActive) {
        localStorage.setItem("activestudents_pag", dataActive.selected);
        setPageActive(dataActive.selected);
    }

    const resetAllStudentsPageToZero = () => {
        localStorage.setItem("newstudents_pag", 0);
        localStorage.setItem("allstudents_pag", 0);
        localStorage.setItem("activestudents_pag", 0);
        localStorage.setItem("unactivestudents_pag", 0);
    };

    useEffect(() => {
        return () => {
            // localStorage.removeItem('provindeIdFilter');
        };
    });
    const [years, setYears] = useState([]);

    useEffect(() => {
        const newYears = [];

        for (let i = 2022; i <= 2100; i++) {
            newYears.push(i);
        }

        setYears([...newYears]);
    }, []);

    return (
        <Layout>
            <div className="container all mb-5">
                <h1
                    className="pt-0 mb-4 pb-4 pt-3"
                    style={
                        {
                            // borderBottom: '1px solid green'
                        }
                    }
                >
                    الطلاب
                </h1>

                <div
                    className="d-flex justify-content-center mb-3 border w-auto dot p-4 flex-column align-items-center mt-4 ">
                    <h5 className="gre"> أضف طالب</h5>
                    <NavLink to="/addstudent">
                        <img src={group} className="me"/>
                    </NavLink>
                </div>

                <div className="d-flex justify-content-evenly my-3">
                    <button
                        className="btn text-white shadow "
                        style={{backgroundColor: typeOfTable == 1 ? "#00a1a1" : "#666"}}
                        onClick={() => {
                            setTypeOfTable(1);
                            localStorage.setItem("typeOfTable", 1);
                        }}
                    >
                        جميع الحسابات
                    </button>
                    {
                        localStorage.getItem('role_id') != 9 && <>
                            <button
                                className="btn text-white shadow"
                                style={{backgroundColor: typeOfTable == 4 ? "#00a1a1" : "#666"}}
                                onClick={() => {
                                    setTypeOfTable(4);
                                    localStorage.setItem("typeOfTable", 4);
                                }}
                            >
                                الحسابات المفعلة
                            </button>

                            <button
                                className="btn text-white shadow"
                                style={{backgroundColor: typeOfTable == 2 ? "#00a1a1" : "#666"}}
                                onClick={() => {
                                    setTypeOfTable(2);
                                    localStorage.setItem("typeOfTable", 2);
                                }}
                            >
                                الحسابات الغير مفعلة
                            </button>

                            <button
                                className="btn text-white shadow"
                                style={{backgroundColor: typeOfTable == 3 ? "#00a1a1" : "#666"}}
                                onClick={() => {
                                    setTypeOfTable(3);
                                    localStorage.setItem("typeOfTable", 3);
                                    localStorage.setItem("newstudents_pag", 0);
                                }}
                            >
                                الحسابات الجديدة
                            </button>
                        </>
                    }
                        </div>

                        <input
                        type="text"
                        // onFocus={() => handleOnFucusInput()}
                        ref={inputSearchRef}
                     placeholder="اكتب هنا للبحث عن طالب"
                     onChange={(e) => devouncedFn(e)}
                     className="shadow w-100 main-input border-0"
                />

                <div className="students_select mb-2 rounded">
                    <ul className="unorder_students_list" ref={unOrderStudentsList}>
                        {searchResult &&
                            searchResult?.map((student, index) => {
                                return (
                                    <li
                                        //  onClick={() => handleSelectStudentFromDrobDownList(student)}
                                        value={student.student_id}
                                        key={index}
                                    >
                                        {student.name}
                                    </li>
                                );
                            })}
                    </ul>

                    {/* <div className='text-center my-5'>
            <button className='btn btn-primary' onClick={handleAddStudent}>اضف طالب</button>
       </div> */}
                </div>

                <div className="d-flex flex-wrap  p-5">
                    {/* provinces filter */}
                    <div className="col-sm-12 col-md-3 form-outline mb-2">
                        <label className="d-flex align-items-center">
                            {/* <input
  type='checkbox'
  className='cursor-pointer mx-2'
  checked={activeProvincesFilter}
  onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                            المحافظات
                        </label>

                        <select
                            // disabled={!activeProvincesFilter}
                            id="form3Example4"
                            onChange={(e) => {
                                setProvinceId(e.target.value);
                                localStorage.setItem("provindeIdFilter", e.target.value);
                            }}
                            className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                        >
                            <option selected={provinceId == ""} value={""}>
                                جميع المحافظات
                            </option>
                            {provinces &&
                                provinces?.map((province, idx) => {
                                    return (
                                        <option
                                            selected={
                                                province.id == localStorage.getItem("provindeIdFilter")
                                            }
                                            value={province.id}
                                        >
                                            {province.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-3 form-outline mb-2">
                        <label>الجنس</label>
                        <select
                            id="form3Example4"
                            onChange={(e) => {
                                setGenderFilter(e.target.value);
                                localStorage.setItem("genderFiler", e.target.value);
                            }}
                            className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                        >
                            <option
                                value={""}
                                selected={localStorage.getItem("genderFiler") == ""}
                            >
                                الجميع
                            </option>
                            <option
                                value={"MALE"}
                                selected={localStorage.getItem("genderFiler") == "MALE"}
                            >
                                ذكور
                            </option>
                            <option
                                value={"FEMALE"}
                                selected={localStorage.getItem("genderFiler") == "FEMALE"}
                            >
                                إناث
                            </option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-3 form-outline mb-2">
                        <label className="d-flex align-items-center">
                            {/* <input
 type='checkbox'
 className='cursor-pointer mx-2'
 checked={activeProvincesFilter}
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                            الأقسام
                        </label>

                        <select
                            // disabled={!activeProvincesFilter}
                            id="form3Example4"
                            onChange={(e) => {
                                setSectionFilter(e.target.value);
                                resetSubsectionFilter();
                                localStorage.setItem("sectionFilter", e.target.value);
                            }}
                            className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                        >
                            <option selected={sectionoFilter == ""} value={""}>
                                جميع الأقسام
                            </option>
                            {dataSections &&
                                dataSections?.data?.map((section, idx) => {
                                    return (
                                        <option
                                            selected={
                                                section.section_id ==
                                                localStorage.getItem("sectionFilter")
                                            }
                                            value={section.section_id}
                                        >
                                            {section.section_name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    {
                        <div className="col-sm-12 col-md-3 form-outline mb-2">
                            <label className="d-flex align-items-center">
                                {/* <input
 type='checkbox'
 className='cursor-pointer mx-2'
 checked={activeProvincesFilter}
 onChange={() => setActiveProvincesFilter(prev => !prev)} />  */}
                                الدورات
                            </label>

                            <select
                                // disabled={!activeProvincesFilter}
                                id="form3Example4"
                                disabled={sectionoFilter == ""}
                                onChange={(e) => {
                                    setSubSectionFilter(e.target.value);
                                    localStorage.setItem("subSectionFilter", e.target.value);
                                }}
                                className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                            >
                                <option selected={subSectionoFilter == ""} value={""}>
                                    جميع الدورات
                                </option>
                                {dataSubSections &&
                                    dataSubSections?.data?.data?.map((subSection, idx) => {
                                        return (
                                            <option
                                                selected={
                                                    subSection.sub_section_id ==
                                                    localStorage.getItem("subSectionFilter")
                                                }
                                                value={subSection.sub_section_id}
                                            >
                                                {subSection.sub_section_name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    }

                    <div className="col-sm-12 col-md-3 form-outline mb-2">
                        <label className="d-flex align-items-center">
                            السنة الدراسية
                        </label>
                        <select
                            // disabled={!activeProvincesFilter}
                            id="form3Example4"
                            onChange={(e) => {
                                setYearFilter(e.target.value);
                            }}
                            className={`shadow-sm border ${"border-success"} form-control form-control-lg`}
                        >
                            <option selected={yearFilter == ""} value={""}>
                                جميع السنوات
                            </option>
                            {
                                years.map((year, idx) => {
                                    return (
                                        <option value={year} key={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-3 form-outline mb-2 ">
                        <div class="form-check d-flex align-items-center gap-3">
                            <label
                                className="form-check-label mx-4"
                                for="flexCheckDefault"
                                role="button"
                            >
                                فلترة حسب الإنجاز
                            </label>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                role="button"
                                checked={sortByAchivment}
                                onChange={(e) => setSortByAchivment(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>

                {typeOfTable == 1 && (
                    <div
                        className="alert my-4 border shadow rounded mx-auto fs-4"
                        style={{maxWidth: "500px"}}
                    >
                        {query == ""
                            ? "عدد الطلاب الإجمالي:"
                            : "عدد الطلاب المطابق لعملية البحث:"}{" "}
                        <strong>{data?.data.total}</strong>
                    </div>
                )}

                {typeOfTable == 1 && (
                    <div
                        className="alert my-4 border shadow rounded mx-auto fs-4"
                        style={{maxWidth: "500px"}}
                    >
                        {"عدد الطلاب النشطين آخر 24 ساعة"}{" "}
                        <strong>{data?.total.recently_active_students}</strong>
                    </div>
                )}

                {typeOfTable == 4 && (
                    <div
                        className="alert my-4 border shadow rounded text-center mx-auto fs-4"
                        style={{maxWidth: "500px"}}
                    >
                        {query == ""
                            ? "عدد الطلاب الإجمالي:"
                            : "عدد الطلاب المطابق لعملية البحث:"}{" "}
                        <strong>{dataActive?.data.total}</strong>
                    </div>
                )}

                {/* {
            typeOfTable == 4 && <div className='alert my-4 border shadow rounded mx-auto fs-4'  style={{maxWidth: '500px'}}>
             {'عدد الطلاب النشطين آخر 24 ساعة'}  <strong>{dataActive?.total.recently_active_students }</strong>
        </div>
          } */}

                {typeOfTable == 3 && (
                    <div
                        className="alert my-4 border shadow rounded text-center mx-auto fs-4"
                        style={{maxWidth: "500px"}}
                    >
                        {query == ""
                            ? "عدد الطلاب الإجمالي:"
                            : "عدد الطلاب المطابق لعملية البحث:"}{" "}
                        <strong>{dataNew?.data.total}</strong>
                    </div>
                )}

                {/* {
            typeOfTable == 3 && <div className='alert my-4 border shadow rounded mx-auto fs-4'  style={{maxWidth: '500px'}}>
             {'عدد الطلاب النشطين آخر 24 ساعة'}  <strong>{dataNew?.total.recently_active_students }</strong>
        </div>
          } */}

                {typeOfTable == 2 && (
                    <div
                        className="alert my-4 border shadow rounded text-center mx-auto fs-4"
                        style={{maxWidth: "500px"}}
                    >
                        {query == ""
                            ? "عدد الطلاب الإجمالي:"
                            : "عدد الطلاب المطابق لعملية البحث:"}{" "}
                        <strong>{dataUn?.data.total}</strong>
                    </div>
                )}

                {/* {
            typeOfTable == 2 && <div className='alert my-4 border shadow rounded mx-auto fs-4'  style={{maxWidth: '500px'}}>
             {'عدد الطلاب النشطين آخر 24 ساعة'}  <strong>{dataUn?.total.recently_active_students }</strong>
        </div>
          } */}

                <div className="d-flex justify-content-around mb-5">
                    {typeOfTable == 1 ? (
                        <StudentsTable data={data?.data} isLoading={isLoading}/>
                    ) : null}

                    {typeOfTable == 2 ? (
                        <UnActiveStudentsTable data={dataUn?.data} isLoading={loadingUn}/>
                    ) : null}

                    {typeOfTable == 3 ? (
                        <NewStudentsTable data={dataNew?.data} isLoading={IsLoadingNew}/>
                    ) : null}

                    {typeOfTable == 4 ? (
                        <ActiveStudentsTable
                            data={dataActive?.data}
                            isLoading={isLoadingActive}
                        />
                    ) : null}
                </div>

                {typeOfTable == 1 ? (
                    data?.data.total < 1 ? (
                        <> </>
                    ) : (
                        <Pagination
                            data={data?.data}
                            handlePageClick={handlePageClick}
                            page={page}
                        />
                    )
                ) : null}

                {typeOfTable == 2 ? (
                    dataUn?.data.total < 1 ? (
                        <></>
                    ) : (
                        <Pagination
                            total={dataUn?.total}
                            data={dataUn?.data}
                            handlePageClick={handlePageUnActiveClick}
                            page={pageUnActive}
                        />
                    )
                ) : null}

                {typeOfTable == 3 ? (
                    dataNew?.data.total < 1 ? (
                        <></>
                    ) : (
                        <Pagination
                            total={dataNew?.total}
                            data={dataNew?.data}
                            handlePageClick={handlePageNewClick}
                            page={pageNew}
                        />
                    )
                ) : null}

                {typeOfTable == 4 ? (
                    dataActive?.data.total < 1 ? (
                        <></>
                    ) : (
                        <Pagination
                            total={dataActive?.total}
                            data={dataActive?.data}
                            handlePageClick={handlePageActiveClick}
                            page={pageActive}
                        />
                    )
                ) : null}
            </div>
        </Layout>
    );
}
