import React, { useEffect, useRef , useState } from 'react'
import axios from "axios";

import { useDispatch } from 'react-redux';
import { getImage } from '../../redux-toolkit/reducers/GetImage/GetImage';
import { useNavigate } from 'react-router-dom';



export  default function SubSectionsLogic(subscribtionPage,id,reset,idTeacher,subSectionId,AddSubId,setSuccessMessage,setErrorMessage,setSuccess , setFaild) {


console.log("teacher idddd" , idTeacher);
console.log("subSection iddd"  , subSectionId);
console.log("AddSubid" , AddSubId);
//remove the image from input file
  const [AddSubscribtion , setAddSubscribtion] = useState(false);
  const [progress,setProgress] = useState(0);
  const [IsLoading,setIsLoading] = useState(false);
const navigate = useNavigate()


  console.log("iddd",id);
  const tempId = id || 0
  console.log("tempIDDsdfdsfjhj",tempId);
      const [valid , SetValid] = useState(false);
    const bach=useRef(null);
    const bache=useRef(null);
    const bachee=useRef(null);

  
    useEffect(()=>{
             setAddSubscribtion(false);
    },[])      
    
    

    const handleSubmitApi=(data)=>{

      setIsLoading(true);
      console.log("submit")
      let formData = new FormData();
      console.log("image Select Logic", data.sub_section_image[0]);
      console.log("name",data.sub_section_name);

     
 console.log("outside " , id);
 if(id){
  console.log("inside " , id);
  formData.append("transable",0)
  formData.append("name",data.sub_section_name)
  console.log("add Sub idbefore push " , AddSubId);
  formData.append("section_id",AddSubId);
  formData.append("price",data.price);
  formData.append("is_for_selling",data.is_for_selling);

  
  if(data.coupon_id){
    formData.append("coupon_id",data.coupon_id)
  }
  
  console.log("typeof dataImage",typeof data.sub_section_image) 
  if(typeof data.sub_section_image !== "string" )
{
 console.log("YESss")
formData.append("image",data.sub_section_image[0])
}
  
  const res = axios.post(`${process.env.REACT_APP_API_URL}/update_sub_section_by_sub_section_id/${id}`, formData, {
    headers: {
      'content-type': 'text/json',
      "Content-Type": "multipart/form-data",
      "Accept": "application/json",
      'Content-Encoding': 'gzip , deflate, br',
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
  
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      console.log(progress);
      setProgress(progress);

      // console.log(`${process.env.REACT_APP_API_URL}/add_website_library_section_book`);
    },
  }).then(res=>{
    if(res.status===200){
      const {data} = res;
      SetValid(true);
      console.log("data EDIT Subscribtion" , data);
      setSuccessMessage("تم عملية التعديل بنجاح")
      setIsLoading(false);
      navigate(-1)
      // window.location.reload();
      return res;
    }
    else
    {
      console.log(res);
      setErrorMessage("غير متصل بالشبكه");
      setIsLoading(false);
      throw new Error('there is an error')
      
    }
  }).catch(error=>{
    setIsLoading(false);
    setErrorMessage(error.response.data.message);
    console.log(error.response)
  });
 }

 else {
  formData.append("image",data.sub_section_image[0])
  formData.append("name",data.sub_section_name)
  formData.append("price",data.price)
  formData.append("is_for_selling",data.is_for_selling)
  console.log('jskjsk', data.coupon_id)
  if(data.coupon_id != null){
    formData.append("coupon_id",data.coupon_id)
  }
  formData.append("transable",0)
  formData.append("section_id",AddSubId);
// console.log('hello',formData)
for(var pair of formData.entries()) { 
  console.log("form Data", pair[0]+ ', '+ pair[1]); 
}


 const res = axios.post(`${process.env.REACT_APP_API_URL}/add_sub_section` , formData, {
  headers: {
    'content-type': 'text/json',
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    'Content-Encoding': 'gzip , deflate, br',
    "Authorization": `Bearer ${localStorage.getItem('token')}`
  },
  onUploadProgress: (progressEvent) => {
    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    console.log(progress);
    setProgress(progress);

    // console.log(`${process.env.REACT_APP_API_URL}/add_website_library_section_book`);
  },
}).then(res=>{
  if(res.status===200){
    setIsLoading(false);
    console.log('add sub sub sub section section ')
    const {data} = res;
    SetValid(true);
    reset({sub_section_name:'',
   sub_section_image:null})
   setSuccessMessage("تمت عملية الاضافة بنجاح")
   navigate(-1)
  //  window.location.reload();
   setAddSubscribtion(true);
    console.log("data Post subSectionss" , data);
    return res;
  }
  else
  {
    setIsLoading(false);
    console.log(res);
    setErrorMessage("غير متصل بالشبكه");
    throw new Error('there is an error')
  }
}).catch(error=>{
  setIsLoading(false);
  setErrorMessage(error.response.data.message);
});

 }



  };


  return {
          valid,handleSubmitApi,
          // handleUpdateApi,
          bach,bache,bachee,AddSubscribtion,progress,IsLoading
  }
}
