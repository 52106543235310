import React, { useState } from "react";
import katex from "katex";
import "katex/dist/katex.min.css";
import Layout from "../../components/Layout/Layout";
import useQuizBankLogic from "./useQuizBankLogic";
import QuestionRow from "./QuestionRow";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";

window.katex = katex;

export default function AddDynamicQuizBankQuestion() {
    const [questions, setQuestions] = useState([]); // State to hold questions
    const [numQuestions, setNumQuestions] = useState(1); // State to handle number of questions input
    const [errorMessage, setErroreMessage] = useState(""); // Error message state
    const [successMessage, setSuccessMessage] = useState(""); // Success message state

    const { isLoading, onSubmit } = useQuizBankLogic(); // Use custom hook

    // Generate multiple questions
    const generateQuestions = () => {
        if (numQuestions < 1 || numQuestions > 100) {
            setErroreMessage("الرجاء اختيار رقم ما بين 1 و 100 .");
            return;
        }
        const generatedQuestions = Array.from({ length: numQuestions }, (_, index) => ({
            questionText: `السؤال ${index + 1}`,
            note: "",
            options: ["A", "B", "C", "D"],
            correctOption: 0,
        }));
        setQuestions(generatedQuestions);
        setSuccessMessage(`تم توليد ${numQuestions} سؤال.`);
    };

    // Add a single question
    const addQuestion = () => {
        if (questions.length >= 100) {
            setErroreMessage("لا يمكن إضافة أكثر من 100 سؤال.");
            return;
        }
        const newQuestion = {
            questionText: `السؤال ${questions.length + 1}`,
            note: "",
            options: ["A", "B", "C", "D"],
            correctOption: 0,
        };
        setQuestions([...questions, newQuestion]);
        setSuccessMessage(`تمت إضافة سؤال جديد: السؤال ${questions.length + 1}`);
    };

    // Update a specific question
    const updateQuestion = (index, updatedQuestion) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q, i) => (i === index ? updatedQuestion : q))
        );
    };

    // Submit the form
    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (questions.length === 0) {
            setErroreMessage("لا يوجد أسئلة بعد");
            return;
        }

        const hasErrors = questions.some((q) => {
            if (!q.questionText.trim()) return true;
            if (q.options.some((opt) => !opt.trim())) return true;
            return false;
        });

        if (hasErrors) {
            setErroreMessage("الرجاء تعبئة الحقول كاملة بشكل صحيح ");
            return;
        }

        try {
            console.log("Submitting Questions:", questions);
            let success = await onSubmit({ questions }); // Call the API submission logic
            if (success)
                setSuccessMessage("تم الحفظ بنجاح");
            else setErroreMessage("حدث خطأ أثناء الحفظ. الرجاء المحاولة مرة أخرى.");

        } catch (error) {
            setErroreMessage("حدث خطأ أثناء الحفظ. الرجاء المحاولة مرة أخرى.");
        }
    };

    return (
        <Layout>
            <ToastSuccessError
                ErrorMessage={errorMessage}
                successMessage={successMessage}
                setErrorMessage={setErroreMessage}
                setSuccessMessage={setSuccessMessage}
            />
            <div className="container all Add_traditional_quiz">
                <div className="mb-4">
                    <label htmlFor="numQuestions" className="form-label">
                        أدخل عدد الأسئلة :
                    </label>
                    <div className="d-flex align-items-center">
                        <input
                            type="number"
                            id="numQuestions"
                            className="form-control me-2"
                            style={{ maxWidth: "120px" }}
                            value={numQuestions}
                            min={1}
                            max={100}
                            onChange={(e) => setNumQuestions(Number(e.target.value))}
                        />
                        <button className="btn btn-primary me-2" onClick={generateQuestions}>
                            توليد الأسئلة
                        </button>
                        <button className="btn btn-secondary" onClick={addQuestion}>
                            إضافة سؤال جديد
                        </button>
                    </div>
                </div>
                <form onSubmit={handleFormSubmit} className="row g-3 needs-validation" noValidate>
                    {questions.map((question, index) => (
                        <QuestionRow
                            key={index}
                            index={index}
                            question={question}
                            updateQuestion={updateQuestion}
                        />
                    ))}
                    {questions.length > 0 && (
                        <div className="col-12 text-center">
                            <button
                                disabled={isLoading}
                                className={`btn btn-success my-5 ${isLoading ? "opacity-50" : ""}`}
                                style={{ minWidth: "120px" }}
                                type="submit"
                            >
                                حفظ
                                {isLoading && (
                                    <span
                                        className="spinner-border spinner-border-sm ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Layout>
    );
}
