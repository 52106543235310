import React, { useEffect, useState } from 'react';
// import Button from '../../components/button';
// import chalks from '../../assets/icons/chalks.png';
import './StudentDetails.css';
import Layout from '../../components/Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const StudentDetails = () => {

    const params = useParams();
    const [studentDetails,setStudentDetails] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_student_by_id/${params.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('student detais are',data.data.data)
            setStudentDetails(data.data.data);
        })
        .catch(error => {
            console.log(error);
        })
    },[]);


  return (
      <Layout>
    <div className='profileScreen'>
        <div className='profileScreen__content'>
            <div className='topcontent'>
                <div className='userImage'>
                    <img src={`${process.env.REACT_APP_Video_URL+studentDetails?.image}`} style={{borderRadius: '50%'}}></img>
                </div>
                <h3 className='userName'>{studentDetails?.name}</h3>
            </div>

            <div className={`bottomContent`}>

                <div className='leftContent'>
                    <ul>
                        <li><div>الاسم</div><div>{studentDetails?.username}</div></li>
                        <li><div>اسم الاب</div><div>{studentDetails?.father_name}</div></li>
                        <li><div>اسم الام</div><div>{studentDetails?.mother_name}</div></li>
                        <li><div>الجنس</div><div>{studentDetails?.gender}</div></li>
                        <li><div>تاريخ الميلاد</div><div>{studentDetails?.birthday}</div></li>
                        <li><div>العنوان</div><div>{studentDetails?.address}</div></li>
                        <li><div>المحافظة</div><div>{studentDetails?.city}</div></li>
                    </ul>
                    <div className='title'>
                        المعلومات الشخصية
                    </div>
                </div>

                <div className='rightContent'>
                <ul>
                        <li><div>الصف</div><div>{studentDetails?.class}</div></li>
                        <li><div>المدرسة</div><div>{studentDetails?.school}</div></li>
                        <li><div>رقم الهاتف</div><div>{studentDetails?.phone_number}</div></li>
                        {studentDetails?.email && <li><div>الايميل</div><div>{studentDetails?.email}</div></li>}
                    </ul>
                    <div className='title'>
                        معلومات الحساب
                    </div>
                </div>

            </div>

       
               
        </div>

        <div className='editBtn__containener'>
            <button 
            className="btn editTeacherInforBtn" 
            onClick={() => navigate(`/editstudentdetails/${studentDetails?.student_id}`)}>
                تعديل بيانات الطالب
            </button>
        </div>
    </div>
    </Layout>
  )


}

export default StudentDetails
