import axios from "axios";

export default async function fetchSuperiorsListSections({ queryKey }) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/superiors-sections/${queryKey[2]}/superiors?page=${queryKey[1]}`,
    {
      params: { limit: 10 },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );

  return data;
}
