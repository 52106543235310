import React, {useEffect, useState} from 'react' 
import { Link } from 'react-router-dom';
 import axios from 'axios';
 
export default function TableTeacherSubjects({data , isLoading,subjectId}) { 

 
     const [dataSubject , setDataSubject] = useState(data);
     const [flag , setFlag] = useState(0);


    //  if(dataSubject.length===0 && flag===1){
    //     window.location.reload();
    // }

    const handleDeleteSubject = (id)=>{
        console.log("idddddd subject" , id);
        const temp = [...dataSubject];
        const DataDelte= temp.filter(item=>item.teacher_id !== id);
        console.log("Data Deleteeed",DataDelte);
        setDataSubject(DataDelte);
        setFlag(1);
    } 

 
    useEffect(() => { 
        console.log('data from react query',data);  
        setDataSubject(data);   
    },[data]);   


   
    async function deletePost(id) {
        console.log("iddddd",id);

        // let Data={
        //     "subject_id":subjectId,
        //     "teacher_id":id
        // }
        // console.log("dataaaa",Data);

        const res = axios.delete(`${process.env.REACT_APP_API_URL}/unlink_subject_with_a_teacher`,{
            headers: {
              'content-type': 'text/json',
              "Content-Type": "multipart/form-data",
              "Accept": "application/json",
              'Content-Encoding': 'gzip , deflate, br',
              "Authorization":`Bearer ${localStorage.getItem('token')}`
            },
            data : {
              "subject_id":subjectId,
              "teacher_id":id
            }
          
          }).then(res=>{
            if(res.status===200){
              const {data} = res;
              console.log("data delete Subscribtion" , data);
              handleDeleteSubject(id);
              alert('تم الحذف بنجاح')
              return res;
            }
            else
            {
              console.log(res);
              throw new Error('there is an error')
            }
          }).catch(error=>{
            console.log(error.response)
          });
        
     }
        // setStatus('Delete successful');
     const  handleDelete = (id)=> {
        // DELETE request using fetch with async/await
        console.log("no id",id);
        deletePost(id);
        // handleDeleteSubject(id);
      }



    return ( 
    <> 
    <div className='overflow-auto w-100'>
        <table className='table border  mt-3table table-striped table-min-fit  table-hover shadow rounded border  mt-3' style={{minHeight: '400px'}}> 
            <thead> 
                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'> اسم الاستاذ</th> 
                <th className='text-center align-middle '> العمليات</th> 
               
                </tr> 
            </thead> 
            <tbody> 
 
                {isLoading ? <td colSpan={2} > 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                   dataSubject && dataSubject.length > 0 ? dataSubject?.map((item,index) => { 
                        return <tr key={index}> 
                        <td  className='text-center align-middle'>{item?.teacher_name} 
                        </td>                       
                        <td  className='text-center align-middle'> 
                              {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                              {/* <Link to={`/mainsubscribtion/${item?.subscription_id}`} className="ms-3 btn btn-primary">show</Link> */}
                              {/* <Link to={`/subscribe/${item?.subscription_id}`} className="ms-3 btn btn-success">edit</Link> */}
                              <button onClick={()=>handleDelete(item?.teacher_id)} className="ms-3 btn btn-danger">حذف</button> 

                              <Link to={`/subjects/${subjectId}/teachers/${item?.teacher_id}/chat/participants`} className="ms-3 btn btn-success">عرض الرسائل</Link>

                              </td> 
                         
                         
                    </tr> 
                    }) : <td colSpan={2} className='text-center align-middle'><h3>لايوجد اساتذه</h3> </td>
                }             
            </tbody> 
        </table> 
        
                </div>
     
    </> 
  ) 
}