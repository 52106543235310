import React, { memo } from "react";

const QuestionRow = ({ index, question, updateQuestion }) => {
    const handleQuestionChange = (value) => {
        updateQuestion(index, { ...question, questionText: value });
    };

    const handleNoteChange = (value) => {
        updateQuestion(index, { ...question, note: value });
    };

    const handleOptionChange = (optionIndex, value) => {
        const updatedOptions = question.options.map((opt, i) =>
            i === optionIndex ? value : opt
        );
        updateQuestion(index, { ...question, options: updatedOptions });
    };

    const handleCorrectOptionChange = (optionIndex) => {
        updateQuestion(index, { ...question, correctOption: optionIndex });
    };

    return (
        <div className="mb-4 p-3 border rounded bg-white shadow-sm">
            {/* Question Input */}
            <div className="row align-items-center mb-3">
                <div className="col-12">
                    <label htmlFor={`question-${index}`} className="form-label fw-bold text-end">
                        {`السؤال ${index + 1}`}
                    </label>
                    <input
                        readOnly={true}

                        type="text"
                        className="form-control text-end"
                        id={`question-${index}`}
                        value={question.questionText}
                        onChange={(e) => handleQuestionChange(e.target.value)}
                        placeholder="أدخل نص السؤال..."
                    />
                </div>
            </div>

            {/* Note Input */}
            <div className="row align-items-center mb-3">
                <div className="col-12">
                    <label htmlFor={`note-${index}`} className="form-label fw-bold text-end">
                        ملاحظة السؤال
                    </label>
                    <textarea
                        className="form-control text-end"
                        id={`note-${index}`}
                        value={question.note}
                        onChange={(e) => handleNoteChange(e.target.value)}
                        placeholder="أدخل ملاحظة السؤال..."
                        rows={2}
                    />
                </div>
            </div>

            {/* Options */}
            <div className="row align-items-center mb-3">
                {question.options.map((option, optionIndex) => (
                    <div className="col-3 d-flex align-items-center" key={optionIndex}>
                        <input

                            type="radio"
                            name={`correctOption-${index}`}
                            checked={question.correctOption === optionIndex}
                            onChange={() => handleCorrectOptionChange(optionIndex)}
                            style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                            }}
                        />
                        <input
                            type="text"
                            className="form-control text-end"
                            value={option}
                            readOnly={true}
                            onChange={(e) => handleOptionChange(optionIndex, e.target.value)}
                            placeholder={`الاختيار ${String.fromCharCode(65 + optionIndex)}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(QuestionRow);
