import React from 'react';
import Layout from '../../components/Layout/Layout';
import './UnAuthorized.css';
import logo from '../../assets/Home/logo.png';
import { useNavigate } from 'react-router-dom';



const UnAuthorized = (props) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return <Layout>
        <div className='container welcomAdmin__container'>
           <div className='top__content'>
            <h2 className='text-center' style={{color: '#1a1a1a'}}>عذرا , ليس لديك صلاحية الدخول لهذه الصفحة</h2>
           <img src={logo} alt="" />
           </div>

           <div className='buttonContainerBottom'>
            <button className='btn btn-primary px-5 pt-0 fs-5' onClick={goBack}>رجوع</button>
           </div>

           {/* <div className='bottom__content'>
                <div className='content'>
                <p>عزيزي الأدمن , لقد تم منحك مجموعة صلاحيات للتحكم في المنصة وتتضمن هذه الصلاحيات:  </p>
                <ul>
                    <li>صلاحية 1</li>
                    <li>صلاحية 2</li>
                    <li>صلاحية 3</li>
                </ul>
                </div>
           </div> */}
        </div>
    </Layout>
}

export default UnAuthorized;