import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import Image from '../Image/Image'
// import SubjectImage from './SubjectImage';
import record from "../../assets/Home/record.png";
import { useForm } from "react-hook-form";
import SubjectLogic from "./SubjectLogic";
import Layout from "../Layout/Layout";
// import Subjectvideo from './SubjectVideo';
import "./Subject.css";

import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import { Circle } from "rc-progress";
import { useQuery } from "react-query";
import fetchCoupons from "../../redux-toolkit/api/fetchCoupons";

const Subjectvideo = React.lazy(() => import("./SubjectVideo"));
const SubjectImage = React.lazy(() => import("./SubjectImage"));

export default function Subject({ handleAdd, subscribtionPage }) {
  const [success, setSuccess] = useState(false);
  const [faild, setFaild] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const { state } = useLocation();
  const parmas = useParams();
  const id = parmas?.id;

  const [idTeacher, setIdTeacher] = useState(null);
  const [subSectionId, setSubSectionId] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const [subjectData, setSubjectData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [subSections, setSubSetSections] = useState([]);
  const [SubSelectedSection, setSelectedSubSection] = useState([]);
  const [sections, setSections] = useState([]);

  const [defaultSelectSection, setDefaultSelectSection] = useState();
  const [defaultSelectSubSection, setDefaultSelectSubSection] = useState();

  useEffect(() => {
    // console.log('section_subSection',state?.sectionId);
    // console.log('Subsection_subSection',state?.subSectionId);
    // console.log('state insdie subject',)
    setSubSectionId(state?.sectionId);
    setSubSectionId(state?.subSectionId);

    const sectionsFromAPI = axios
      .get(
        `${process.env.REACT_APP_API_URL}/get_section_by_section_id/${state?.sectionId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        // console.log('selected sections',data.data.data)
        setDefaultSelectSection(data.data.data.section_name);
      });

    const subSectionsAPI = axios
      .get(
        `${process.env.REACT_APP_API_URL}/get_sub_section_by_sub_section_id/${state?.subSectionId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        // console.log('selected subsection  ', data.data.data);
        setDefaultSelectSubSection(data.data.data.sub_section_name);
      });
  }, []);

  const {
    reset,
    getValues,
    formState,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subject_name: "",
      subject_image: null,
      ponits: "",
      subject_description: "",
      introdeuctory_video: null,
      subject_req: "",
      price: "",
      coupon_id: "nothing",
      teacher_id: "",
      is_for_selling: 0,
      notify_name: "",
    },
  });

  const {
    valid,
    handleSubmitApi,
    handleUpdateApi,
    AddSubscribtion,
    progress,
    IsLoading,
    changeColor,
  } = SubjectLogic(
    subscribtionPage,
    id,
    reset,
    idTeacher,
    subSectionId,
    setSuccessMessage,
    setErrorMessage,
    setSuccess,
    setFaild
  );

  const { isLoading, isError, data, error } = useQuery(
    ["coupons"],
    fetchCoupons
  );

  // const [selectedTeacher , setSelectedTeacher]= useState(null)

  const handleSectionChange = (e) => {
    // console.log("selected sections id",e.target.value);
    setSelectedSection(e.target.value);
    // setSubSetSections([]);
  };

  const handleTeacherChange = (e) => {
    //  console.log("selected teacer  id sections",e.target.value);
    setIdTeacher(e.target.value);
  };

  const handleSubSectionChange = (e) => {
    // console.log(e.target.value);
    // console.log("selected  Sub Sections id",e.target.value);
    // e.target.value =
    setSelectedSubSection(e.target.value);
    setSubSectionId(e.target.value);
    // setSubject([]);
  };

  useEffect(() => {
    const sectionsFromAPI = axios
      .get(`${process.env.REACT_APP_API_URL}/get_all_sections?limit=19`)
      .then((data) => {
        // console.log('log',data.data.data)
        setSections(data.data.data);
      });
  }, []);

  useEffect(() => {
    // console.log('section changed')
    // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
    const teachers = axios
      .get(`${process.env.REACT_APP_API_URL}/get_all_teachers?limit=19`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        // console.log('teachers DATA', data?.data?.data);
        setTeachers(data?.data?.data?.data);
      });
  }, [selectedSection]);

  useEffect(() => {
    // console.log('section changed')
    // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
    const subSectionsAPI = axios
      .get(
        `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}?limit=19`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log("subsection ", data.data.data);
        setSubSetSections(data.data.data.data);
      });
  }, [selectedSection]);

  useEffect(() => {
    if (id) {
      const sectionsFromAPI = axios
        .get(
          `${process.env.REACT_APP_API_URL}/get_subject_by_subject_id/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          setSubjectData(data.data.data);
          reset(data.data.data);
          setIdTeacher(data.data.data.teacher_id);
          if (data.data.data.coupon_id == null) {
            setValue("coupon_id", "nothing");
          }
        });
    }
  }, []);

  const tempId = id || 0;

  return (
    <Layout>
      {IsLoading && (
        <div className={`uploadPrgressbar`}>
          <h2>
            {tempId != 0
              ? "جار اضافة المادة للاشتراك..."
              : "جاري إضافة المادة..."}{" "}
          </h2>

          <div
            className="content"
            style={{ dispaly: typeof progress != "number" ? "none" : "flex" }}
          >
            {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
            <p>{progress}%</p>
            <Circle
              percent={progress}
              strokeWidth={4}
              strokeColor={changeColor(progress)}
            />
          </div>
        </div>
      )}

      <div className="container">
        <ToastSuccessError
          successMessage={successMessage}
          ErrorMessage={ErrorMessage}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />

        <form onSubmit={handleSubmit(handleSubmitApi)}>
          <div className="mb-3 pt-5">
            <div className="mb-3 d-flex align-items-center gap-5 pt-5">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> صورة الماده </label>
            </div>
            <SubjectImage
              valid={valid}
              register={register}
              id={id}
              subscribtionPage={subjectData}
              AddSubscribtion={AddSubscribtion}
              errors={errors}
            />
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> اسم الماده </label>
            </div>
            <div className="d-flex ">
              <input
                type="text"
                className="shadow w-50 py-2 border-0"
                {...register("subject_name", { required: true })}
              />
              {errors.subject_name && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> اسم المادة في الاشعار </label>
            </div>
            <div className="d-flex ">
              <input
                type="text"
                className="shadow w-50 py-2 border-0"
                {...register("notify_name", { required: true })}
              />
              {errors.notify_name && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> نقط الماده </label>
            </div>
            <div className="d-flex ">
              <input
                type="number"
                className="shadow w-50 py-2 border-0"
                {...register("points", { required: true })}
              />
              {errors.ponits && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> وصف عن الماده </label>
            </div>
            <textarea
              type="text"
              className="shadow w-100 main-input border-0"
              {...register("subject_description", { required: true })}
            />
            {errors.subject_description && (
              <p className="text-danger">*required</p>
            )}
          </div>

          {/* video */}
          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center gap-5">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> فيديو الماده </label>
            </div>

            <Subjectvideo
              valid={valid}
              register={register}
              id={id}
              subscribtionPage={subjectData}
              AddSubscribtion={AddSubscribtion}
              errors={errors}
            />
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> متطلبات الماده </label>
            </div>
            <div className="d-flex ">
              <input
                type="text"
                className="shadow w-50 py-2 border-0"
                {...register("subject_req", { required: true })}
              />
              {errors.subject_req && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> هل هذا القسم للبيع ؟ </label>
            </div>

            <div className="d-flex ">
              <select
                class="form-select"
                {...register("is_for_selling", { required: true })}
                aria-label="Default select example"
              >
                {/* <option value={null}>كوبون الخصم (اختياري)</option> */}
                <option value={0}>لا</option>
                <option value={1}>نعم</option>
              </select>
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> (اختياري) كوبون الخصم </label>
            </div>
            <select
              class="form-select"
              {...register("coupon_id", { required: false })}
              aria-label="Default select example"
            >
              <option value={"nothing"}>كوبون الخصم (اختياري)</option>
              {data?.data?.data?.map((coupon) => {
                return (
                  <option value={coupon.id}>
                    نسبة الخصم {coupon.discount_percentage}% الصلاحية{" "}
                    {coupon.expires_at}
                  </option>
                );
              })}
            </select>
          </div>

          {/*  select teacher  */}

          <form className="subject">
            <div className="selects__container">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p>اختر قسم</p>
                <select
                  onChange={(e) => handleSectionChange(e)}
                  className="form-select customSelect"
                  aria-label="Default select example"
                >
                  <option selected>{defaultSelectSection}</option>
                  {sections &&
                    sections?.map((section, index) => (
                      <option key={index} value={section.section_id}>
                        {section.section_name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <p>اختر دوره</p>
                <select
                  onChange={(e) => handleSubSectionChange(e)}
                  className="form-select  customSelect"
                  aria-label="Default select example"
                >
                  <option selected>{defaultSelectSubSection}</option>
                  {subSections &&
                    subSections?.map((subsection, index) => (
                      <option key={index} value={subsection.sub_section_id}>
                        {subsection.sub_section_name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <p>اختر استاذ</p>

                <select
                  onChange={(e) => handleTeacherChange(e)}
                  className="form-select  customSelect"
                  aria-label="Default select example"
                >
                  <option selected>الرجاء اختيار استاذ المادة</option>
                  {teachers &&
                    teachers.length > 0 &&
                    teachers?.map((teacher, index) => (
                      <option
                        key={index}
                        value={teacher.teacher_id}
                        selected={teacher.teacher_id == idTeacher}
                      >
                        {teacher.teacher_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* <button type='submit' className='btn infos px-3 py-2' onClick={handleAddSubject}>إضافة</button> */}
          </form>

          <div className="mb-3">
            <div className="mb-3 d-flex align-items-center">
              <img src={record} className="record mx-2" />
              <label className="fw-bold"> السعر </label>
            </div>
            <div className="d-flex ">
              <input
                type="number"
                className="shadow w-50 py-2 border-0"
                {...register("price", { required: true })}
              />
              {errors.price && <p className="text-danger">*required</p>}
            </div>
          </div>

          <div className="d-flex justify-content-center mb-5">
            {!IsLoading && tempId ? (
              <button
                className=" btn btn-success"
                // onClick={()=>handleUpdateApi(getValues())}
              >
                <h6>تعديل </h6>
              </button>
            ) : null}

            {!IsLoading && !tempId ? (
              <button className=" info  py-2 px-5 border-0">
                <h6>إضافة </h6>
              </button>
            ) : null}

            {IsLoading ? (
              <div className="mb-3 d-flex justify-content-center">
                <div class="spinner-border mx-auto" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : null}
          </div>

          {/* <h1>{formState.errors?.content }</h1> */}
        </form>
      </div>
    </Layout>
  );
}
