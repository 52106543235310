import axios from 'axios';
import { Circle } from 'rc-progress';
import React, {useState,useEffect, useRef} from 'react';
import { useForm } from 'react-hook-form';
import Image from '../../components/Image/Image';
import Layout from '../../components/Layout/Layout';
import './EditLibraryBook.css';
import record from '../../assets/Home/record.png';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';

const EditLibraryBook = (props) => {


    const [progress,setProgress] = useState(0);
    const [IsLoading,setIsLoading] = useState(false);
    const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
    const [imageChanged,setImageChnaged] = useState(false);    
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const [bookDetails,setBookDetails] = useState();
    const navigate = useNavigate();
    const [librarySections,setLibrarySections] = useState();

    const unOrderStudentsList = useRef();

    const [isSuccess,setIsSuccess] = useState(false);

    const [defauluseFormVlaues,setDefauluseFormValues] = useState({
        title: '' ,
        website_library_section_id:  '',
        transable: 0,
        pdf_link: '',
 });

 const params = useParams();

 
 const { reset,getValues,watch,setValue ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
 useForm({mode: "onChange",defaultValues: defauluseFormVlaues});


 useEffect(() => {
     setImageChnaged(true);
 },[watch('image')]);

 useEffect(() => { 
     axios.get(`${process.env.REACT_APP_API_URL}/get_book_by_id/${params.id}`,
     {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
     .then(data => {

        //  console.log('book data',data.data.data)
            setBookDetails(data.data.data);

         const bookDetails = data.data.data;

         setValue('title',bookDetails.title);
    
         setValue('pdf_link',bookDetails.pdf_link);
         setValue('website_library_section_id',bookDetails.website_library_section_id);
     })
     .catch(error => console.log(error));


     ////////////////////////////////////////////////////////////////////

     axios.get(`${process.env.REACT_APP_API_URL}/get_website_library_sections?limit=19`)
     .then(data => {
        // console.log('data sections',data.data.data.data);
        setLibrarySections(data.data.data.data);
     })
     .catch(error => {
        // console.log('error while fetch library sections',error);
     })


},[]);

 const handleOnSubmit = (data) => {
     // e.preventDefault();
    //  console.log('data',data)
    //  console.log("submitting data to server");

     setIsLoading(true);

     const ourFormData = new FormData();

     ourFormData.append('title',data.title);

     ourFormData.append('transable',data.transable);
     ourFormData.append('website_library_section_id',data.website_library_section_id);

     if(typeof getValues().pdf_file != 'string'){
        // console.log('gogogo');
         ourFormData.append('pdf_link',data.pdf_link[0]);
     }


     for(var pair of ourFormData.entries()) {
         console.log(pair[0]+ ', '+  pair[1]); 
         // console.log('pair',pair);
    }

     axios.post(`${process.env.REACT_APP_API_URL}/update_website_library_section_book_by_id/${params.id}?_method=PUT`, ourFormData, {

     headers: {
       "Content-Type": "multipart/form-data",
       "Accept": "application/json",
       'Content-Encoding': 'gzip , deflate, br',
       'content-type': 'text/json',
       "Content-Type": "multipart/form-data",
       "Authorization": `Bearer ${localStorage.getItem('token')}`
     },
 
     onUploadProgress: (progressEvent) => {
       const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    //    console.log(progress);
    //    setProgress(progress);
     },
    //  onDownloadProgress: (progressEvent) => {
    //    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 100;
    //    console.log(progress);
    //    setProgress(progress);
    //  },
   }).then(res=>{
     if(res.status === 200)
     {
       const {data} = res;
    //  console.log('data from post api' , data);
     setIsLoading(false)
     setIsUploadSuccessfuly(true);
     setIsSuccess(true);
     setSuccessMessage("تم تعديل معلومات الكتاب بنجاح")
     
     setTimeout(() => {
        navigate(-1);   
     }, 1000);

     setTimeout(() => {
         setIsSuccess(false);    
     }, 4000);

       return res;
     }
     else
     {
        setErrorMessage("حدث خطأ ما");
    setIsLoading(false);
    //    console.log(res);
       throw new Error('there is an error')
     }
   }).catch(error=>{
      setIsLoading(false);
      if(error.response.data.message){
          setErrorMessage(error.response.data.message);
        }else {
          setErrorMessage('حدث خطأ ما');
      }
    //  console.log(error.response)
     //  setIsError(error.response.data)
   }
     );
   }

   useEffect(() => {
     if(progress == 100) {
         setIsLoading(false);
     }
   },[progress]);

   
   const changeColor = (progress) => {
     if(progress <= 25){
         return '#ff0707';
     }else if(progress <= 50){
         return '#ff6907';
     }else if(progress <= 75){
         return '#ffdb07';
     }else {
         return '#77ff07';
     }
   }

    return <div className='librarySectionPage'>

            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

        {
           IsLoading && <div className={`uploadPrgressbar`}>
            <h2>جار تعديل معلومات الكتاب ... </h2>
            
            <div className='content'>
                {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
                <p>{progress}%</p>
                <Circle percent={progress} strokeWidth={4} strokeColor={changeColor(progress)} /> 
            </div>
        </div>
        }
        <Layout>


        <form onSubmit={handleSubmit(handleOnSubmit)}>
    <div className='container all'>
        <div className='mb-5'>
            <div className='mb-2 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> عنوان الكتاب</label>
            </div>
           <input type='text' {...register("title", { required: true })  } className='shadow w-100 main-input border-0'/>
           {/* {errors.junior_certificate && <p className='text-danger'>*required</p>} */}
        </div>

        <div className='mb-5 '>
            <div className='mb-2 d-flex align-items-center gap-2'>
                <img src={record} className='record'/>
                <label className='fw-bold'>القسم الذي يحتوي على هذا الكتاب</label>
            </div>

            <select {...register("website_library_section_id", { required: true })  } class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">

                    {
                        librarySections && librarySections.map((librarySection,index) => {
                                return <option value={librarySection.id}>{librarySection.name}</option>
                        })
                    }

            </select>
        </div>



        <div className='mb-5 '>
            <div className='mb-2 d-flex align-items-center gap-2'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> ملف الكتاب </label>
            </div>
            <RegisterImage  register={register} isUploadSuccessfuly={isUploadSuccessfuly}  registerWhat={"pdf_link]"} isRequired={false}    Accept={'pdf'}/>
        </div>

        {
            watch('pdf_link').toString().includes('books_files') && 
            <iframe src={watch('pdf_link').toString().includes('books_files') ? process.env.REACT_APP_Video_URL + watch('pdf_link') : null} style={{borderRadius: '20px'}} height="400" width="100%"></iframe>
        }
        {/* <iframe style={{overflow: 'scroll'}} src={watch('pdf_link')} title="title">
     Presss me: <a href="./resources/crayola.pdf">Download PDF</a>
    </iframe> */}
        </div>
          


        {
          IsLoading ? <div className='mb-3 d-flex justify-content-center mt-5' >
          asdfasdfadsf
                    <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  </div>
                  : 
            <button type='submit' className="btn btn-primary infos px-3 py-2 mt-5">تعديل معلومات الكتاب</button>
        }

        </form>

        
        </Layout>
    </div>
}

export default EditLibraryBook;