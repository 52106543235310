import React from "react";
import Message from "./Message";

function PopupDelete({
  onClick,
  onClickFalse,
  type,
  setViewFile,
  viewFile,
  setClosefile,
  sendMessage,
  setAudioBlob,
}) {
  return (
    <div className="popupDelete ">
      <div className=" popupDelete_1 col-sm-4 flex-col ">
        {type === "file" ? (
          <>
            <div className="  mt-3 fs-4 d-flex justify-content-center ">
              <img
                src={viewFile}
                alt=""
                className="rounded-circle border border-warning img"
                style={{
                  width: "70px",
                  height: "70px",
                  marginLeft: "12px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div className="col-12 d-flex justify-content-between ">
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger w-50"
                  onClick={() => {
                    setClosefile(false);
                    sendMessage(viewFile, true, "image");
                  }}
                >
                  نعم{" "}
                </button>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger w-50"
                  onClick={() => {
                    setClosefile(false);
                    setViewFile("");
                  }}
                >
                  لا{" "}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="  mt-3 fs-4 ">
              <p className="text-center"> هل انت متأكد من حذف التسجيل</p>
            </div>
            <div className="col d-flex justify-content-between mt-4">
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger w-50"
                  onClick={() => {
                    onClick(null);
                    onClickFalse();
                    setAudioBlob(null);
                  }}
                >
                  نعم{" "}
                </button>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger w-50"
                  onClick={() => onClickFalse()}
                >
                  لا{" "}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PopupDelete;
