

import axios from "axios";
export default async function  fetchBankQuizes ({queryKey})
{

          
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/bank-quizzes?search=${queryKey[2]}&page=${queryKey[1]}` ,
          {params :{limit:19}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


