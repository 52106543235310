import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchSubSectionsApi from "../../redux-toolkit/api/fetchSubSections";

const ShowSubSectionsLogic = (id) => {
    const [page,setPage] =useState( +localStorage.getItem('subsections_pag') == null ? 1 : Number(+localStorage.getItem('subsections_pag')) );
    console.log("nooooooo");
    const {isLoading, isError, data, error} = useQuery(['SubSections',page , id], fetchSubSectionsApi);   
    
    function handlePageClick (data)
    {  
        localStorage.setItem('subsections_pag' , data.selected)
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page
    }
}

export default ShowSubSectionsLogic