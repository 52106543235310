import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Layout from '../../components/Layout/Layout';
import './index.css'
import ShowExamQALogic from './ShowExamQALogic';

export default function ShowExamQA({corrected}) {

  const {isSuccess,IsLoading,IsError,data ,Get_Qustions_Quiz,qustions_Quiz_IsLoading,qustions_Quiz_IsError,qustions_Quiz_data
    ,updatefaqs,register,onSubmit,errors,isDirty,isValid,handleSubmit,examDetails,    isCorrecting,
    successCorrecting
  } =  ShowExamQALogic();

  const params = useParams();
  useEffect(() => {
    // console.log('yes '.repeat(100));
    // console.log( 'question quiz data' , data);
    // console.log('noooooooooooo'.repeat(50));
    // console.log('params pa pa pa ', params);
    // console.log('state is state is ', state);

    // axios.get(`${process.env.REACT_APP_API_URL}/get_corrected_unit_traditional_quizzes_by_student_id?student_id=${params.QuizId}`,
    // {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
    // .then(data => {
    //   console.log('q q q q q ' , data);
    // })
    // .catch(erro => {

    // })

    // setInterval(() => {
    //     console.log('data dddddd',qustions_Quiz_data);
    // }, 800);
  },[])

  const {state} = useLocation();
  
  return (
    <Layout>
   <div className='container Show_Asnwear_Unit_Tranditiosnal all'>

<div className="Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center">
    <h3 className="Show_Asnwear_Unit_Tranditiosnal-title mx-auto">
    أجوبة الطالب
    </h3>
   
    <div className="container p-2 my-2">
        <div id="carouselExampleIndicators" className=" Show_Asnwear_Unit_Tranditiosnal_carousel carousel  slide w-75 mx-auto my-4  position-relative    "  data-bs-ride="carousel">

            <div className="carousel-indicators">
            {examDetails && examDetails?.answers?.map((source,index)=>{
      return  (<button key={uuid()} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={` ${index === 0 ? 'active' : ''} `} aria-current="true" aria-label={`Slide ${index+1}`}></button>)
    })}
            </div>
            <div className="carousel-inner ">
            {examDetails && examDetails?.answers?.map((source,index)=>{
      return  (  <div className={`carousel-item  ${index===0 ? 'active' : ''} `}>
      <a rel="noreferrer" target='_blank' href={`${process.env.REACT_APP_Video_URL}${source}`}>
          <img src={` ${process.env.REACT_APP_Video_URL}${source}`} className="d-block w-100" alt="..."/>
      </a>
      </div>)
    })}

            </div>

            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
            </button>

            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
            </button>
           
        </div>
    </div>
</div>
   




<div className="Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center">
    <h3 className="Show_Asnwear_Unit_Tranditiosnal-title mx-auto">
    أسئلة الاختبار 
    </h3>
   
    <div className="container p-2 my-2">
    <iframe src={ process.env.REACT_APP_Video_URL + examDetails?.questions} height="500" width="100%"></iframe>

        {/* <div id="carouselExampleIndicators" className=" Show_Asnwear_Unit_Tranditiosnal_carousel mb-5 carousel  slide w-75 mx-auto my-4  position-relative    "  data-bs-ride="carousel">

            <div className="carousel-indicators">
            {examDetails && examDetails?.questions?.map((source,index)=>{
      return  (<button key={uuid()} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={` ${index === 0 ? 'active' : ''} `} aria-current="true" aria-label={`Slide ${index+1}`}></button>)
    })}
            </div>
            <div className="carousel-inner ">
            {examDetails && examDetails?.questions?.map((source,index)=>{
      return  (  <div className={`carousel-item  ${index===0 ? 'active' : ''} `}>
      <a rel="noreferrer" target='_blank' href={`${process.env.REACT_APP_STORAGE_URL}${source.file_link}`}>
          <img src={` ${process.env.REACT_APP_STORAGE_URL}${source.file_link}`} className="d-block w-100" alt="..."/>
      </a>
      </div>)
    })}
                  
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
            </button>
           
        </div> */}

        {/* <div className="mt-5"/>
        <div className="mt-5"/> */}
        { 
          (examDetails?.traditional_exam?.type == 'BOTH' ||  examDetails?.traditional_exam?.type == "AUTOMATED") && 
        <h4 className='w-100 mt-3 text-center shadow p-3 border rounded'>

        حصل في القسم المؤتمت على : <strong>{examDetails?.automated_section_result}</strong>
        </h4>
        }

        {!!examDetails?.deserved_mark && <h3 className="w-100 text-center shadow p-3 border rounded">العلامة المعطاة : {examDetails?.deserved_mark}</h3>}
        
        <div className="mt-3"/>
         { examDetails?.teacher_notes && <h3 className="w-100 text-center shadow p-3 border rounded">الملاحظة : {examDetails?.teacher_notes}</h3>}
    </div>
</div>


{!examDetails?.corrected&&
<>

     {/* {
        post_correction_IsError&& <div className='alert alert-danger' role='alert'>{post_correction_IsError?.response?.data?.message ? post_correction_IsError?.response?.data?.message : post_correction_IsError.message  }</div>
     } */}
      <div className='  py-5 '>

      
       <div className='d-flex justify-content-center'>
          <div className='card shadow  py-4  login-card'>
            <form onSubmit={handleSubmit(onSubmit)}>
    <div className='mb-3'>
              <div className='mb-3'>
                  <label className='fw-bold'>ملاحظة</label>
              </div>
             <input   {...register("teacher_notes")}  type='text' className='border rounded shadow w-100 main-input border-0' />
             {errors.teacher_notes&&<label className='text-center w-100 text-danger'>Invalid input</label>}
  </div>
  
  < div className='mb-3'>
              <div className='mb-3'>
             
                  <label className='fw-bold'>العلامة المستحقة</label>
              </div>
             <input  {...register("deserved_mark",{ required: true })} type='number' className='shadow w-100 main-input border-0'/>
             {errors.deserved_mark&&<label className='border rounded text-center w-100 text-danger'>Invalid input</label>}
  </div>
  <div className='mb-3'>
  </div>
          {/* {
        post_correction_IsLoading && <div className='d-flex mb-3'><div class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div></div>
      } */}
          <div className='d-flex'>
            {!isCorrecting ? 
          <button 
          disabled={!isDirty || !isValid} 
          type='submit' 
          className='login-button m-auto w-50 rounded text-white fw-bold py-2' 
          style={{cursor: 'pointer'}}  
          >
             {examDetails?.is_corrected == 0 ? 'إرسال النتيجة' : 'تعديل النتيجة'}
        </button>
            
            : 
            <div className='w-100 text-center'>
            <div className='d-flex mb-3 text-center'><div class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div></div>
            </div>
}



          </div>
          </form>
          </div>
          </div>
          {successCorrecting && <div class="alert alert-success text-center" role="alert">
 تهانينا تم تصحيح الاختبار بنجاح</div>} 
  
      </div>
</>}




   
   
   </div>
   </Layout>
  )
}
