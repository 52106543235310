import React, { useState, useRef, useEffect } from "react";
import user from "../../../assets/Chat/icons8-download.gif";
import { FaPlay, FaPause } from "react-icons/fa";
import { Line } from "rc-progress";
import ReactAudioPlayer from "react-h5-audio-player";

function AudioPlayer({ audioBlob, url = null }) {
  const [blobUrl, setBlobUrl] = useState("");

  useEffect(() => {
    if (!audioBlob || url) return;

    const blobUrl = URL.createObjectURL(audioBlob);
    setBlobUrl(blobUrl);

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [audioBlob]);

  return (
    <ReactAudioPlayer
      autoPlay={false}
      src={url ?? blobUrl}
      onPlay={(e) => {
        const audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
          if (audio !== e.target) {
            audio.pause();
          }
        });
      }}
      showJumpControls={false}
      showFilledProgress={false}
      autoPlayAfterSrcChange={false}
      className="audio-player "
      style={{ paddingTop: "25px" }}
    />
  );
}

export default AudioPlayer;
