import React , {useState} from 'react'
import { Link } from 'react-router-dom';
import ExamsTable from '../../components/ConfirmRequest/ExamsTable';
import TableSections from '../../components/ConfirmRequest/TableSections';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png'
import RegisterOrdersTable from '../../components/ConfirmRequest/RegisterOrdersTable';
import { useQuery } from 'react-query';
import fetchRegisterOrders from '../../redux-toolkit/api/fetchRegisterOrders';
import { filter } from 'lodash';


const RegisterOrders = () => {


//   console.log("YESSSSSSSSSS");
    // const  {listRef,isLoading, isError, data, error,handlePageClick,page ,setPage} = ExamsLogic();
    // console.log("sections Data" ,data);
    // console.log("current page" , page);
    
    // const dataSections = data?.data;
    // const [status,setStatus] = useState(0);
    // const {sectionId,subjectId} = useLocation().state;

    const [page ,setPage] = useState(0);

    const [filters,setFilters] = useState([
        {
            value: 'all',
            name: 'جميع الطلبات'
        },
        {
            value: 1,
            name: 'الطلبات قيد الانتظار'
        },
        {
            value: 2,
            name: 'الطلبات المقبولة'
        },
        {
            value: 3,
            name: 'الطلبات المرفوضة'
        },
    ]);

    const [selectedFilter,setSelectedFilter] = useState('all');

    const {data , isLoading , isError} = useQuery(['register_orders', selectedFilter ,page], fetchRegisterOrders)
   
    function handlePageClick (data){  
     setPage(data.selected+1);
    }

    return (
      <>
      <Layout>
          <div className='container all'>

          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >طلبات التسجيل
          </h1>

          {/* <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
              <h5 className='gre'> أضف مذاكرة</h5> 
              <NavLink to={`/addTraditionalExam/${sectionId}`}>
              <img src={group} className="me" />
              </NavLink>
        </div> */}

              <div className='d-flex justify-content-around'>     
              <select className='form-control' onChange={(e) => setSelectedFilter(e.target.value)}>
                {
                    filters.map((filter) => {
                        return <option value={filter.value}>{filter.name}</option>
                    })
                }
              </select>      
              </div>
  
              {
                       <RegisterOrdersTable 
                       data={data}
                       isLoading={isLoading}
                       />
              } 
  
                   { <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
  
                 
              </div>
      </Layout>

      </>
    )
}

export default RegisterOrders;
              
              
    