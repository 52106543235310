import React, {useEffect, useRef, useState} from 'react';
import { useQuery } from 'react-query';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import fetchGuirdians from '../../redux-toolkit/api/fetchGuirdians';
import GuardiansTable from '../../components/ConfirmRequest/GuardiansTable';
import './Guirdians.css';
import { NavLink } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png'
import { debounce } from 'lodash';

const Guirdians = (props)=> {

  const [page,setPage] =useState( +localStorage.getItem('guardian_pag') == null ? 1 : Number(+localStorage.getItem('guardian_pag')) );

  const [query,setQuery] = useState('');
  const {isLoading, isError, data, error} = useQuery(['guardians',page,query], fetchGuirdians);

  const unOrderStudentsList = useRef();
  const inputSearchRef = useRef();
  const [searchResult,setSearchResult] = useState('');

  const handleSearchStudent = (e) => {
        
    unOrderStudentsList.current.style.height = 'auto';
    setQuery(e.target.value);

    handleUnorderStudentsList(e.target.value);

    // setValue('student_id',e.target.value);
}

const devouncedFn = debounce(handleSearchStudent , 700);

const handleUnorderStudentsList = (searchQuery) => {
  if(searchQuery == '') {
      unOrderStudentsList.current.style.height = '0px';
  }else {
      unOrderStudentsList.current.style.height = 'auto';
  }
}

    function handlePageClick (data)
    {
        localStorage.setItem('guardian_pag' , +data.selected)
        setPage(data.selected+1);
    }

    return <Layout>
          <div className='container all'>  

          <h1 
          className='pt-0 mb-1 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >أولياء الأمور
          </h1>

          <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
              <h5 className='gre'> أضف ولي أمر</h5> 
              <NavLink to='/addguardian'>
              <img src={group} className="me" />
              </NavLink>
          </div>

          <input 
          type='text' 
          ref={inputSearchRef} 
          placeholder='يمكنك البحث من خلال الاسم او اسم المستخدم او الايميل لولي الامر او من خلال اسم أو ايميل أو اسم المستخدم لأحد أبنائه' 
          onChange={(e) => devouncedFn(e)} className='shadow w-100 main-input border-0'/>
           
           <div className='students_select mb-2 rounded'>   
               <ul className='unorder_students_list' ref={unOrderStudentsList} >
                       {
                           searchResult && searchResult?.map((student,index) => {
                               return   <li 
                                      //  onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                       value={student.student_id} 
                                       key={index}>
                                           {student.name}
                                       </li>
                           })
                       }
                   </ul>

                    
       {/* <div className='text-center my-5'>
            <button className='btn btn-primary' onClick={handleAddStudent}>اضف طالب</button>
       </div> */}


           </div>

        <div className='d-flex justify-content-around mb-5'>


        {
          <GuardiansTable data={data?.data} isLoading={isLoading}/>
        }

          </div>
          {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
          
            


        </div>

  
    </Layout>
}

export default Guirdians;