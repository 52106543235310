import React, { useRef, useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import record from "../../assets/Home/record.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Circle } from "rc-progress";
import RegisterImage from "../../components/RegisterImage/RegisterImage";
import { useNavigate, useParams } from "react-router-dom";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import { useSelector } from "react-redux";
import "./EditAdmin.css";

export default function EditAdmin() {
  const userData = useSelector((state) => state.Login);
  const [mainVideoFile, setMainVideoFile] = useState("");
  const [aboutUsImage, setAboutUsImage] = useState("");
  const [allSiteInfo, setAllSiteInfo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [defauluseFormVlaues, setDefauluseFormValues] = useState({
    password: "",
    role_id: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [data,setData] = useState({});
  const {
    reset,
    getValues,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange", defaultValues: defauluseFormVlaues });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_admin_by_id/${params.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        console.log("admin data", data.data.data);

        const adminDetails = data.data.data;
        setData(adminDetails)
        setValue("username", adminDetails.password);
        setValue("role_id", adminDetails.role_id);
        setValue("name", adminDetails.name);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleOnSubmit = (data) => {
    // e.preventDefault();
    console.log("data", data);
    console.log("submitting data to server");
    setIsLoading(true);
    const ourFormData = new FormData();

    ourFormData.append("password", data.password);
    ourFormData.append("role_id", data.role_id);
    ourFormData.append("name", data.name);

    for (var pair of ourFormData.entries()) {
      console.log(pair[0] + " , " + pair[1]);
      // console.log('pair',pair);
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/update_admin_by_id/${params.id}`,
        { password: data.password, role_id: data.role_id, name: data.name },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },

          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log(progress);
            setProgress(progress);
            setIsLoading(true);
          },
          onDownloadProgress: (progressEvent) => {
            const progress =
              50 + (progressEvent.loaded / progressEvent.total) * 100;
            console.log(progress);
            setProgress(progress);
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          console.log("data from post api", data);
          setIsLoading(false);
          setIsUploadSuccessfuly(true);
          setIsSuccess(true);

          setSuccessMessage("تم تعديل معلومات الادمن بنجاح");

          setTimeout(() => {
            navigate(-1);
          }, 1200);

          setTimeout(() => {
            setIsSuccess(false);
          }, 4000);
          return res;
        } else {
          setIsLoading(false);
          setErrorMessage("غير متصل بالشبكة");
          console.log(res);
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setErrorMessage(error.response.data.message);

        console.log(error.response);
        //  setIsError(error.response.data)
      });
  };

  useEffect(() => {
    if (progress == 100) {
      setIsLoading(false);
    }
  }, [progress]);

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };
  

  return (
    <>
      <Layout>
        <div className="container all editAdminContainer">
          <ToastSuccessError
            successMessage={successMessage}
            ErrorMessage={ErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
          <form onSubmit={handleSubmit(handleOnSubmit)} className="w-100">
            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> الاسم</label>
              </div>
              <input
                type="text"
                defaultValue={data.name}
                
                {...register("name", { required: true })}
                className="shadow w-100 main-input border-0"
              />
              {
                errors.name && (
                  <p className="text-danger">*هذا الحقل مطلوب</p>
                )
              }
            </div>
            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> كلمة السر الجديدة</label>
              </div>
              <input
                type="text"
                {...register("password", { required: true })}
                className="shadow w-100 main-input border-0"
              />
              {errors.password && (
                <p className="text-danger">*هذا الحقل مطلوب</p>
              )}
            </div>

            <div className="mb-3">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold"> نوع الأدمن</label>
              </div>
              <select
                type="text"
                {...register("role_id", { required: true })}
                className="shadow w-100 border-0 p-2"
              >
                <option value="4">إدارة المحتوى</option>
                <option value="5">إدارة الأمور المالية</option>
                <option value="6">إدارة المستخدمين</option>
                <option value="7">آدمن رئيسي </option>
                <option value="8">إدارة المذاكرات</option>
                <option value="9">مشرف المتابعة </option>
                <option value="10">الدعم الفني</option>

              </select>
              {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
            </div>

            <div className="d-flex justify-content-center mt-5">
              {!IsLoading ? (
                <button
                  disabled={IsLoading}
                  className=" btn infos px-3 py-2 border-0 bg-primary"
                  type="submit"
                >
                  <h6>تعديل بيانات الأدمن </h6>{" "}
                </button>
              ) : (
                <div className="mt-2 d-flex justify-content-center">
                  <div class="spinner-border mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}
