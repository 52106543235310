import {  useState } from 'react';
import { useQuery } from "react-query";
import fetchBankQuizes from '../../redux-toolkit/api/fetchBankQuizes';

export default function ShowallautomatedfinalQuizLogic(querySearch) {

    const [page,setPage] = useState(0);

    const {isLoading, isError, data, error,refetch} = useQuery([`bank-quizes_${querySearch}_${page}`,page,querySearch], fetchBankQuizes);

      return {isError, data,isLoading,page,setPage,refetch}
}


