

import axios from "axios";

export default async function  fetchAutomatedQuizes ({queryKey})
{
   
          
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/bank-quizzes?for_exams=1&page=${queryKey[1]}` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 





