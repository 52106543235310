

import axios from "axios";
export default async function  fetchOpenUnitsForStudent ({queryKey})
{
    console.log("units for student " , queryKey);
    console.log("process",process.env.REACT_APP_API_URL)
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_student_subscribed_units_by_student_id/${queryKey[1]}}` ,
    {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
    );
          return data;
} 


