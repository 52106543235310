import React, { useEffect, useRef , useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import Error from "../../../alerts/Error/Error";
import Add_Automated_Quiz_Quistions_For_Lesson_Logic from "./AddQuizBankQuestionLogic";
import ReactQuill from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
import uuid from "react-uuid";
import Layout from "../../components/Layout/Layout";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
window.katex = katex;

export default function AddQuizBankQuestion() {
  const {
    register,
    errors,
    isDirty,
    isValid,
    onSubmit,
    fields, 
    data,
    watch,
    handleSubmit,
    question,
    firstOption,
    secondOption,
    thirdOption,
    fourthOption,
    isLoading,
    errorMessage,
    successMessage,
    setErroreMessage,
    setSuccessMessage,
  } = Add_Automated_Quiz_Quistions_For_Lesson_Logic();


  const [ar,setAr] = useState([1,2,3,4]);
  // useEffect(() => {
  //   // console.lot('state is', state)
  // },[state])


  const optionsArr = [
    {
      index: 0 ,
      optionRef: firstOption 
    },
    {
      index: 1 ,
      optionRef: secondOption 
    },
    {
      index: 2 ,
      optionRef: thirdOption 
    },
    {
      index: 3 ,
      optionRef: fourthOption 
    },
  ]

  return (
    <Layout>

        <ToastSuccessError 
            ErrorMessage={errorMessage}
            successMessage={successMessage}
            setErrorMessage={setErroreMessage}
            setSuccessMessage={setSuccessMessage}
        />
    
      <div className="container all Add_traditional_quiz">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row g-3 needs-validation"
          noValidate
        >
          <div className="col-md-12">
            <label htmlFor="question" className="form-label">
              السؤال
            </label>


          {/* <h1>test quill editor</h1> */}
          <ReactQuill
          style={{height: '300px', marginBottom: '120px'}}
          theme={"snow"}
          onChange={(e) => question.current = e}
          value={question.current}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={'أدخل السؤال ...'}
        /> 


          </div>
          
          <div className="col-12">
            <label htmlFor="point" className="form-label">
              الإجابات
            </label>

            
            {
              optionsArr.map((option,idx) => {
                return returnOptionWithItsCheckbox(option.optionRef,option.index , register , watch);
              })
            }
            
          </div>
          <div className="col-md-6">
            <label htmlFor="question_note" className="form-label">
              ملاحظة السؤال
            </label>
            <input
              type="text"
              className="form-control"
              id="question_note"
              {...register("question_note", { required: true })}
            />
            {errors.question_note && (
              <p className="text-danger">ملاحظة السؤال مطلوب</p>
            )}
          </div>
          <div className="col-12 text-center">
            <button
              disabled={!isDirty || !isValid}
              className="btn btn-success my-5"
              style={{minWidth: '120px'}}
              type="submit"
            >
              رفع الاختبار
              {
                isLoading && <div className="mx-2">
                <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
                </div> 
              }
            </button>

          </div>
  
        </form>
      </div>
    </Layout>
  );



}

Editor.modules = {
  /*toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ],*/
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

function returnOptionWithItsCheckbox(optionRef, index ,register , watch) {
  return <div className="d-flex g-1" key={uuid()}>
        
        <div className="">
            <input
            // defaultChecked={index === 0}
            type="radio"
            checked={watch('correct') == index}
            {...register("correct", { required: false })}
            value={index}
            style={{ width: '25px',height: '25px'}}
          />
        </div> 

        <div className="">
        <ReactQuill
        style={{height: '300px', marginBottom: '120px'}}
        theme={"snow"}
        onChange={(e) => {optionRef.current =  e;}}
      value={ optionRef.current}
      modules={Editor.modules}
      formats={Editor.formats}
      bounds={".app"}
      placeholder={'أدخل الاختيار ...'}
      />


        </div>
      </div>
}