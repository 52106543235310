// subjects for subscribtionsss

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import ModalImage from "react-modal-image";

export default function RegisterOrdersTable({ data, isLoading, refetch }) {
  //  const [dataSubject , setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [minusPage, setMinusPage] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [isloading, setisLoading] = useState(false);
  const [refuseReason, setRefuseReason] = useState("");
  const [selectedItems, setSelectedItems] = useState({});

  const [ImageSource, setImageSource] = useState(null);

  useEffect(() => {
    console.log("itemsss", selectedItems);
  }, [selectedItems]);

  const handleRefuseItem = () => {
    if (refuseReason == "") {
      setErrorMessage("يجب إدخال سبب رفض طلب التسجيل");
    } else {
      setisLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/orders/${selectedId}/refuse`,
          {
            refuse_reason: refuseReason,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          setSuccessMessage("تم رفض الطلب بنجاح");
          refetch();
        })
        .catch(() => {
          // setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
        })
        .finally(() => {
          setisLoading(false);
          setRefuseReason("");
        });
    }
  };

  const handleApproveItem = () => {
    setisLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/${selectedId}/approve`,
        {
          // refuse_reason: refuseReason
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setSuccessMessage("تم قبول الطلب بنجاح");
        refetch();
      })
      .catch(() => {
        // setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى')
      })
      .finally(() => {
        setisLoading(false);
        setRefuseReason("");
      });
  };

  function checkRegisterType(type) {
    switch (type) {
      case "SUB_SECTION":
        return "تسجيل دورات";
        break;
      case "SUBJECTS":
        return "تسجيل مواد";
        break;
      case "UNITS":
        return "تسجيل وحدات";
        break;
      default:
        break;
    }
  }

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "400px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> اسم الطالب</th>
              <th className="text-center align-middle"> نوع الطلب</th>
              <th className="text-center align-middle"> تاريخ الطلب</th>
              {/* <th className='text-center align-middle'>   صورة الحوالة</th>  */}
              <th className="text-center align-middle"> السعر الكلي</th>
              <th className="text-center align-middle"> الحسومات</th>
              <th className="text-center align-middle"> السعر النهائي</th>
              <th className="text-center align-middle"> العمليات المتاحة</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={7}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : data && data.data.data.length > 0 ? (
              data.data.data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.student_name}
                    </td>
                    <td className="text-center align-middle">{item.type}</td>
                    <td className="text-center align-middle">
                      {item?.created_at}
                    </td>

                    <td className="text-center align-middle">
                      {item?.total_price}
                    </td>
                    <td className="text-center align-middle">
                      {item?.discounted_money}
                    </td>
                    <td className="text-center align-middle">
                      {item?.final_price}
                    </td>
                    <td className="text-center align-middle d-flex gap-2 flex-column">
                      <button
                        onClick={() =>
                          setSelectedItems({
                            items: item.items,
                            type: item.type,
                          })
                        }
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModaldetails"
                      >
                        عرض التفاصيل
                      </button>

                      {item.status == 3 || item.status == 1 ? (
                        <button
                          onClick={() => setSelectedId(item.id)}
                          type="button"
                          class="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          قبول الطلب
                        </button>
                      ) : null}

                      {item.status == 1 && (
                        <button
                          onClick={() => setSelectedId(item.id)}
                          className="ms-3 btn btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          رفض الطلب
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={7}>
                <h3 className="text-center my-5">لا يوجد بيانات</h3>
              </td>
            )}
          </tbody>
        </table>
      </div>

      {ImageSource && (
        <div className="popupImage_container">
          <button
            className="btn btn-primary"
            onClick={() => setImageSource(null)}
          >
            إغلاق
          </button>
          <div className="d-flex align-items-center justify-content-center">
            <img src={ImageSource} className="popup_image" />
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                هل انت متاكد انك تريد رفض الطلب
              </h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <p> * سبب الرفض </p>
              210 /{" " + refuseReason.length + " "}
              <textarea
                className="form-control w-full mt-2"
                style={{ minHeight: "150px" }}
                placeholder="يرجى إدخال سبب الرفض هنا ..."
                value={refuseReason}
                onChange={(e) => {
                  e.target.value.length >= 211
                    ? console.log("maximum")
                    : setRefuseReason(e.target.value);
                }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleRefuseItem}
              >
                نعم
                {isloading && (
                  <div className="mx-2">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">هل أنت متأكد أنك تريد قبول الطلب</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إلغاء
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
                onClick={handleApproveItem}
              >
                نعم
                {isloading && (
                  <div className="mx-2">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModaldetails"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              تفاصيل الطلب
              {
                <div className="overflow-auto w-100">
                  <table
                    className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
                    style={{ minHeight: "400px" }}
                  >
                    <thead>
                      <tr className="align-middle table-head fw-bold text-white">
                        <th className="text-center align-middle">الاسم </th>
                        <th className="text-center align-middle">الكوبون </th>
                        <th className="text-center align-middle">
                          السعر الكلي{" "}
                        </th>
                        <th className="text-center align-middle">
                          السعر النهائي{" "}
                        </th>
                        {/* <th className="text-center align-middle"> الحسومات </th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {selectedItems.items.map((item) => {
                        return (
                          <tr>
                            <td className="text-center align-middle">
                              {item.name}
                            </td>
                            <td className="text-center align-middle">
                              {item.coupon == null ? "----" : item.coupon}
                            </td>
                            <td className="text-center align-middle">
                              {item.total_price}
                            </td>
                            <td className="text-center align-middle">
                              {item.final_price}
                            </td>
                            {/* <td className="text-center align-middle">
                              {item.discounted_money}
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              }
              {selectedItems.type == "SUBJECTS" && (
                <div className="overflow-auto w-100">
                  <table
                    className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
                    style={{ minHeight: "400px" }}
                  >
                    <thead>
                      <tr className="align-middle table-head fw-bold text-white">
                        <th className="text-center align-middle">اسم المادة</th>
                        <th className="text-center align-middle">الكوبون </th>
                        <th className="text-center align-middle">
                          السعر الكلي{" "}
                        </th>
                        <th className="text-center align-middle">
                          السعر النهائي{" "}
                        </th>
                        <th className="text-center align-middle"> الحسومات </th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectedItems.items.map((item) => {
                        return (
                          <tr>
                            <td className="text-center align-middle">
                              {item.subject_name}
                            </td>
                            <td className="text-center align-middle">
                              {item.coupon}
                            </td>
                            <td className="text-center align-middle">
                              {item.total_price}
                            </td>
                            <td className="text-center align-middle">
                              {item.final_price}
                            </td>
                            <td className="text-center align-middle">
                              {item.discounted_money}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {selectedItems.type == "UNITS" && (
                <div className="overflow-auto w-100">
                  <table
                    className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
                    style={{ minHeight: "400px" }}
                  >
                    <thead>
                      <tr className="align-middle table-head fw-bold text-white">
                        <th className="text-center align-middle">اسم الوحدة</th>
                        <th className="text-center align-middle">الكوبون </th>
                        <th className="text-center align-middle">
                          السعر الكلي{" "}
                        </th>
                        <th className="text-center align-middle">
                          السعر النهائي{" "}
                        </th>
                        <th className="text-center align-middle"> الحسومات </th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectedItems.items.map((item) => {
                        return (
                          <tr>
                            <td className="text-center align-middle">
                              {item.unit_name}
                            </td>
                            <td className="text-center align-middle">
                              {item.coupon}
                            </td>
                            <td className="text-center align-middle">
                              {item.total_price}
                            </td>
                            <td className="text-center align-middle">
                              {item.final_price}
                            </td>
                            <td className="text-center align-middle">
                              {item.discounted_money}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
