import React, { useRef, useState,useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import record from'../../assets/Home/record.png'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';


export default function () {
        const userData = useSelector(state => state.Login);
        const [mainVideoFile,setMainVideoFile] = useState('');
       const [aboutUsImage,setAboutUsImage] = useState('');
       const [allSiteInfo,setAllSiteInfo] = useState(null);
       const [progress,setProgress] = useState(0);
       const [IsLoading,setIsLoading] = useState(false);
       const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
       const [imageChanged,setImageChnaged] = useState(false);
       const [successMessage , setSuccessMessage] = useState('');
       const [ErrorMessage , setErrorMessage] = useState('');
       const [teacherImage,setTeacherImage] = useState(null);
       const navigate = useNavigate();

       const [isSuccess,setIsSuccess] = useState(false);

       const [defauluseFormVlaues,setDefauluseFormValues] = useState({
            name: '' ,
            username:  '',
            email: '',
            password: '',
            gender: '',
            image: '',
            description: '',
            phone_number: '',
            transable: 0,
            telegram_url: ''
    });

    const params = useParams();

    
    const { reset,getValues,watch,setValue ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
    useForm({mode: "onChange",defaultValues: defauluseFormVlaues});


    useEffect(() => {
        setImageChnaged(true);
    },[watch('image')]);

    useEffect(() => { 
        axios.get(`${process.env.REACT_APP_API_URL}/get_teacher_by_id/${params.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {

            console.log('teacher data',data.data.data)

            const teacherdetails = data.data.data;

            setValue('name',teacherdetails.teacher_name);
            setValue('email',teacherdetails.email);
            console.log('name after get api', teacherdetails.name)
            setValue('username',teacherdetails.username);
            setValue('password',teacherdetails.password);
            setValue('gender',teacherdetails.gender);
            setValue('image',teacherdetails.image);
            setValue('description',teacherdetails.description);
            setValue('phone_number',teacherdetails.phone_number);
            setValue('telegram_url',teacherdetails.telegram_url);

            setTeacherImage(teacherdetails.image);

        })
        .catch(error => console.log(error));
   },[]);

    const handleOnSubmit = (data) => {
        // e.preventDefault();
        console.log('data',data)
        console.log("submitting data to server");
        setIsLoading(true);

        const ourFormData = new FormData();


        ourFormData.append('name',data.name);
        // ourFormData.append('username',data.username);
        ourFormData.append('email',data.email);
        // ourFormData.append('password',data.password);
        ourFormData.append('description',data.description);
        if(typeof getValues().image != 'string'){
            ourFormData.append('image',data.image[0]);
        }
        ourFormData.append('phone_number',data.phone_number);
        ourFormData.append('gender',data.gender);
        ourFormData.append('transable',data.transable);
        ourFormData.append('telegram_url',data.telegram_url);


        for(var pair of ourFormData.entries()) {
            console.log(pair[0]+ ', '+  pair[1]); 
            // console.log('pair',pair);
       }

        axios.post(`${process.env.REACT_APP_API_URL}/update_teacher_by_id/${params.id}`, ourFormData, {

        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          'Content-Encoding': 'gzip , deflate, br',
          'content-type': 'text/json',
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${userData.token}`
        },
    
  
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(progress);
          setProgress(progress);
          setIsLoading(true);
        }

      }).then(res=>{
        if(res.status === 200)
        {
          const {data} = res;
          console.log('data from post api' , data);
        setIsLoading(false)
        setIsUploadSuccessfuly(true);
        setIsSuccess(true);

        
        setSuccessMessage("تم تعديل معلومات الاستاذ بنجاح")

        setTimeout(() => {
            navigate(-1);
        }, 1200);

        setTimeout(() => {
            setIsSuccess(false);    
        }, 4000);

          return res;
        }
        else
        {
            setErrorMessage('غير متصل بالشبكة');
            setIsLoading(false);
          console.log(res);
          throw new Error('there is an error');
        }
      }).catch(error=>{
         setIsLoading(false);
         setErrorMessage('حدث خطأ ما');
        console.log(error.response);

      }
        );
      }

      useEffect(() => {
        if(progress == 100) {
            setIsLoading(false);
        }
      },[progress]);

      
      const changeColor = (progress) => {
        if(progress <= 25){
            return '#ff0707';
        }else if(progress <= 50){
            return '#ff6907';
        }else if(progress <= 75){
            return '#ffdb07';
        }else {
            return '#77ff07';
        }
      }


  return (
   
        <>

        
        <Layout>
            <div className='container all'>
            <ToastSuccessError 
            successMessage={successMessage}
            ErrorMessage={ErrorMessage} 
            setSuccessMessage={setSuccessMessage}    
            setErrorMessage={setErrorMessage}    
            />

        <form onSubmit={handleSubmit(handleOnSubmit)}>

        
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم الاستاذ</label>
            </div>
           <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>

                
        {/* <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم المستخدم</label>
            </div>
           <input type='text' {...register("username", { required: true })} className='shadow w-100 main-input border-0'/>
            {errors.the_vision && <p className='text-danger'>*required</p>} 
        </div> 
        */}

                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> الايميل</label>
            </div>
           <input type='text' {...register("email", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>

                
        {/* <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> كلمة السر</label>
            </div>
           <input type='text' {...register("password", { required: true })} className='shadow w-100 main-input border-0'/>
         
        </div> */}
                
        <div className='m-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> الجنس</label>
            </div>
           <select type='text' {...register("gender", { required: true })} className='shadow w-100 border-0'>
            <option value="MALE">ذكر</option>
            <option value="FEMALE">أنثى</option>
           </select>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>

                
        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> صورة الاستاذ</label>
            </div>
            <RegisterImage  
            register={register} 
            isUploadSuccessfuly={isUploadSuccessfuly}  
            registerWhat={"image"} 
            isRequired={false}
            teacherImage={process.env.REACT_APP_Video_URL + teacherImage}
            />

           {/* <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/> */}
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>


        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> وصف عن الاستاذ</label>
            </div>
           <input type='text' {...register("description", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>
        

                <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'>رقم الهاتف</label>
            </div>
           <input type='number' {...register("phone_number", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>


        <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> رابط حساب التيليجرام </label>
            </div>
           <input type='text' {...register("telegram_url", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>




            <div className='d-flex justify-content-center mt-5'>

           { !IsLoading ?
            <button className=' btn infos px-3 py-2 border-0 bg-primary' type='submit'><h6>تعديل بيانات الاستاذ </h6> </button>
            : 
            <div className='mt-2 d-flex justify-content-center' >
              <div class="spinner-border mx-auto" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
           </div>
           }     
        
         </div>

   
        </form>
        </div>
        </Layout>
        </>
    
  )
}
