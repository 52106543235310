import axios from 'axios';
import React, {useEffect, useState,useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function ConfirmRequest({data , isLoading}) { 
    const navigate = useNavigate();
    const [currentGuirdians,setCurrentGuirdians] = useState([]);
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const [Isloading,setIsLoading] = useState(false);
    const modalRef = useRef(null);
    const closeModalBtn = useRef();
    const [selectedGuardinId,setSelectedGuardianId] = useState();

    // http://java-course.online/api/get_all_un_active_students?limit=20

    useEffect(() => { 
        console.log('data from react query',data?.data);
        setCurrentGuirdians(data?.data); 
    },[data]);   


    const handleDeleteTeacher = (e,id) => {
        e.stopPropagation();
        console.log("id guardian to delete is => " , id);

        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_guardian_by_id/${id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {

            setIsLoading(false);
            console.log(data);
            const newCurrentGuirdians = currentGuirdians.filter(currentGuirdian => currentGuirdian.guardian_id != id);
            setCurrentGuirdians([...newCurrentGuirdians]);

            setSuccessMessage("تمت  عملية الحذف بنجاح")

            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();

        })
        .catch(error => {

            console.log(error);
            setIsLoading(false);
        setErrorMessage("حدث خطأ ما");
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }

    const selectedGuardianId = (e,id) => {
        e.stopPropagation();
            console.log('id guardian',id)
        setSelectedGuardianId(id);
    }
    
    return ( 
    <div className="tableContainer"> 

        <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

        <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}} > 
            <thead> 
                <tr className='align-middle table-head fw-bold text-white'> 
                <th className='text-center align-middle'> اسم ولي الامر</th> 
                <th className='text-center align-middle'> اسم المستخدم</th> 
                <th className='text-center align-middle'> الايميل</th> 
                <th className='text-center align-middle'> رقم الهاتف</th> 
               <th className='text-center align-middle'>العمليات المتاحة</th>
                </tr> 
            </thead> 
            <tbody style={{minHeight: '400px'}}> 
 
                {isLoading ? <td colSpan={5} > 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td>
             :  
                 
                 currentGuirdians && currentGuirdians.length > 0 ? currentGuirdians.map((guardian,index) => { 
                        return <tr 
                        key={index} 
                        onClick={(() => {navigate(`/guardian/${guardian.guardian_id}`)})} 
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 
                        <td  className='text-center align-middle'>{guardian.name}</td>             
                        <td  className='text-center align-middle'>{guardian.username}</td>                       
                        <td  className='text-center align-middle'>{guardian.email}</td>                       
                        <td  className='text-center align-middle'>{guardian.phone_number}</td>   
                        <td  className='text-center align-middle'>
                            
                            <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={(e) => { 
                                e.stopPropagation();
                                navigate(`/sons/${guardian.guardian_id}`,{state: {guardianData: guardian}}) 
                                }} >
                                الأبناء
                            </button>
                            <button 
                            
                            type="button" 
                            className="btn btn-danger" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal"
                            onClick={(e) => selectedGuardianId(e,guardian.guardian_id)}>
                                حذف
                            </button>

                            
                            </td>   

                    </tr> 
                
                 
                    }) : <td colSpan={5} className='text-center w-100'><h3>ليس هنالك اولياء امور بعد</h3> </td>
                } 
            
            </tbody> 
        </table> 
        </div>
        
     
        <div className="modal fade" ref={modalRef} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>
           

        </div>

        {Isloading && <div className="modal-body">
            
        {Isloading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
      
        }
        </div>
        }

       {!Isloading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn-danger"
            onClick={(e) => handleDeleteTeacher(e,selectedGuardinId)}
            >حذف</button>

        </div>
        }
      
    </div>
    </div>
    </div>
     
    </div> 
  ) 
}