import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './LibrarySection.css';
import axios from 'axios';
import { useParams , useNavigate, NavLink} from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import BooksTable from '../../components/ConfirmRequest/BooksTable';
import { useQuery } from 'react-query';
import fetchLibrarySectionBooks from '../../redux-toolkit/api/fetchLibrarySectionBooks';
import group from'../../assets/AboutUs/Group 408.png'

const LibrarySection = (props) => {

    const [librarySection,setLibrarySection] = useState({});
    const params = useParams();
    const nvigate = useNavigate();

    const [page,setPage] =useState( +localStorage.getItem('librarysection_pag') == null ? 1 : Number(+localStorage.getItem('librarysection_pag')) );
    const {isLoading, isError, data, error} = useQuery(['guardians',page,params.id], fetchLibrarySectionBooks);
  
      function handlePageClick (data)
      {
        localStorage.setItem('librarysection_pag' , data.selected)
        setPage(data.selected+1);
      } 
  

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_website_library_section_by_id/${params.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('library section detais is => ',data.data.data)
            setLibrarySection(data.data.data);
        })
        .catch(error => {
            console.log(error);
        })
    },[])

    return <Layout>
            <div className='container pt-5'>


            <div className='image_container pt-5 d-flex flex-column justify-content-center align-items-center gap-3'>
                    <img src={`${process.env.REACT_APP_Video_URL}/${librarySection?.image}`} alt="" />
                
                    <h3>{librarySection?.name}</h3>

                </div>

                <hr/>

                <div className='d-flex justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
                    <h5 className='gre mb-3'> أضف كتابا لهذا القسم</h5> 
                    <NavLink to={`/addbook/${params.id}`}>
                        <img src={group} className="me" />
                    </NavLink>
                </div>


            <div className='d-flex-column justify-content-around align-items-center mb-5 pt-5'>

            <h3 className='text-center my-3'>كتب القسم</h3>
            {
                <BooksTable data={data?.data} isLoading={isLoading}/>
            }

            </div>
            {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
            


         
            </div>
    </Layout>
}

export default LibrarySection;