

import axios from "axios";
export default async function  fetchQuizeQuestions ({queryKey})
{

          
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_automated_quiz_questions_with_options_by_automated_quiz/${queryKey[1]}` ,
          {params :{limit:19}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

   
          return  data;
} 


