
import React ,{ useEffect, useState  } from "react";
import './RegisterImage.css'
import RegisterImageLogic from "./RegisterImageLogic";

export default function RegisterImage({ 
  valid,
  subscribtionPage,
  register,
  id,
  registerWhat,
  isUploadSuccessfuly,
  imageSource,
  isRequired = true,
  guardianImage,
  studentImageSource,
  teacherImage,
  Accept= undefined
}){
  const{ cardref,btnref,onImageChange} = RegisterImageLogic(valid);

  const [link,setLink] = useState(null);
 
  const paramsId = id || 0

  const [imgLink,setImgLink]= useState(null);
  
  useEffect(()=>{
   
      if(paramsId){
        setImgLink(subscribtionPage?.data?.subscription_image);
      }
  },[subscribtionPage])
  


    useEffect(() => {
        if(isUploadSuccessfuly){
            setLink(null);
        }else {
            return;
        }
    },[isUploadSuccessfuly])


    let img =null;
    if(paramsId){
      img= `$${process.env.REACT_APP_Video_URL+imgLink}`
    }
   


   const handleImage  = (e)=>{
    const [file] = e.target.files;
    setLink(URL.createObjectURL(file));
    console.log('image url from device',URL.createObjectURL(file))
   }

    return(
      <>
      
      <div className="bg-white    shadow  mb-4 d-flex finals justify-content-center parent_btn" ref={cardref}>
      
       <img   
       src={link || img || imageSource || guardianImage || studentImageSource || teacherImage} 
       style={{'width':'100%','height':'100%','margin':'auto'}} 
       /> 
            <div className="button ii m-auto add-div btn_child" ref={btnref}>
              <i class="fa fa-plus "></i>
            <div>
      
       
              <input type='file' 
              className="ool"
              accept={Accept ? Accept : "image/*"}
              {...register(`${registerWhat}`, { required: isRequired })}
              onChange={handleImage}
              />


        

{/* export default function Image({imagess,setimage,register}){
    const{ cardref,btnref,onImageChange,imagesURLs}=ImageLogic(imagess,setimage)
    return(
      <>
      
      <div className="bg-white    shadow  mb-4 d-flex finals justify-content-center" ref={cardref}>
      {
        imagesURLs.map(imageSrc=> <img   src={imageSrc} style={{'width':'16rem','height':'16rem','margin':'auto'}}/> )
      }
      
        <div className="button ii m-auto add-div" ref={btnref}>
          <i class="fa fa-plus "></i>
        <div >

        <input type='file' 
        ref={imagess} 
        className="ool" 
        multiple accept="image/*" 
        {...register("about_us_image", { required: true })  } 
        onChange={onImageChange}
        /> */}
        

        </div></div>
      </div>

        {
          Accept && link && (
            <iframe src={link} height="400" width="100%"></iframe>
          )
        }

      </>
    )
  }