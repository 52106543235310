import React from 'react'
import { useLocation } from 'react-router-dom';
import Img from '../../assets/Menu/My-way-Logo.png';
import Layout from '../Layout/Layout';

const ShowComment = () => {
    const location = useLocation();
    console.log("Location",location)
    const myComment = location?.state?.comment
    
  return (
    <Layout>
    <div className='container text-center pt-5'> 
    <div className='d-flex justify-content-center align-items-center'>
      <img src={Img} alt="image"/>
     <h5 className='mt-5'>my Name:{myComment?.student_name}</h5>
      </div>
      <h3 className='my-5'>my Comment: {myComment?.comment}</h3>

    </div>

    </Layout>
  )
}

export default ShowComment