import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function CallsRecordTable({data , isLoading , refetch}) { 
    const navigate = useNavigate();
    const [currentAdimis,setCurrentAdmins] = useState([]);
    const modalRef = useRef(null);
    const closeModalBtn = useRef(null);
    const [Isloading,setIsLoading] = useState(false);
    const [selectedDeleteId,setSelectedDeleteId] = useState();
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const {id} = useParams();

    const addRecordModalRef = useRef();
    const addRecordModalColseBtn = useRef();

    const [isLoadingHistroy,setIsLoadingHistory] = useState(false);
    const [historyId,setHistoryId] = useState(null);
    const [historyData,setHistoryData] = useState([]);

    const [showSendMessage,setShowSendMessage] = useState(true);
    const [refetchHistory,setRefetchMessage] = useState(false);

    useEffect(() => { 
        console.log('data from react query',data?.data);
        setCurrentAdmins(data?.data); 
    },[data]);   

    useEffect(() => {

      function closeModal(event) {
        // closing the modal when the user go back
        document.querySelector('.modal-backdrop').classList.remove('show');
      }


      window.addEventListener('popstate',closeModal);

      return () => {
        window.removeEventListener('popstate',closeModal);
      }
    },[]);


    const handleDeleteAdmin = (e,id) => {
        e.stopPropagation();
        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_student_status_history_by_id/${id}`, 
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(res => {
            if(res.status === 200)
            {
            console.log(data);
            setIsLoading(false);
            refetch();
          setSuccessMessage("تمت  عملية الحذف بنجاح")
            // hide the confirm deletion modal 
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();

            // const newCurrentAdmins = currentAdimis.filter(currentAdmin => currentAdmin.id != id);
            // console.log('admin deleted successfully ')
            // setCurrentAdmins([...newCurrentAdmins]);
            }else {

            }
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
            setErrorMessage("حدث خطأ ما");
            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })
    }

    const handleEditAdmin = (e,id) => {
        e.stopPropagation();
        navigate(`/editAdmin/${id}`);
    }

    const selectedAdminId = (id) => {
        console.log('id'.repeat(20),id)
        setSelectedDeleteId(id);
    }
    
    const testarray = [1,2,3,5,6,7,8,3,45,5];

    const { register, handleSubmit , reset , formState: { errors } } = useForm({
      defaultValues: {
        notes: '',
        has_warned: 0 , 
        next_calling_time: '',
        student_id: id , 

      }
    });


    const { register: registerSendMessage, handleSubmit: handleSubmitSendMessage , reset: resetSendMessage , formState: { errors: errorsSendMessage } } = useForm({
      defaultValues: {
        message: '' ,
        notification_type: "NOTIFICATION" , 
        is_for_guardian: 0 ,
        is_for_student: 0 , 
        students_status_history_id: ''
      }
    });

    const addMessage = (data) => {

      setIsLoading(true);

      console.log('message data data ', data);
      const formCallsRecordData = new FormData();

      formCallsRecordData.append('message', data.message);
      formCallsRecordData.append('notification_type', data.notification_type);
      formCallsRecordData.append('is_for_guardian', data.is_for_guardian ? 1 : 0);

      formCallsRecordData.append('is_for_student' , data.is_for_student ? 1 : 0);
      formCallsRecordData.append('students_status_history_id' , historyId);



      axios.post(`${process.env.REACT_APP_API_URL}/add_student_status_history_message`, formCallsRecordData , 
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then((data) => {
      
        setSuccessMessage('تمت إرسال الرسالة بنجاح');
        // refetch();
        setRefetchMessage(prev => !prev);
        resetSendMessage();
      })
      .catch(error => {
        setErrorMessage('حدث خطأ ما يرجى المحاولة مرة أخرى')
      
      })
      .finally(() => {
        setIsLoading(false);

        setShowSendMessage(false);
      })

    }

    // Catch all inputs and check if they are valid then adding the record
    const addStudentRecord = (data) => {
      
      setIsLoading(true);

      console.log('message data data ', data);
      const formCallsRecordData = new FormData();

      formCallsRecordData.append('notes', data.notes);
      formCallsRecordData.append('next_calling_time', data.next_calling_time);
      formCallsRecordData.append('student_id', data.student_id);

      formCallsRecordData.append('has_warned' , data.has_warned ? 1 : 0);


      axios.post(`${process.env.REACT_APP_API_URL}/add_student_status_history`, formCallsRecordData , 
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then((data) => {

        setSuccessMessage('تمت إضافة السجل بنجاح');
        refetch();
        reset();
      })
      .catch(error => {
        setErrorMessage('حدث خطأ ما يرجى المحاولة مرة أخرى')
      })
      .finally(() => {
        setIsLoading(false);

        addRecordModalRef.current.style.display = 'none';
        addRecordModalColseBtn?.current?.click();
      })

    }


    useEffect(() => {

      if(historyId){

        setIsLoadingHistory(true);

        axios.get(`${process.env.REACT_APP_API_URL}/get_all_student_status_history_messages/${historyId}`, {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
          // console.log('data data history message student'  , data.data.datsa);
          setHistoryData(data.data.data);
        })
        .catch(error => {
          console.log('error' , error);
        })
        .finally(() => {
          setIsLoadingHistory(false);
        })
      
      }
      
    },[historyId,refetchHistory])


    return ( 
    <> 
            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

          <div className='mb-5 w-100 text-center'>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModaladdrecord">
              إضافة سجل
            </button>
          </div>

          <div className="modal fade " ref={addRecordModalRef} id="exampleModaladdrecord" tabindex="-1" aria-labelledby="exampleModaladdrecord" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div classname="modal-header">
                <h3 className="modal-title w-100  p-2 px-3" id="exampleModaladdrecord">إضافة سجل للطالب</h3>
                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <form onSubmit={handleSubmit(addStudentRecord)} >
              <div className="modal-body">



            <div className='d-flex flex-column gap-3'>

           


              <div>

              <div class="input-group">
              <span class="input-group-text rounded-0">الملاحظة</span>
              <textarea {...register("notes", {required: true})} class="form-control rounded-0" aria-label="With textarea"></textarea>
            </div>
              {errors.notes && <div className="text-danger text-right">يجب إدخال ملاحظاتك</div>}
              
              </div>

            <div>
            
            <div class="input-group">
              <span class="input-group-text rounded-0">موعد الاتصال القادم</span>
              <input {...register("next_calling_time" , {required: true})} class="form-control rounded-0" aria-label="With textarea" type={'datetime-local'}></input>
            </div>
              {errors.next_calling_time && <div className="text-danger text-right">يجب إدخال موعد الاتصال القادم</div>}
            
            </div>

            <div class="form-check d-flex justify-content-between align-items-center border px-2 ">
              <input {...register("has_warned")} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label class="form-check-label mr-4 fs-2" for="flexCheckDefault">
                تم تحذير الطالب
              </label>
            </div>
              {/* {errors.next_calling_t && <div className="text-danger text-right">يجب إدخال موعد الاتصال القادم</div>} */}


            </div>

              </div>
              <div class="modal-footer">

                {
                  Isloading ? 
                  <div className='text-center w-100' >
                  <div class="spinner-border " role="status"> 
                    <div class="visually-hidden">Loading...</div> 
                  </div>
                  </div> : 
                  <>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={addRecordModalColseBtn}>إغلاق</button>
                  <button type="submit" class="btn btn-primary">إضافة</button>
                  
                  </>
                }

              </div>
            </form>
            </div>
          </div>
          </div>

        <div className='' >
        <div className='custom_table_calls  flex-wrap' style={{overflowX: 'auto'}}>

        <div className='row table_calls_header  m-0 w-100' style={{minWidth: '1000px'}} >

            <div className='col-2 align-middle text-center'>تاريخ المكالمة</div> 
            <div className='col-1 align-middle text-center'>تم التحذير</div> 
            <div className='col-2 align-middle text-center'>تاريح المكالمة القادم</div>  
            <div className='col-2 align-middle text-center'>ملاحظات المشرف</div> 
            <div className='col-1 align-middle text-center'>ساعات الدراسة المطلوبة</div> 
            <div className='col-1 align-middle text-center'>ساعات الدراسة المنجزة</div> 
            <div className='col-1 align-middle text-center'>ساعات الدراسة المتبقية</div> 
            <div className='col-2 align-middle text-center'>نسبة الإنجاز</div>

            </div>
 
                {isLoading ? <div className='text-center my-5 w-100' >
                   <div class="spinner-border " role="status"> 
                     <div class="visually-hidden">Loading...</div> 
                   </div>
                   </div> :  
                 
                 data ? data?.data?.map((record,index) => { 
                        return  <div class="accordion" id="accordionExample" style={{minWidth: '1000px'}}>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
        <div className=' w-100'>

          <div className='row m-0 row_custom' data-bs-toggle="collapse" data-bs-target={`#collapseOne${index}`} aria-expanded="false" aria-controls="collapseOne">

          <div className='col-2 align-middle text-center'>
            {record.current_calling_time }
             <button type="button" class="btn d-inline" onClick={(e) => {e.stopPropagation() ; selectedAdminId(record.id)}} data-bs-toggle="modal" data-bs-target="#exampleModaladeleteRecord">
                <i class="fa fa-trash fs-3 text-danger"  aria-hidden="true"></i>
              </button> 
          </div>

            <div className='col-1 align-middle text-center'> {record.has_warned == 1 ? 'نعم' : 'لا'}</div> 
            <div className='col-2 align-middle text-center'> {record.next_calling_time}</div>  
            <div className='col-2 align-middle text-center'> {record.notes} </div> 
            <div className='col-1 align-middle text-center'>  {record.totat_required_time}</div> 
            <div className='col-1 align-middle text-center'>  {record.totat_completed_study_time}</div> 
            <div className='col-1 align-middle text-center'>  {record.totat_left_study_time}</div> 
            <div className='col-2 align-middle text-center' 
            style={{
              display: 'flex', 
              // flexDirection: 'column',
              alignItems: 'center', 
              gap: '5px',
              justifyContent: 'space-between'}}
              > 
              
              
              <div>{record.study_percentage}%</div>  
               
                <button  
                className='rounded message_calls_record_btn' 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModalll"  
                onClick={(e) => {e.stopPropagation(); setHistoryId(record.id) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                </svg>
                </button>

                
                <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${index}`} aria-expanded="false" aria-controls="collapseOne">
          
          </button>
            </div>

          </div>
         
          </div>
        </h2>
        <div id={`collapseOne${index}`} class="accordion-collapse collapse text-wrap" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

          {/* Accordion body */}
          <div class="accordion-body">
            



          <div className='custom_table_calls'>

<div className='row table_calls_header   m-0' >

    <div className='col-3 align-middle text-center'>اسم المادة</div> 
    <div className='col-2 align-middle text-center'> انجاز الطالب</div> 
    <div className='col-2 align-middle text-center'>  الساعات المنجزة</div>  
    <div className='col-2 align-middle text-center'>   ساعات التقصير</div>
    <div className='col-3 align-middle text-center'> عدد ساعات الدراسة الكلية للمادة</div>

    </div>

        {isLoading ? <tr class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
             <td class="visually-hidden">Loading...</td> 
           </tr> :  
         
         record?.student_subjects_achievement.map((record,index) => { 
                return  <div className='row m-0 row_custom'>

    <div className='col-3 align-middle text-center'>{record.subject_name}</div> 
    <div className='col-2 align-middle text-center'> {record.student_subject_achievement_percentage + '%' }</div> 
    <div className='col-2 align-middle text-center'> {record.subject_student_study_time}</div>  
    <div className='col-2 align-middle text-center'> {record.student_subject_left_study_time} </div> 
    <div className='col-3 align-middle text-center'> {record.subject_total_study_time} </div> 


  </div>
 
  

{/* <div id={`collapseOne${index}`} class="accordion-collapse collapse text-wrap" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
  <div class="accordion-body">
    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
  </div>
</div> */}


  
                
         
            }) 
        } 

        

    
 
    
</div> 






          </div>
        </div>
      </div>
    
    </div>    
                        
                 
                    }) :<div className='text-center w-100 my-5'><h3>لا يوجد سجلات بعد</h3> </div>
                } 
 
                

            
         
            
        </div> 

        </div>


        
     <div className="modal fade" ref={modalRef} id="exampleModaladeleteRecord" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري الحذف ...' : "هل أنت متأكد ؟"}
            </h5>
           
           
        </div>
        {Isloading && <div className="modal-body">
        {Isloading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
      
        }
        </div>
        }
       {!Isloading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn-danger"
            onClick={(e) => handleDeleteAdmin(e,selectedDeleteId)}
            >حذف</button>

        </div>
        }
      
    </div>
    </div>
    </div>

    <div id="exampleModalll" class="modal fade">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="w-100 text-center">سجل الرسائل</h3>
                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>


                <div className='w-100 text-center'>

        <button className="btn btn-primary my-3" onClick={() => setShowSendMessage(prev => !prev)}>{showSendMessage ? 'إغلاق' : 'إرسال رسالة'}</button>
      
        {
          showSendMessage &&  
            <form onSubmit={handleSubmitSendMessage(addMessage)} className="sendMessageForm border shadow rounded mx-auto">

                <div className='my-2'>
                  <h5>الرسالة</h5>
                <textarea
                        className="border rounded w-100 "
                        placeholder='أدخل نص الرسالة المراد إرسالها هنا ...'
                        {...registerSendMessage('message', {required: true})}
                        />
                </div>

                <div className='my-2'>
                  <label>إرسال للطالب</label>
                  <input
                        className="form-check-input mx-2"
                        type="checkbox"
                        id="noReal"
                        {...registerSendMessage('is_for_student')}
                        />
                  </div>

                  <div className='my-2'>
                    <label>إرسال للوالد</label>
                  <input
                        className="form-check-input mx-2"
                        type="checkbox"
                        id="noReal"
                        {...registerSendMessage('is_for_guardian')}
                        />
                  </div>

          <select {...registerSendMessage('notification_type')}   className='shadow p-2 border-0 my-2'>
          <option >اختر نوع الرسالة</option>
            <option value="NOTIFICATION" >إشعار داخلي</option>
            <option value="SMS" >رسالة نصية SMS </option>
            <option value="BOTH" >رسالة نصية SMS + إشعار داخلي </option>
        </select>

          {
            Isloading ? 
            <div className=' w-100 text-center w-100' >
            <div class="spinner-border " role="status"> 
              <div class="visually-hidden">Loading...</div> 
            </div>
            </div>
            :
        <button className="btn btn-success d-block mx-auto" type="submit">إرسال الرسالة</button>

          }

            </form>
          
        }
      </div>


                <div class="modal-body">
                    

                <div className='custom_table_calls' style={{ overflowX: 'auto' }}>

<div className='row table_calls_header   m-0' style={{minWidth: '1000px'}}>

    <div className='col-3 align-middle text-center'>تاريخ الإرسال </div> 
    <div className='col-3 align-middle text-center'>الرسالة </div> 
    <div className='col-3 align-middle text-center'>  نوع الرسالة</div> 
    <div className='col-3 align-middle text-center'>الجهة المستقبلة </div>  
    {/* <div className='col-3 align-middle text-center'>   تم الإرسال للطالب</div> */}

    </div>
    

        {isLoadingHistroy ? <tr class="spinner-border" role="status"> 
             <td class="visually-hidden">Loading...</td> 
           </tr> :  
         
          historyData &&  historyData.map((record,index) => { 
                return  <div className='row m-0 row_custom' style={{minWidth: '1000px'}}>
    <div className='col-3 align-middle text-center'>{record.created_at}</div> 
    <div className='col-3 align-middle text-center'>{record.message}</div> 
    
    <div className='col-3 align-middle text-center'> 

    {record.notification_type == 'NOTIFICATION' && <div>إشعار داخلي </div>}
    {record.notification_type == 'SMS' && <div>رسالة SMS </div> }
    {record.notification_type == 'BOTH' && <div>رسالة SMS + إشعار داخلي</div>}
    
    
    </div> 
    <div className='col-3 align-middle text-center'> 

          <div className='d-flex align-items-center justify-content-center gap-2 '>

          {
            record.is_for_guardian == 1 && <p className='fw-bold border rounded p-1 mb-0'>ولي الأمر</p>   
          }
          {record.is_for_student == 1 &&  <p className="fw-bold border rounded p-1 mb-0">الطالب</p>}
            </div>
    </div>  
    {/* <div className='col-3 align-middle text-center'> {record.is_for_student == 1 ? 'نعم' : 'لا'}</div>   */}
    {/* <div className='col-3 '> {record.notes} </div>  */}


  </div>
 
  

{/* <div id={`collapseOne${index}`} class="accordion-collapse collapse text-wrap" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
  <div class="accordion-body">
    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
  </div>
</div> */}   
         
            }) 
        } 

        


 
    
</div> 





                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary mx-auto" data-bs-dismiss="modal">إغلاق</button>
                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                </div>
            </div>
        </div>


{/* Send Message Modale */}
<div class="modal fade" id="sendMessageModal" tabindex="10" aria-labelledby="sendMessageModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="sendMessageModal">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

    </div>







     
    </> 
  ) 
}