import { useState } from "react";
import { useQuery } from "react-query";
import CouponsTable from "../../components/ConfirmRequest/CouponsTable";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import fetchCoupons from "../../redux-toolkit/api/fetchCoupons";


const Coupons = () => {

    const [page,setPage] =useState(+localStorage.getItem('coupons_page') == null ? 1 : Number(+localStorage.getItem('admins_pag')));
    const {isLoading, isError, data, error , refetch: refetchCoupons} = useQuery(['coupons',page], fetchCoupons);
  
      function handlePageClick (data)
      {
        localStorage.setItem('coupons_pag' , data.selected)
        setPage(data.selected+1);
      } 
  

    return <Layout>
    <div className=' callsRecordsContainer container  all'>
        <h2 className="text-center mt-3">كوبونات الخصومات</h2>

                   
        <div className=' mb-5'>


        {
                <CouponsTable data={data?.data} isLoading={isLoading} refetchCoupons={refetchCoupons} />
        }

          </div>

          {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  


    </div>
    </Layout>

}

export default Coupons;